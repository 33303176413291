import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Slider from "react-slick";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BoxContentTurmas from "./BoxContentTurmas";
import Loading from "./Loading";
import CustomDialog from "../ui/custom-dialog";
import BoardAboutClass from "./BoardAboutClass";

import Subtitle from "../ui/subtitle";

import { API_KEY, BASE_URL } from "../Constants";
import { Auth } from "../client/api";

import { useHistory } from "react-router-dom";

const Arrow = ({ className, to, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`button button--text button--icon ${className}`}
    aria-label={to}
  >
    {to === "next" ? (
      <ArrowForwardOutlinedIcon color="primary" />
    ) : (
      <ArrowBackOutlinedIcon color="primary" />
    )}
  </button>
);

const setSettings = (total) => {
  return {
    dots: false,
    infinite: false,
    speed: 250,
    slidesToShow: Math.min(6, total),
    slidesToScroll: 1,
    centerPadding: "0px",

    lazyLoad: "ondemand",
    nextArrow: <Arrow to="next" />,
    prevArrow: <Arrow prev="prev" />,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: Math.min(4, total),
          // arrows: false,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: Math.min(3, total),
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: Math.min(2, total),
        },
      },
      {
        breakpoint: 580,
        settings: {
          variableWidth: true,
          centerMode: true,
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
};

export default ({
  title,
  classContent,
  allowGetInClassButton,
  allowCancel,
  profile,
  style,
  statusChip,
  single,
  watched,
  singleHomework = false,
  singleclass = false,
}) => {
  const [infoDialog, setInfoDialog] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [fetchData, setFetchData] = useState(0);

  const token = profile.user.auth_token;
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];

  const history = useHistory();

  const inTimeForClass = async (dateStr1) => {
    try {
      const response = await fetch(`${BASE_URL}/time/iso?apikey=${API_KEY}`);
      console.log(response);
      if (!response.ok) {
        throw new Error(`Erro ao buscar a data atual. Status: ${response.status}`);
      }
      const dataString2 = await response.json();

      // const dataString2 = "2023-12-10T23:49:10.552Z";


      const partes = dateStr1.split(" ");
      const dataPartes = partes[0].split("/");
      const horaPartes = partes[1].split("h");
      const dataString1 = `${dataPartes[2]}-${dataPartes[1]}-${dataPartes[0]} ${horaPartes[0]}:${horaPartes[1]}:00.000 -0300`;

      const data1 = new Date(dataString1);
      const data2 = new Date(dataString2);

      const dezMinutosEmMilissegundos = 10 * 60 * 1000;

      return data2.getTime() >= data1.getTime() - dezMinutosEmMilissegundos;
    } catch (error) {
      console.error('Erro na função inTimeForClass:', error.message);
      return true;
    }
  };

  const onOpenDialog = async (data) => {
    // const isInTime = await inTimeForClass(data.init_at_complete_format);
    // data.isInTime = isInTime;
    // console.log(data);
    setInfoDialog(data);
  };

  const onCloseDialog = () => {
    setInfoDialog(null);
  };

  const montaTempo = (infodialog) => {
    // console.log('infodialog: ', infodialog);
    // let dateTimezone = new Date();
    // let timezone = dateTimezone.getTimezoneOffset() / 60;
    // let separadorDataHorario = infodialog.init_at_complete_format.split(" ");
    // let hora = separadorDataHorario[1].split("h");
    // let data = separadorDataHorario[0].split("/");
    // data = data[2] + "-" + data[1] + "-" + data[0];
    // let horaAula = data + "T" + hora[0] + ":" + hora[1] + ":00";
    // let dateClass = new Date(horaAula);

    return "";
  };

  const cancelReserve = async (classId) => {
    // const source = axios.CancelToken.source();

    try {
      setProcessing(true);

      const response = await Auth(token).post(
        // 'livecourseclassdate/' + classId + '/cancel?apikey=' + API_KEY,
        `singleclassdate/${classId}/reserve/cancel?apikey=${API_KEY}`,
        {
          profile_id: childrenLive.id,
        }
      );

      setProcessing(false);

      if (response.data.result) {
        setFetchData((fetchData) => fetchData + 1);
      } else if (response.data.error) {
        alert(response.data.error);
      }

      history.go(0);
      onCloseDialog();
    } catch (error) {
      // console.log('Data:', error.response.data.error);
      setProcessing(false);

      alert(error.response.data.error);

      onCloseDialog();
    }
  };

  if (classContent.loading) return <Loading />;
  if (classContent.data === null)
    return <p>Erro ao tentar carregar o conteúdo</p>;

  return (
    <>
      <Grid item xs={11} style={style}>
        <Subtitle>{title}</Subtitle>
        <Slider {...setSettings(classContent.data.length)}>
          {
            // eslint-disable-next-line array-callback-return
            classContent.data.map((item, index) => {
              if (item.id) {
                return (
                  <BoxContentTurmas
                    {...item}
                    singleHomework={singleHomework}
                    classId={item.live_course_class_id}
                    hasHomeworks={!single ? item.has_homeworks : false}
                    key={index}
                    reserve={true}
                    onOpenDialog={(item) => onOpenDialog(item)}
                    hora={montaTempo(item)}
                    reserved={true}
                    statusChip={statusChip}
                  />
                );
              }
            })
          }
        </Slider>
      </Grid>

      {infoDialog && (
        <CustomDialog
          title={`${infoDialog.name} -`}
          subtitle={infoDialog.initHour + " - Horário de Brasília"}
          open={true}
          onClose={onCloseDialog}
        >
          <BoardAboutClass
            id={infoDialog.id}
            countReserves={infoDialog.countReserves}
            numberParticipants={infoDialog.numberParticipants}
            image={infoDialog.teacher.image_thumb[1].secure_url_image}
            reserved={allowGetInClassButton}
            title={infoDialog.teacher.name}
            description={infoDialog.description}
            nameTeacher={infoDialog.teacher.name}
            processing={processing}
            allowCancel={allowCancel}
            cancelReserve={cancelReserve}
            onClose={onCloseDialog}
            replacementvideoid={infoDialog.replacementvideoid}
            link={infoDialog.link}
            time={infoDialog}
            hasVacancy={infoDialog.hasVacancy}
            turmas={true}
            liveCourseClassId={infoDialog.liveCourseClassId}
            hasHomeworks={infoDialog.hasHomeworks}
            classId={infoDialog.classId}
            replacementhomeworkid={infoDialog.replacementhomeworkid}
            single={single}
            watched={watched}
            singleclass={singleclass}
            recordedclass={infoDialog.recordedclass}
            recorded={infoDialog.recorded}
            recorded_class_video={infoDialog.recorded_class_video}
            init_at={infoDialog.init_at}
            init_at_complete_format={infoDialog.init_at_complete_format}
            singleHomework={infoDialog.singleHomework}
            // isInTime={infoDialog.isInTime}
          />
        </CustomDialog>
      )}
    </>
  );
};
