import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Container, makeStyles, Button } from "@material-ui/core";
import WarningRounded from "@material-ui/icons/WarningRounded";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "15px",
    height: "100vh",
  },
  messageBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    backgroundColor: "#fff",
    padding: "15px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
});

export default () => {
  const classes = useStyles();

  useEffect(() => {
    localStorage.removeItem("user");
    setTimeout(() => {
      window.location.href = "/";
    }, 4000);
  });

  return (
    <Container className={classes.container} maxWidth="lg">
      <Container className={classes.messageBox}>
        <WarningRounded style={{ fontSize: "100px" }} color="error" />
        <Typography
          variant="h1"
          color="textPrimary"
          style={{ marginBottom: "10px" }}
        >
          Ops! Parece que algo deu errado.
        </Typography>
        <Typography variant="h2" color="textPrimary">
          Você pode tentar novamente, caso o problema persista, entre em contato
          com o suporte.
        </Typography>
        <Typography variant="h2" color="textPrimary">
          Você será redirecionado para a página de login automaticamente, ou
          pode clicar no botão abaixo para voltar para a página de login.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Página de Login
        </Button>
      </Container>
    </Container>
  );
};
