import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useParams, useLocation, useHistory } from 'react-router-dom';
// import Slide from '../../components/Slide';
import HeaderPage from '../../components/HeaderPage';
import { API_KEY } from '../../Constants';
import { Auth } from '../../client/api';
import { load } from '../../localStorage';
import axios from 'axios';
import Message from '../../ui/message';
import BoardAboutClass from '../../components/BoardAboutClass';
import CustomDialog from '../../ui/custom-dialog';

import Subtitle from '../../ui/subtitle';
import GridAutoFlow from '../../components/GridAutoFlow';
import BoxContent from '../../components/BoxContent';
// import { DateTimePicker } from '@material-ui/pickers';
// import moment from 'moment';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default (props) => {
  const token = load('user').profile.user.auth_token;
  const history = useHistory();
  let query = useQuery();
  const date = query.get('date');
  const profileId = query.get('profileId');
  const name = query.get('name');
  const description = query.get('description');

  const [classes, setClasses] = React.useState(null);
  const [infoDialog, setInfoDialog] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);
  // const [selectedDate, handleDateChange] = React.useState(new Date());
  // let source;

  const onOpenDialog = (data) => {
    setInfoDialog(data);
  };

  const onCloseDialog = () => {
    setInfoDialog(null);
  };

  React.useEffect(() => {
    loadDataFilter(date);
    // loadData();
    // return () => source.cancel('request canceled by the user.');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const montaTempo = (infodialog) => {
    
    let dateTimezone = new Date();
    let timezone = dateTimezone.getTimezoneOffset() / 60;
    let separadorDataHorario = infodialog.init_at_complete_format.split(' ');
    let hora = separadorDataHorario[1].split('h');
    let data = separadorDataHorario[0].split('/');
        data = data[2] + '-'+ data[1] + '-' + data[0];
    let horaAula = data + 'T' + hora[0]+':'+ hora[1]+':00';
    let dateClass = new Date(horaAula);
    
      // Se 3 Igual ao Servidor
      if(timezone == 3){
       
      
        // let hora = `${dateClass.getHours()}:${dateClass.getMinutes()}`;
        // return hora;
        return '';
      }
      else 
      {
        dateClass.setHours(dateClass.getHours() + 3);
        dateClass.setHours(dateClass.getHours() - timezone);
        let hora = (dateClass.getHours() < 10)? '0'+dateClass.getHours() : dateClass.getHours();
        let minutos = (dateClass.getMinutes() < 10 ) ? '0' + dateClass.getMinutes() : dateClass.getMinutes();
       
        return ( `${hora}h${minutos}`);
         
      } 
    return '';
  }



  // React.useEffect(() => {
  //   console.log(location);
  //   return () => source.cancel('request canceled by the user.');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  // const loadData = async () => {
  //   // source = axios.CancelToken.source();

  //   try {
  //     const response = await Auth(token).get(
  //       'livecourseclass/' +
  //         classId +
  //         '/dates?profile_id=' +
  //         profileId +
  //         '&apikey=' +
  //         API_KEY
  //       // {
  //       //   cancelToken: source.token,
  //       // }
  //     );
  //     const { name, description, classes } = response.data.result;
  //     setName(name);
  //     setDescription(description);
  //     setClasses(classes);
  //   } catch (error) {
  //     if (axios.isCancel(error)) {
  //       console.log(error);
  //     } else {
  //       throw error;
  //     }
  //   }
  // };

  const loadDataFilter = async (formatDate) => {
    setProcessing(true);
    try {
      setProcessing(true);

      const response = await Auth(token).get(
        `livecourseclass/byDatetolerance?date=${formatDate}&profile_id=${profileId}&apikey=${API_KEY}`
        // {
        //   cancelToken: source.token,
        // }
      );

      setProcessing(false);

      // const { name, description, classes } = response.data.result;
      // setClasses(classes);
      // console.log(response.data.result);
      // const [day] = response.data.result;
      // console.log(day);
      setClasses(response.data.result || []);
      // console.log('filter: ', response.data);
    } catch (error) {
      setProcessing(false);
      if (axios.isCancel(error)) {
        // console.log(error);
      } else {
        throw error;
      }
    }
  };

  const makeReserve = async (classId) => {
    // console.log(classId, Number(profileId));

    // source = axios.CancelToken.source();

    try {
      setProcessing(true);

      const response = await Auth(token).post(
        'livecourseclassdate/' + classId + '/reserve?apikey=' + API_KEY,
        {
          profile_id: profileId,
        }
        // {
        //   cancelToken: source.token,
        // }
      );

      setProcessing(false);

      if (response.data.result) {
        loadDataFilter(date, profileId);
        onCloseDialog();
      }
    } catch (error) {
      setProcessing(false);

      // console.log(error);

      // if (axios.isCancel(error)) {
      //   console.log(error);
      // } else {
      //   throw error;
      // }
    }
  };

  const cancelReserve = async (classId) => {
    try {
      setProcessing(true);

      const response = await Auth(token).post(
        'livecourseclassdate/' + classId + '/cancel?apikey=' + API_KEY,
        {
          profile_id: profileId,
        }
        // {
        //   cancelToken: source.token,
        // }
      );

      setProcessing(false);

      if (response.data.result) {
       
        loadDataFilter(date, profileId);
        onCloseDialog();
      }
    } catch (error) {
      setProcessing(false);
      alert(error.response.data.error);
      // console.log(error);

      // if (axios.isCancel(error)) {
      //   console.log(error);
      // } else {
      //   throw error;
      // }
    }
  };

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      style={{ paddingTop: 130, paddingBottom: 60 }}
    >
      <HeaderPage title={name} description={description} />

      {classes && classes.length === 0 && (
        <Grid item xs={11}>
          <Message error={false} text='Não há aulas no momento' />
        </Grid>
      )}

      {classes && classes.length > 0 && (
        <>
          {classes.map((item, index) => {
            // return (
            //   <Slide
            //     key={index}
            //     title={`${item.day_week}, ${item.day} de ${item.month}`}
            //     values={item.dates}
            //     liveClass={true}
            //     onOpenDialog={onOpenDialog}
            //   />
            // );

            return (
              <>
                <Grid item xs={11}>
                  <Subtitle>{`${item.day_week}, ${item.day} de ${item.month}`}</Subtitle>
                </Grid>
                <Grid item xs={11}>
                  <GridAutoFlow>
                    {item.dates.map((classItem, index) => {
                      return (
                        <BoxContent
                          {...classItem}
                          key={index}
                          liveClass={true}
                          onOpenDialog={onOpenDialog}
                          hora = {montaTempo(classItem)}
                        />
                      );
                    })}
                  </GridAutoFlow>
                </Grid>
              </>
            );
          })}
          <Grid item xs={11}>
            <Button
              color='primary'
              style={{ display: 'block', margin: '0 auto' }}
              onClick={() => history.goBack()}
            >
              {`<`} voltar
            </Button>
          </Grid>
        </>
      )}

      {infoDialog && (
        <CustomDialog
          title={`${infoDialog.name || name} - ${infoDialog.initHour}`}
          open={true}
          onClose={onCloseDialog}
        >
          <BoardAboutClass
            id={infoDialog.id}
            countReserves={infoDialog.countReserves}
            numberParticipants={infoDialog.numberParticipants}
            hasVacancy={infoDialog.hasVacancy}
            image={infoDialog.teacher.image_thumb[1].secure_url_image}
            reserved={infoDialog.reserved}
            title={infoDialog.name || name}
            description={infoDialog.description || description}
            nameTeacher={infoDialog.teacher.name}
            processing={processing}
            allowReserve={infoDialog.allowReserve}
            dependencies={infoDialog.dependencies}
            makeReserve={makeReserve}
            cancelReserve={cancelReserve}
            onClose={onCloseDialog}
            link={infoDialog.link}
            time={infoDialog}
            init_at_complete_format={infoDialog.init_at_complete_format}
          />
        </CustomDialog>
      )}
    </Grid>
  );
};
