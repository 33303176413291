import React, { useState, useEffect } from "react";
import { makeStyles, AppBar, Tabs, Tab, TabPanel } from "@material-ui/core";
import "react-chat-elements/dist/main.css";
import { MessageBox, MessageList } from "react-chat-elements";
import { load } from "../../../localStorage";
import useFetch from "../../../hook/useFetch";
import client from "../../../client/api";
import { API_KEY } from "../../../Constants";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#004DC9",
    height: "90%",
    borderRadius: "10px",
    display: "flex",
    alignItems: "end",

    width: "100%",
    flexDirection: "column",
  },
  appBar: { borderRadius: "10px 10px 0 0" },
  tabs: { width: "100%" },
  messageList: {
    width: "100%",
    color: "rgba(0,0,0,0.7)",
    overflowY: "hidden",
    paddingTop: "10px",
    paddingRight: "15px",
  },
});

export default () => {
  const classes = useStyles();
  const [access_level, setAccessLevel] = useState("all");
  const [messageList, setMessageList] = useState([
    {
      position: "left",
      type: "text",
      title: "Carregando Avisos...",
      text: "Por favor aguarde o carregamento dos avisos.",
    },
  ]);
  const { profile } = load("user");
  const { children } = profile;
  const token = profile.user.auth_token;
  const childrenLive = children.filter((item) => item.has_live_course)[0];
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setAccessLevel(newValue);
    setMessageList([
      {
        position: "left",
        type: "text",
        title: "Carregando Avisos...",
        text: "Por favor aguarde o carregamento dos avisos.",
      },
    ]);
  };

  function genMessageList(data) {
    var messageList = [];
    data.forEach((item) => {
      var dateString = new Date(item.created_at);

      var message = {
        position: "left",
        type: "text",
        title: item.title,
        text: item.message,
        dateString: dateString.toLocaleDateString("pt-br"),
      };
      messageList.push(message);
    });

    if (messageList.length === 0) {
      messageList.push({
        position: "left",
        type: "text",
        text: "Não foi encontrado nenhum aviso no momento.",
      });
    }

    setMessageList(messageList);
    dispatch({
      type: "REFRESH_NOTICE_WALL",
    });
  }

  async function getData() {
    const response = await client.get(
      `noticewall/profilelist?profile_id=${childrenLive.id}&access_level=${access_level}&apikey=${API_KEY}`
    );

    genMessageList(response.data.result);
  }

  useEffect(() => {
    getData();
  }, [access_level]);

  return (
    <div className={classes.container}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          variant="fullWidth"
          className={classes.tabs}
          onChange={handleChange}
          value={access_level}
          indicatorColor="primary"
        >
          <Tab label="Todas os Avisos" value="all" />
          <Tab label="Todos os Alunos" value="general" />
          <Tab label="Minha Turma" value="class_group" />
          <Tab label="Para o Aluno" value="profile" />
        </Tabs>
      </AppBar>
      <MessageList
        toBottomHeight={0}
        className={classes.messageList}
        dataSource={messageList}
      />
    </div>
  );
};
