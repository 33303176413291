import React from "react";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import Coin from "../assets/coin.png";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: 10,
    paddingRight: 14,
    // paddingBottom: 8,
  },
  mobile: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: 10,
    paddingRight: 14,
    // paddingBottom: 8,
  },
  coinsTitle: {
    color: "#494949",
    fontSize: 18,
  },
  coinsTitleHome: {
    color: "#fff",
    fontSize: 18,
  },
  coinsTitleMobileHome: {
    color: "#fff",
    fontSize: 16,
  },
  coinsTitleMobile: {
    color: "#494949",
    fontSize: 16,
  },
  coin: {
    backgroundImage: "url(" + Coin + ")",
    backgroundSize: "cover",
    width: 64,
    height: 64,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 19,
    fontWeight: "bold",
    color: "rgba(0,0,0,0.8)",
  },

  coinMobile: {
    backgroundImage: "url(" + Coin + ")",
    backgroundSize: "cover",
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    fontWeight: "bold",
    color: "rgba(0,0,0,0.8)",
  },
  col: {
    display: "flex",
  },
});

function CoinsContainer(props) {
  const { coins, mobile, isLive } = props;
  const classes = useStyles();
  const theme = useTheme();
  const show = useMediaQuery(theme.breakpoints.up("md"));

  // if (show) {
  return (
    <>
      <div className={show ? classes.root : classes.mobile}>
        {show ? <div></div> : ""}
        <div
          style={{ display: "flex", alignItems: "center", columnGap: "5px" }}
        >
          <div className={show ? classes.coin : classes.coinMobile}>
            {coins}
          </div>

          {!mobile && isLive && (
            <div className={classes.col}>
              <h3
                className={show ? classes.coinsTitle : classes.coinsTitleMobile}
              >
                KIDSA KOINS
              </h3>
            </div>
          )}

          {!isLive && (
            <div className={classes.col}>
              <h3
                className={
                  show ? classes.coinsTitleHome : classes.coinsTitleMobileHome
                }
              >
                KIDSA KOINS
              </h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
  // }
  // return <></>;
}

export default CoinsContainer;
