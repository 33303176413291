import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
// import Slide from '../../components/Slide';
import HeaderPage from '../../components/HeaderPage';
import { API_KEY, BASE_URL } from '../../Constants';
import { Auth } from '../../client/api';
import { load } from '../../localStorage';
import axios from 'axios';
import Message from '../../ui/message';
import BoardAboutClass from '../../components/BoardAboutClass';
import CustomDialog from '../../ui/custom-dialog';

import Subtitle from '../../ui/subtitle';
import GridAutoFlow from '../../components/GridAutoFlow';
import BoxContent from '../../components/BoxContent';

import { useHistory } from 'react-router-dom';

export default (props) => {
  const token = load('user').profile.user.auth_token;
  const {classId, profileId } = useParams();
  let history = useHistory();
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [classes, setClasses] = React.useState(null);
  const [infoDialog, setInfoDialog] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);
  // const [selectedDate, handleDateChange] = React.useState(new Date());
  // let source;

  const inTimeForClass = async (dateStr1) => {
    try {
      const response = await fetch(`${BASE_URL}/time/iso?apikey=${API_KEY}`);
      console.log(response);
      if (!response.ok) {
        throw new Error(`Erro ao buscar a data atual. Status: ${response.status}`);
      }
      const dataString2 = await response.json();

      // const dataString2 = "2023-12-10T23:49:10.552Z";


      const partes = dateStr1.split(" ");
      const dataPartes = partes[0].split("/");
      const horaPartes = partes[1].split("h");
      const dataString1 = `${dataPartes[2]}-${dataPartes[1]}-${dataPartes[0]} ${horaPartes[0]}:${horaPartes[1]}:00.000 -0300`;

      const data1 = new Date(dataString1);
      const data2 = new Date(dataString2);

      const dezMinutosEmMilissegundos = 10 * 60 * 1000;

      return data2.getTime() >= data1.getTime() - dezMinutosEmMilissegundos;
    } catch (error) {
      console.error('Erro na função inTimeForClass:', error.message);
      return true;
    }
  };

  const onOpenDialog = async (data) => {
    // const isInTime = await inTimeForClass(data.init_at_complete_format);
    // data.isInTime = isInTime;
    setInfoDialog(data);
  };

  const onCloseDialog = () => {
    setInfoDialog(null);
  };

  React.useEffect(() => {
    // alert('aa');
    loadData();
    // return () => source.cancel('request canceled by the user.');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   console.log(location);
  //   return () => source.cancel('request canceled by the user.');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  const loadData = async () => {
    // source = axios.CancelToken.source();

    try {

      const response = await Auth(token).get(
        'singleclassdate/' +
          classId +
          '/datesbytolerance?profile_id=' +
          profileId +
          '&apikey=' +
          API_KEY
        
      );
  
      const { name, description, classes } = response.data.result;
      setName(name);
      setDescription(description);
      setClasses(classes);
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        throw error;
      }
    }
  };

  const verificaHora = (classItem) => {
    let horaAula = montaTempo(classItem);
    let objHoraAula = new Date(classItem.init_at);
    let timestampHoraAula = objHoraAula.getTime();
    if(horaAula != '')
    {
     
      horaAula = horaAula.split('h');
      objHoraAula.setHours(horaAula[0]);
      objHoraAula.setMinutes(horaAula[1]);
      timestampHoraAula = objHoraAula.getTime();
    }

    let horaAtual = new Date();
    let timestampHoraAtual = horaAtual.getTime();

    if(timestampHoraAtual < timestampHoraAula || timestampHoraAtual >= timestampHoraAula && classItem.reserved)
    {
      return true;
    } 
    else
    {
      return false;
    }

  }

  const montaTempo = (infodialog) => {
  //  console.log('infodialog:', infodialog);
    let dateTimezone = new Date();
    let timezone = dateTimezone.getTimezoneOffset() / 60;
    let separadorDataHorario = infodialog.init_at_complete_format.split(' ');
    let hora = separadorDataHorario[1].split('h');
    let data = separadorDataHorario[0].split('/');
        data = data[2] + '-'+ data[1] + '-' + data[0];
    let horaAula = data + 'T' + hora[0]+':'+ hora[1]+':00';
    let dateClass = new Date(horaAula);
    
      // Se 3 Igual ao Servidor
      if(timezone == 3){
        // console.log('timezone: ', );
        // let hora = `${dateClass.getHours()}:${dateClass.getMinutes()}`;
        // return hora;
      }
      else 
      {
        dateClass.setHours(dateClass.getHours() + 3);
        dateClass.setHours(dateClass.getHours() - timezone);
        let hora = (dateClass.getHours() < 10)? '0'+dateClass.getHours() : dateClass.getHours();
        let minutos = (dateClass.getMinutes() < 10 ) ? '0' + dateClass.getMinutes() : dateClass.getMinutes();
        return ( `${hora}h${minutos}`);
         
      } 
    return '';
  }

  const makeReserve = async (classId) => {


    try {
      setProcessing(true);

      const response = await Auth(token).post(
        // 'livecourseclassdate/' + classId + '/reserve?apikey=' + API_KEY,
        `singleclassdate/${classId}/reserve?apikey=${API_KEY}`,
        {
          profile_id: profileId,
        }
      
      );
      setProcessing(false);

      if (response.data.result) {
        loadData();
        onCloseDialog();
      }
    } catch (error) {
      setProcessing(false);

      console.log(error.response.status);
      if(error.response.status === 488)
      {
        alert('Você não tem Créditos Suficientes.');
      }
    
    }
  };

  const cancelReserve = async (classId) => {
    try {
      setProcessing(true);

      const response = await Auth(token).post(
        // 'livecourseclassdate/' + classId + '/cancel?apikey=' + API_KEY,
        `livecourseclassdate/${classId }/cancel?apikey=${API_KEY}`,
        {
          profile_id: profileId,
        }
     
      );

      setProcessing(false);

      if (response.data.result) {
        loadData();
        onCloseDialog();
      }
    } catch (error) {
      alert(error.response.data.error);
      setProcessing(false);

    }
  };

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      style={{ paddingTop: 130, paddingBottom: 60 }}
    >
      <HeaderPage title={name} description={description} />

      {classes && classes.length === 0 && (
        <>
          <Grid item xs={11}>
            <Message error={false} text='Não há aulas no momento' />
          </Grid>
          <Grid item xs={11}>
            <Button
              color='primary'
              style={{ display: 'block', margin: '0 auto' }}
              onClick={() => history.goBack()}
            >
              {`<`} voltar
            </Button>
          </Grid>
        </>
      )}

      {classes && classes.length > 0 && (
        <>
          {classes.map((item, index) => {
            // return (
            //   <Slide
            //     key={index}
            //     title={`${item.day_week}, ${item.day} de ${item.month}`}
            //     values={item.dates}
            //     liveClass={true}
            //     onOpenDialog={onOpenDialog}
            //   />
            // );

            return (
              <>
                <Grid item xs={11}>
                  <Subtitle>{`${item.day_week}, ${item.day} de ${item.month}`}</Subtitle>
                </Grid>
                <Grid item xs={11}>
                  <GridAutoFlow>

                    {item.dates.map((classItem, index) => {

                      if(verificaHora(classItem))
                      {
                          return (
                            <BoxContent
                              {...classItem}
                              key={index}
                              liveClass={true}
                              onOpenDialog={onOpenDialog}
                              calendar = {false}
                              hora = {montaTempo(classItem)}
                            />
                          );

                      }

                    })}


                  </GridAutoFlow>
                </Grid>
              </>
            );
          })}
          <Grid item xs={11}>
            <Button
              color='primary'
              style={{ display: 'block', margin: '0 auto' }}
              onClick={() => history.goBack()}
            >
              {`<`} voltar
            </Button>
          </Grid>
        </>
      )}

      {infoDialog && (
        <CustomDialog
          title={`${name} -`}
          subtitle = { `${(montaTempo(infoDialog) != '')? montaTempo(infoDialog):infoDialog.initHour} `}
          open={true}
          onClose={onCloseDialog}
        >
          <BoardAboutClass
            id={infoDialog.id}
            countReserves={infoDialog.countReserves}
            numberParticipants={infoDialog.numberParticipants}
            hasVacancy={infoDialog.hasVacancy}
            image={infoDialog.teacher.image_thumb[1].secure_url_image}
            reserved={infoDialog.reserved}
            title={name}
            description={description || ''}
            nameTeacher={infoDialog.teacher.name}
            processing={processing}
            makeReserve={makeReserve}
            cancelReserve={cancelReserve}
            onClose={onCloseDialog}
            link={infoDialog.link}
            time={infoDialog}
            singleclass={true}
            init_at_complete_format={infoDialog.init_at_complete_format}
            // isInTime={infoDialog.isInTime}
          />
        </CustomDialog>
      )}
    </Grid>
  );
};
