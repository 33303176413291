const dev = process.env.REACT_APP_KIDSA_ENV === "development" || process.env.NODE_ENV === "development";
// const dev = false;
// API
// ## API
const BASE_URL = dev
  ? "https://api-dev.kidsa.com" // "http://kidsa-english-api-dev2.us-east-1.elasticbeanstalk.com/"
  : "https://api.kidsa.com";

// const BASE_URL = "http://localhost:5000";

const API_KEY = dev
  ? "2e15ce96f866c8b10468cb37c59360ce"
  : "2e15ce96f866c8b10468cb37c59360ce";
// Facebook
const FACEBOOK_APP_ID = dev ? "578128016021363" : "216743259172059";
const FREE_USER = "58680a4647b1a9a6ba8566380df0bf9d";
export { BASE_URL, API_KEY, FACEBOOK_APP_ID, FREE_USER };

// ---
// const BASE_URL = 'http://kidsa-english-api-dev.us-east-1.elasticbeanstalk.com';
// const API_KEY = '2e15ce96f866c8b10468cb37c59360ce';
// const FACEBOOK_APP_ID = '578128016021363';
// const FREE_USER = '58680a4647b1a9a6ba8566380df0bf9d';
// export { BASE_URL, API_KEY, FACEBOOK_APP_ID, FREE_USER };
