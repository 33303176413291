import React, { useEffect } from "react";
import { Grid, Link } from "@material-ui/core";
import Paper from "../styles/paper";
import FormSignIn from "../containers/FormSignIn";
import Title from "../ui/title";
import Message from "../ui/message";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { getError } from "../redux/actions/utils";
import CapsLockAdvisor from "../components/CapsLockAdvisor";

const notify = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 10000,
  });
};

const Login = (props) => {
  useEffect(() => {
    if (props.error.length > 0) {
      notify(props.error);
    }
  }, [props.error]);

  useEffect(() => {
    // Substitua a URL abaixo pela URL para a qual você deseja redirecionar
    window.location.replace('https://www.portal.kidsa.com');
  }, []);
  return (
    <React.Fragment>
      <CapsLockAdvisor />
      <Grid
        item
        xs={11}
        sm={8}
        md={5}
        lg={4}
        style={{ paddingTop: 130, paddingBottom: 60 }}
      >
        <Grid item xs>
          <Title>FAÇA LOGIN PARA ACESSO</Title>
        </Grid>
        <Grid item xs sm md>
          <Paper>
            <FormSignIn />
            <Grid item xs={12}>
              <Message error={false} text={props.message} />
              <Link
                underline="always"
                style={{
                  textAlign: "center",
                  display: "block",
                  marginTop: 10,
                  fontWeight: "bold",
                }}
                color="secondary"
                href="https://kidsa.com/curso/organico/"
              >
                Faça seu cadastro!
              </Link>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ToastContainer />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  error: getError(state),
});
export default connect(mapStateToProps, {})(Login);
