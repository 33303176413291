import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Typography,
  Button,
} from "@material-ui/core";
import { useParams, useLocation, Redirect, useHistory } from "react-router-dom";

import ReactPlayer from "react-player";
import PandaPlayer from "../components/PandaPlayer";

import Loading from "../components/Loading";
import { load } from "../localStorage";
import useFetchDataVideo from "../hook/useFetchDataVideo";
import { isMobile } from "../utils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SimpleDialog from "../components/SimpleDialog";
import DoneIcon from "@material-ui/icons/Done";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Clear from "@material-ui/icons/Clear";
import { Auth } from "../client/api";
import { API_KEY } from "../Constants";

const mobile = isMobile();

const useStyles = () =>
  makeStyles((theme) => ({
    container: {
      paddingTop: "160px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "85px",
      },
      paddingBottom: "80px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
    },
    containerLandscape: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
    },
    presenceValidateContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "20px",
      paddingTop: "10px",
    },
    player: {
      height: "100% !important",
      width: "100% !important",
      borderWidth: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    playerChange: {
      height: "100% !important",
      width: "85% !important",
      borderWidth: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      fontSize: "60px",
    },
    fullScreenButton: {
      position: "absolute",
      right: "25px",
      top: "188px",
      [theme.breakpoints.down("sm")]: {
        top: "200px",
        right: "5px",
      },
      height: "50px",
      width: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#3f51b5",
      opacity: "0.6",
      zIndex: 999,
      color: "#fff",
      cursor: "pointer",
      "&:hover": {
        opacity: "1",
      },
    },
    backButton: {
      position: "absolute",
      left: "5px",
      top: "188px",
      [theme.breakpoints.down("sm")]: {
        top: "200px",
      },
      height: "50px",
      width: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#3f51b5",
      opacity: "0.6",
      zIndex: 999,
      color: "#fff",
      cursor: "pointer",
      "&:hover": {
        opacity: "1",
      },
    },
  }));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Video = () => {
  // Para desativar o Panda Player basta mudar a variável usePandaPlayer para false
  const usePandaPlayer = true;
  const [pandaPlayerType, setPandaPlayerType] = React.useState("video_hls");
  const [changePlayer, setChangePlayer] = React.useState(true);
  const matches = useMediaQuery("(orientation: landscape)");
  let { id } = useParams();
  let query = useQuery();
  let history = useHistory();
  const theme = useTheme();
  const styles = useStyles(theme)();
  const [validate, setValidate] = React.useState("NOT_STARTED");
  const [validating, setValidating] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const token = load("user").profile.user.auth_token;
  const [currentId, setCurrentId] = React.useState(Number(id));
  const [endPoint, setEndPoint] = React.useState(
    "videocategory/item/" + currentId
  );
  const { profile } = load("user");

  const activatePandaPlayer = () => {
    ReactPlayer.addCustomPlayer(PandaPlayer);
    setPandaPlayerType("video_player");
  };

  useEffect(() => {
    if(usePandaPlayer) {
      activatePandaPlayer();
    }
    
  }, []);

  let state = useFetchDataVideo(endPoint, null, token);
  if (state.loading) return <Loading />;
  if (state.video === null) return <p>Erro ao tentar carregar o conteúdo</p>;
  if (state.error) return <Redirect push to="/videos" />;

  const handleValidatePrensence = () => {
    setValidate("VALIDATING");
    setValidating(true);
    const response = Auth(token)
      .put(`videocategory/validate/${currentId}?apikey=${API_KEY}`, {})
      .then((response) => {
        setValidate("VALIDATED");
        setError(null);

        setTimeout(() => {
          history.push(
            "/videos?title=VÍDEOS&description=Milhares%20de%20vídeos%20para%20aprender%20muito!%20Desenhos%20animados,%20músicas,%20estórias,%20meditação%20e%20muito%20mais."
          );
        }, 2000);
      })
      .catch((error) => {
        setValidate("NOT_VALIDATED");
        console.log(error);
        setError("Erro ao validar presença");
      });
  };

  function handleFullScreen() {
    const player = document.querySelector("#player-video");
    if (document.fullscreenElement) {
      // Elemento já está em fullscreen, sair do fullscreen
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      // Elemento não está em fullscreen, entrar no fullscreen
      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.webkitRequestFullscreen) {
        // Safari
        player.webkitRequestFullscreen();
      } else if (player.msRequestFullscreen) {
        // IE11
        player.msRequestFullscreen();
      }
      setFullScreen(true);
    }
  }

  function exitFullscreen() {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  return (
    <>
      <SimpleDialog open={validating}>
        <div className={styles.presenceValidateContainer}>
          {validate === "VALIDATING" ? <CircularProgress /> : ""}
          {validate === "VALIDATED" ? (
            <DoneIcon style={{ color: "#00bcd4" }} className={styles.icon} />
          ) : (
            ""
          )}
          {validate === "NOT_VALIDATED" ? (
            <Clear style={{ color: "#FF3C3D" }} className={styles.icon} />
          ) : (
            ""
          )}
          <div>
            {validate === "VALIDATED" ? (
              <>
                {" "}
                <Typography variant="h2" color="primary">
                  Moeda validada com sucesso <br />
                  Em instantes você será redirecionado para a página de turmas.
                </Typography>
              </>
            ) : (
              ""
            )}

            {validate === "VALIDATING" ? (
              <>
                {" "}
                <Typography variant="h2" color="primary">
                  Por favor aguarde estamos
                  <br />
                  processando sua Moeda.
                </Typography>
              </>
            ) : (
              ""
            )}

            {validate === "NOT_VALIDATED" ? (
              <>
                {" "}
                <Typography variant="h2" color="primary">
                  Houve um erro. <br />
                  Por favor tente novamente.
                </Typography>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {validate === "NOT_VALIDATED" && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleValidatePrensence}
            >
              Tentar Novamente
            </Button>
          )}
        </div>
      </SimpleDialog>
      <Grid
        item
        xs={12}
        className={
          matches && fullScreen ? styles.containerLandscape : styles.container
        }
        id="player-video"
      >
        <div className={styles.backButton} onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </div>

        {typeof state.files.link === "undefined" &&
        pandaPlayerType === "video_hls" ? (
          <div
            className={styles.fullScreenButton}
            onClick={() => handleFullScreen()}
          >
            {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </div>
        ) : (
          <></>
        )}

        <ReactPlayer
          controls
          pandaControls={`play-large,rewind,play,${profile.masterAccess ? 'progress,' : ''}current-time,volume,captions,settings,fullscreen,cast,airplay`}
          playing
          playsinline
          changePlayer={setChangePlayer}
          url={
            typeof state.files.link === "undefined"
              ? state.files[pandaPlayerType]
              : state.files.link
          }
          className={
            usePandaPlayer && changePlayer ? styles.playerChange : styles.player
          }
          config={{
            file: {
              attributes: {
                disablePictureInPicture: true,
                autopictureinpicture: "false",
              },
            },
          }}
          onEnded={() => {
            exitFullscreen();
            handleValidatePrensence();
          }}
        />
      </Grid>
    </>
  );
};

export default Video;

