import React from "react";
import { Grid } from "@material-ui/core";
import Paper from "../../styles/paper";
import Title from "../../ui/title";
import { API_KEY } from "../../Constants";
import { Auth } from "../../client/api";
import { load } from "../../localStorage";
import axios from "axios";
import FormChildApplyLiveCourse from "../../containers/FormChildApplyLiveCourse";

// export default () => {
//   return <div>child apply</div>;
// };

export default (props) => {
  const { profile } = load("user");
  const { children, subscription } = profile;
  const [courses, setCourses] = React.useState([]);
  const [turmas, setTurmas] = React.useState([]);

  React.useEffect(() => {
    const token = load("user").profile.user.auth_token;
    const source = axios.CancelToken.source();

    const loadData = async () => {
      try {
        const response = await Auth(token).get(
          "/livecourse/list?apikey=" + API_KEY,
          {
            cancelToken: source.token,
          }
        );

        setCourses(response.data.result);
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error;
        }
      }
    };

    const loadTurmas = async () => {
      try {
        const response = await Auth(token).get(
          "livecourseclassturmas/getTurmas" + "?apikey=" + API_KEY,
          {
            cancelToken: source.token,
          }
        );
        setTurmas(response.data.result);
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error;
        }
      }
    };

    loadTurmas();
    loadData();

    return () => source.cancel("livecourse/list canceled by the user.");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        item
        xs={11}
        sm={8}
        md={5}
        lg={4}
        style={{ paddingTop: 130, paddingBottom: 60 }}
      >
        <Grid item xs>
          <Title>ESCOLHA QUAL CRIANÇA PARTICIPARÁ DO KIDSA LIVE</Title>
        </Grid>
        <Grid item xs sm md>
          <Paper>
            <FormChildApplyLiveCourse
              subscription_id={subscription.id}
              courses={courses}
              children={children}
              turmas={turmas}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
