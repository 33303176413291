import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import InfoAboutPayLive from "./InfoAboutPayLive";
import NewChild from "./NewChild";
import ChildApplyLive from "./ChildApplyLive";
import Calendar from "./Calendar";
import MyReserves from "./MyReserves";
import Homework from "./Homework";
import Class from "./Class";
import Turma from "./Turma";
import FilterClass from "./FilterClass";
import { load } from "../../localStorage";
import { FREE_USER } from "../../Constants";
import HomeworkDetail from "./HomeworkDetail";
import ChooseCredit from "./ChooseCredit";
import ChildrenProfile from "./ChildrenProfile";
import useFetch from "../../hook/useFetch";
import Loading from "../../components/Loading";
import Vacation from "./Vacation";
import PromotionPage from "./PromotionPage";
import RecordedVideoPlayer from "../RecordedVideoPlayer";
import Calendarnew from "./Calendarnew";
import FeriasNoClub from "../FeriasNoClub";

export default () => {
  let { path } = useRouteMatch();
  const history = useHistory();
  const { profile } = load("user");
  const token = profile.user.auth_token;

  const liveAccess = useFetch(`customer/livecustomer`, null, token);

  useEffect(() => {
    if (liveAccess.loading === false) {
      if (
        (profile.user.type_user === FREE_USER && liveAccess.data == null) ||
        liveAccess.data.allow_live_access === false
      ) {
        history.push("/clientapp");
      }
    }
  }, [liveAccess]);

  // const path = '/live'; // to test :()

  if (liveAccess.loading) return <Loading />;

  return (
    <Switch>
      <ControlLiveRoute exact path={path} redirect={`${path}/calendar`}>
        <InfoAboutPayLive />
      </ControlLiveRoute>

      <ControlLiveRoute path={`${path}/new-child`} redirect={path}>
        <NewChild />
      </ControlLiveRoute>

      <ControlLiveRoute path={`${path}/child-apply-live`} redirect={`${path}`}>
        <ChildApplyLive />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/singleclass`}
        redirect={`${path}/child-apply-live`}
        exact
      >
        <Calendar />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/calendar`}
        redirect={`${path}/child-apply-live`}
        exact
      >
        <Calendarnew />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/my-reserves`}
        redirect={`${path}/child-apply-live`}
      >
        <MyReserves />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/homework`}
        redirect={`${path}/child-apply-live`}
        exact
      >
        <Homework />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/filter`}
        redirect={`${path}/child-apply-live`}
      >
        <FilterClass />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/calendar/class/:classId/profile/:profileId`}
        redirect={`${path}/child-apply-live`}
      >
        <Class />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/classplayer/:class_date`}
        redirect={`${path}/child-apply-live`}
      >
        <RecordedVideoPlayer />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/homework/detail/:id`}
        redirect={`${path}/child-apply-live`}
      >
        <HomeworkDetail />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/turmas`}
        redirect={`${path}/child-apply-live`}
      >
        <Turma />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/credit`}
        redirect={`${path}/child-apply-live`}
      >
        <ChooseCredit />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/profile`}
        redirect={`${path}/child-apply-live`}
      >
        <ChildrenProfile />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/vacation`}
        redirect={`${path}/child-apply-live`}
      >
        <Vacation />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/promotion-page`}
        redirect={`${path}/child-apply-live`}
      >
        <PromotionPage />
      </ControlLiveRoute>

      <ControlLiveRoute
        path={`${path}/feriasnoclub`}
        redirect={`${path}/child-apply-live`}
      >
        <FeriasNoClub />
      </ControlLiveRoute>
    </Switch>
  );
};

function ControlLiveRoute({ children, path, redirect, ...rest }) {
  const user = load("user");

  const { profile } = user;

  let renderChildren;

  if (path === "/live") {
    if (profile.live_subscription_pending && profile.children.length > 0) {
      renderChildren = null;
      redirect = "/live/child-apply-live";
    } else {
      renderChildren =
        !profile.subscription || profile.user.type_user === FREE_USER
          ? children
          : null;
    }
  } else if (path === "/live/new-child") {
    renderChildren =
      !profile.children || profile.children.length === 0 ? children : null;
  } else if (path === "/live/child-apply-live") {
    renderChildren = profile.live_subscription_pending ? children : null;
  } else if (
    path === "/live/singleclass" ||
    path === "/live/filter" ||
    path === "/live/calendar/class/:classId/profile/:profileId" ||
    path === "/live/my-reserves" ||
    path === "/live/turmas" ||
    path === "/live/homework" ||
    path === "/live/homework/detail/:id" ||
    path === "/live/credit" ||
    path === "/live/profile" ||
    path === "/live/vacation" ||
    path === "/live/promotion-page" ||
    path === "/live/calendar" ||
    path === "/live/classplayer/:class_date" ||
    path === "/live/feriasnoclub"
  ) {
    const { subscription } = profile;

    if (!subscription || !subscription.allow_live_access) {
      renderChildren = null;
      redirect = "/live";
    } else {
      if (!profile.children || profile.children.length === 0) {
        renderChildren = null;
        redirect = "/live/new-child";
      } else {
        let atLeastOneChild = profile.children.filter(
          (item) => item.has_live_course === true
        );
        renderChildren =
          subscription && atLeastOneChild.length > 0 ? children : null;
      }
    }
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        renderChildren !== null ? (
          renderChildren
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
