import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import KoinsExchangeHeader from "../components/KoinsExchange/KoinsExchangeHeader";
import KoinsExchangeGrid from "../components/KoinsExchange/KoinsExchangeGrid";
import { ToastContainer, toast } from "react-toastify";

const notify = (msg, type) => {
  if (type === "error") {
    toast.error(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 10000,
    });
  } else {
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 10000,
    });
  }
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "150px",
    paddingBottom: "150px",
  },
});

function KoinsExchange() {
  useEffect(() => {
    var notification = JSON.parse(localStorage.getItem("koinsExchangeNotify"));
    console.log(notification);

    if (notification !== null) {
      notify(notification.msg, notification.type);
      localStorage.removeItem("koinsExchangeNotify");
    }
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <KoinsExchangeHeader />
      <KoinsExchangeGrid />
      <ToastContainer />
    </div>
  );
}

export default KoinsExchange;
