import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import KoinsExchangeItem from "./KoinsExchangeItem";
import KoinsExchangeDialog from "./KoinsExchangeDialog";
import Dialog from "../../ui/custom-dialog";
import { load } from "../../localStorage";
import useFetch from "../../hook/useFetch";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    rowGap: "10px",
    columnGap: "10px",
    flexWrap: "wrap",
    maxWidth: 1200,
    width: "100%",
    justifyContent: "center",
    marginTop: "15px",
  },
});

const is_enabled = (item, koins) => {
  var value = null;
  if (item.minValue !== 0) {
    value = item.minValue;
  } else {
    value = item.value;
  }

  return koins >= value;
};
function KoinsExchangeGrid({}) {
  const { profile } = load("user");
  const { children } = profile;
  const token = profile.user.auth_token;
  const koinExchangeItens = useFetch(`koinsexchangeitem`, null, token);
  if (koinExchangeItens.loading == false) {
    koinExchangeItens.data.koinsItems = koinExchangeItens.data.koinsItems.filter((item) => item.origin === "portal_velho")
  }

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [seletedItem, setSeletedItem] = useState(null);
  const handleOpen = (item) => {
    setOpen(true);
    setSeletedItem(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      {koinExchangeItens.loading === false
        ? koinExchangeItens.data.koinsItems.map((item, key) => (
            <KoinsExchangeItem
              handleOpen={handleOpen}
              item={item}
              key={key}
              koins={koinExchangeItens.data.koins}
            />
          ))
        : ""}
      {/* <KoinsExchangeItem handleOpen={handleOpen} /> */}

      <Dialog
        open={open}
        onClose={handleClose}
        subtitle={seletedItem != null ? seletedItem.title : ""}
        title={"Troque seus KOINS"}
      >
        <KoinsExchangeDialog
          seletedItem={seletedItem}
          koins={
            koinExchangeItens.loading === false
              ? koinExchangeItens.data.koins
              : 0
          }
        />
      </Dialog>
    </div>
  );
}

export default KoinsExchangeGrid;
