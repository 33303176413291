import React from "react";
import "../containers/CardCredit.css";
import FriendCredit from "../assets/friendCredit.png";

export default class CardCredit extends React.Component {
  render() {
    return (
      <div className="product-card">
        <div className="product-tumb">
          <img src={FriendCredit} alt="" />
        </div>
        <div className="product-details">
          <p>
            Cada indicação que virar aluno você ganhará um mês adicional em seu
            plano do Kidsa! Clique no botão abaixo e preencha o formulário para
            indicar.
          </p>
          <div className="product-bottom-details">
            {/* <div className="product-price">R${`${parseFloat(credit.value_money).toFixed(2)}`.replace('.',',')}</div> */}

            <div className="product-price"></div>
            <div className="product-links"></div>
          </div>
          <div className="container-Button">
            <button
              className="btn-Credits"
              onClick={() => {
                window.open("https://kidsa.com/2/indicou0/", "_blank");
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
              INDICAR{" "}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
