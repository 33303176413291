import React from "react";
import { Button, Chip } from "@material-ui/core";
import { RotateLeft } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import forceFullscreen from "../hoc/forceFullscreen";
import { Auth } from "../client/api";
import { load } from "../localStorage";
import ListenAndTap from "../game/ListenAndTap";
import Loading from "../components/Loading";
import { isMobile, isLandscape } from "../utils";
import { API_KEY } from "../Constants";
import useQuery from "../hook/useQuery";

var json = null;
let contentWindow;
let mobile = isMobile();

const Test = () => {
  let { id } = useParams();

  const [showGame, setShowGame] = React.useState(false);
  const [loading, setLoading] = React.useState(null);
  const [landscape, setLandscape] = React.useState(isLandscape());
  const [startGame, setStartGame] = React.useState(false);
  const [loadedGame, setLoadedGame] = React.useState(false);

  let myRef = React.createRef();

  const query = useQuery();
  const singleclass = query.get("singleclass");

  var typeOfClassUrl =
    singleclass === "true" ? "singleclasshomework" : "livecourseclasshomework";

  const loadGame = () => {
    setLoading(true);

    const token = load("user").profile.user.auth_token;
    Auth(token)
      .get(typeOfClassUrl + "/" + id + "/detail?apikey=" + API_KEY) // GET DATA CONTENT
      .then(
        (response) => {
          json = response.data.result;

          setLoading(false);
          setShowGame(true);
          setLoadedGame(true);
          return;
        },
        ({ response }) => {
          setLoading(false);
          setLoadedGame(false);
        }
      )
      .catch((error) => {
        setLoading(false);
        setLoadedGame(false);
      });
  };

  const start = () => {
    contentWindow = document.getElementById("game").contentWindow;
    contentWindow.loadGame(JSON.stringify(json));
    contentWindow.startGame();
  };

  React.useEffect(() => {
    if (isMobile()) {
      if (!loadedGame && landscape) loadGame();
      window.addEventListener(
        "orientationchange",
        function () {
          setLandscape(isLandscape());
        },
        false
      );
    } else {
      if (!loadedGame) loadGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedGame, landscape, startGame]);

  return (
    <div style={{ backgroundColor: "white" }}>
      {showGame && (
        <ListenAndTap
          ref={myRef}
          isMobile={mobile}
          json={json}
          style={
            !mobile
              ? { display: "flex" }
              : { display: landscape ? "flex" : "none" }
          }
        />
      )}

      {/* Warnings */}
      <div
        style={{ display: !mobile || loading ? "none" : "block", zIndex: 2 }}
      >
        {mobile && !landscape && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginLeft: "-111px",
              marginTop: "-16px",
            }}
          >
            <Chip
              color="secondary"
              label="ROTATE YOUR SMARTPHONE"
              icon={<RotateLeft />}
            />
          </div>
        )}

        <Button
          disableRipple
          style={{
            display: startGame ? "none" : landscape ? "block" : "none",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginLeft: "-60px",
            marginTop: "-16px",
          }}
          onClick={() => {
            setStartGame(true);

            if (showGame) {
              myRef.current.startGame();
            } else {
              start();
            }
          }}
          color="primary"
        >
          START GAME
        </Button>
      </div>

      {/* Loading */}
      {loading && <Loading />}
    </div>
  );
};

export default forceFullscreen(Test, "/games", mobile, true);
