import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  outsideWhiteBorder: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 12,
    padding: 2,
    // shadowOffset: {width: 0, height: 3},
  },
  outsideBlackBorder: {
    flex: 1,
    backgroundColor: "#424242",
    borderRadius: 11,
    padding: 2,
  },
  background: {
    display: "flex",
    backgroundColor: "#CCCCCC",
    borderRadius: 10,
  },
  progress: {
    width: "0%",
    height: "15px",
    backgroundColor: "#FFD714",
    borderRadius: 10,
  },
});

export default ({index, length}) => {
  const styles = useStyles();
  let pct = (index / length) * 100;
  let width = {width: pct + "%"};

  return (
    <div className={styles.outsideWhiteBorder}>
      <div className={styles.outsideBlackBorder}>
        <div className={styles.background}></div>
        <div className={`${styles.progress}`} style={width}></div>
      </div>
    </div>
  );
};
