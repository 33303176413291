import React, { useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Link,
  CircularProgress,
} from "@material-ui/core";
import SubmitSkinButton from "../../styles/submit-skin-button";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SentimentVeryDissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import Slider from "react-slick";
import KoinsExchangeService from "../../client/koinsExchange";
import { load } from "../../localStorage";
import { API_KEY } from "../../Constants";
import client from "../../client/api";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    justifyContent: "center",
  },
  sliderContainer: {
    padding: 30,
  },
  sliderContent: {
    height: "180px",
  },
  variant: {
    marginTop: 15,
    marginBottom: 15,
  },
  link: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: 10,
    fontSize: "16px",
  },
  seletedVariantError: {
    color: "red",
    padding: 5,
    textAlign: "center",
    fontWeight: 600,
  },
});

function getChildLive(listChildren) {
  const atLeastOneChildLive = listChildren.filter(
    (item) => item.has_live_course === true
  );

  return atLeastOneChildLive.length > 0 ? atLeastOneChildLive[0] : null;
}

const Arrow = ({ className, to, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`button button--text button--icon ${className}`}
    aria-label={to}
  >
    {to === "next" ? (
      <ArrowForwardOutlinedIcon color="primary" />
    ) : (
      <ArrowBackOutlinedIcon color="primary" />
    )}
  </button>
);

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 0,
  className: "center",
  centerMode: true,
  nextArrow: <Arrow to="next" />,
  prevArrow: <Arrow prev="prev" />,
};

const is_enabled = (item, koins) => {
  var value = null;
  if (item.min_value !== 0) {
    value = item.min_value;
  } else {
    value = item.value;
  }
  console.log(item);
  return parseInt(koins) >= value;
};

function KoinsExchangeDialog({ handleClose, seletedItem, koins }) {
  const enabled = is_enabled(seletedItem, koins);
  const [seletedVariant, setSeletedVariant] = useState(
    seletedItem.variants.length > 0 ? 0 : null
  );
  const [seletedVariantValue, setSeletedVariantValue] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [seletedVariantError, setSeletedVariantError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const classes = useStyles();

  const { profile } = load("user");
  const children = getChildLive(profile.children);

  const handleSubmit = async () => {
    if (seletedVariant === 0) {
      setSeletedVariantError("Por favor selecione pelo menos uma opção.");
      setProcessing(false);
      return;
    }
    setProcessing(true);
    setSeletedVariantError(null);

    let koinExchangeItem = seletedItem;
    koinExchangeItem.seletedVariant = seletedVariant;
    var koins_value = 0;
    if (seletedVariant !== null) {
      koins_value = seletedVariant.value;
    } else {
      if (seletedItem.min_value !== 0) {
        koins_value = seletedItem.min_value;
      } else {
        koins_value = seletedItem.value;
      }
    }
    const response = await client.post(
      `/koinsexchangeorder/new?apikey=${API_KEY}`,
      {
        koins_exchange_item_id: seletedItem.id,
        koins_exchange_variant_id: koinExchangeItem.seletedVariant.id,
        koins_value: koins_value,
        profile_id: children.id,
      }
    );
    if (response.status === 200) {
      if (response.data.error === "duplicate order") {
        setError(true);
      } else {
        setSuccess(true);
      }
    } else {
      setError(true);
      setErrorMsg(
        "Houve um erro em processar a solicitação, por favor tente novamente."
      );
    }
  };

  if (error === true) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          rowGap: "15px",
          textAlign: "center",
        }}
      >
        <div>
          <SentimentVeryDissatisfied
            style={{
              fontSize: "100px",
              color: "red",
            }}
          />
        </div>
        <div
          className="content"
          style={{ fontSize: "1.2em", textAlign: "justify" }}
        >
          Houve um erro em processar a solicitação, por favor tente novamente.
        </div>
        <SubmitSkinButton
          fullWidth
          onClick={() => {
            document.location.reload(true);
          }}
        >
          Fechar
        </SubmitSkinButton>
      </div>
    );
  }

  if (success === true) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          rowGap: "15px",
          textAlign: "center",
        }}
      >
        <div>
          <CheckCircleIcon
            style={{
              fontSize: "100px",
              color: "green",
            }}
          />
          <h3 style={{ color: "green" }}>Recebemos o seu pedido! </h3>
        </div>
        <div
          className="content"
          style={{ fontSize: "1.2em", textAlign: "justify" }}
        >
          Agora é só aguardar. Nossa equipe entrará em contato através do
          whatsapp em até 24 horas. Dentro do horário comercial.
        </div>
        <SubmitSkinButton
          fullWidth
          onClick={() => {
            document.location.reload(true);
          }}
        >
          Fechar
        </SubmitSkinButton>
      </div>
    );
  }
  return (
    <Box className={classes.root}>
      {seletedItem.description != null && seletedItem.description != "null" ? (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: seletedItem.description }}
        ></div>
      ) : (
        ""
      )}
      {seletedItem.infoLink != null && seletedItem.infoLink != "null" ? (
        <div className={classes.link}>
          <Link href={seletedItem.info_link} target="_blank">
            Saiba mais sobre item
          </Link>
        </div>
      ) : (
        ""
      )}
      {seletedItem.image !== null ? (
        <div className={classes.sliderContainer}>
          <Slider {...settings}>
            <figure className={classes.sliderContent}>
              <Zoom zoomMargin={100}>
                <img
                  src={seletedItem.image}
                  className={classes.sliderContent}
                />
              </Zoom>
            </figure>
          </Slider>
        </div>
      ) : (
        ""
      )}

      {typeof seletedItem.variants != "undefined" &&
        seletedItem.variants.length > 0 && (
          <FormControl variant="outlined" className={[classes.variant]}>
            <InputLabel>{seletedItem.variant_text}</InputLabel>
            <Select
              id="gender"
              name="gender"
              label={seletedItem.variant_text}
              variant="outlined"
              value={seletedVariant.id}
              error={seletedVariantError}
              helperText={seletedVariantError}
              onChange={(event) => {
                setSeletedVariant(seletedItem.variants[event.target.value]);
              }}
            >
              {seletedItem.variants.map((variant, index) => (
                <MenuItem
                  value={index}
                  key={variant.id}
                  disabled={variant.value > koins}
                  onClick={() => setSeletedVariantValue(variant.value)}
                  required
                >
                  {variant.description}
                </MenuItem>
              ))}
            </Select>
            {seletedVariantError && (
              <small className={classes.seletedVariantError}>
                {seletedVariantError}
              </small>
            )}
          </FormControl>
        )}

      {enabled === true ? (
        <SubmitSkinButton
          fullWidth
          // className={styles.button}
          onClick={handleSubmit}
        >
          {processing ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            "Enviar"
          )}
        </SubmitSkinButton>
      ) : (
        <SubmitSkinButton disabled fullWidth>
          Koins insuficientes
        </SubmitSkinButton>
      )}
    </Box>
  );
}

export default KoinsExchangeDialog;
