import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

export default withStyles({
  root: {
    '& .Mui-error': {
      // color: 'red',
      '& input:invalid + fieldset': {
        borderColor: '#d0d0d3',
        borderWidth: 1,
        padding: 10
      },
    },
    '& label': {
      fontFamily: 'Arial',
      fontSize: 14,
      // color: '#d0d0d3',
      '&.Mui-focused': {
        color: '#d0d0d3'
      },
      '&.Mui-error': {
        color: 'red'
      }
    },
    '& input': {
      fontFamily: 'Arial',
      fontSize: 14,
      borderColor: '#d0d0d3',
      borderWidth: 1,
    },
    '& input:valid + fieldset': {
      borderColor: '#d0d0d3',
      borderWidth: 1,
    },
    '& p.MuiFormHelperText-contained': {
      marginTop: 3,
      marginBottom: 3,
      marginLeft: 6,
      marginRight: 6,
      color: 'red',
      fontFamily: 'Arial',
      fontSize: 8,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#d0d0d3',
      },
      '&:hover fieldset': {
        borderColor: '#d0d0d3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d0d0d3',
        borderWidth: 1
      },
    },
  },
})(TextField);