import axios from 'axios';
import { BASE_URL } from '../Constants';

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  },
});

export const Auth = (authToken) => {
  if (authToken) {
    // console.log('Bearer ' + authToken);
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
  } else {
    instance.defaults.headers.common['Authorization'] = '';
  }
  return instance;
};

export default instance;