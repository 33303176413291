import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_KEY, BASE_URL } from "../Constants";
import setas_ferias_club from "../assets/setas_ferias_club.png";
import wow from "../assets/wow.png";
import evento_ferias from "../assets/ganhe_evento_especial.png";
import premios from "../assets/fone_tablet.png";
import koin_progress_bar from "../assets/koin_ferias_club.png";
import tickets from "../assets/tickets_ferias-club.png";
import koin_star from "../assets/koin_star_ferias_club.png";
import congratz from "../assets/congratz_ferias_club.png";
import "../styles/ferias-no-club.css";

export default (props) => {
  const [coinsList, setCoinsList] = useState([]);
  const [cuponsList, setCuponsList] = useState([]);
  const [loadingCoins, setLoadingCoins] = useState(true);
  const [loadingCupons, setLoadingCupons] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [coinsToNextCupons, setCoinsToNextCupons] = useState(0);

  const styles = {
    mainDiv: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 190,
      paddingBottom: 100,
      backgroundColor: "#D1E1E4",
    },
    button: {
      marginTop: "80px",
      background: "#F68B1B",
      borderRadius: "25px 25px 25px 25px",
      border: "none",
      padding: "0",
      cursor: "pointer",
      outlineOffset: "4px",
      transition: "transform 0.2s ease",
    },
    buttonSpan: {
      display: "block",
      padding: "12px 42px",
      borderRadius: "25px 25px 25px 25px",
      fontSize: "1.25rem",
      background: "#FEB900",
      color: "#0d0a66",
      transform: "translateY(-6px)",
      fontWeight: "700",
    },
    title: {
      color: "#0d0a66",
      marginTop: "16px",
    },
    tabelaStyle: {
      width: "100%",
      borderCollapse: "collapse",
      marginTop: "20px",
      color: "#494949",
      fontWeight: "500",
      height: "100%",
      zIndex: "1",
    },
    celulaStyle: {
      border: "3px solid #0d0a66",
      padding: "8px",
      textAlign: "center",
      backgroundColor: "white",
    },
    cabecalhoStyle: {
      backgroundColor: "#D4070D",
      color: "white",
    },
    linhaStyle: {
      backgroundColor: "#f9f9f9",
    },
    tabelaStyleCupons: {
      width: "100%",
      borderCollapse: "collapse",
      marginTop: "20px",
      height: "100%",
      zIndex: "2",
      position: "relative",
    },
    celulaStyleCupons: {
      border: "3px solid #0d0a66",
      padding: "8px",
      textAlign: "center",
      backgroundColor: "white",
    },
    cabecalhoStyleCupons: {
      backgroundColor: "#D4070D",
      color: "white",
    },
    linhaStyleCupons: {
      backgroundColor: "#f9f9f9",
    },
    progressBar: {
      marginTop: "32px",
      position: "relative",
      width: "30%",
      height: "30px",
      borderRadius: "30px",
      backgroundColor: "white",
      boxShadow: "3px 4px 5px 0px rgba(204 185 185 / .75)",
      zIndex: "1",
    },
    progressBarSpan: {
      position: "absolute",
      top: "0",
      left: "0",
      width: `${percentage}%`,
      height: "100%",
      color: "white",
      fontWeight: "800",
      borderRadius: "30px",
      backgroundColor: "#FEB900",
      zIndex: "2",
    },
  };

  useEffect(() => {
    const getCoinList = async () => {
      try {
        const profileData = JSON.parse(localStorage.getItem("user"));

        const headers = {
          headers: {
            Authorization: `Bearer ${profileData.profile.user.auth_token}`,
          },
        };
        const children = profileData.profile.children;
        const childrenLive = children.filter((item) => item.has_live_course)[0];
        const { data } = await axios.get(
          `${BASE_URL}/ferias_no_club/list_coins/${childrenLive.id}?apikey=${API_KEY}`,
          headers
        );

        setCoinsList(data.response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingCoins(false);
      }
    };

    const getCuponsList = async () => {
      try {
        const profileData = JSON.parse(localStorage.getItem("user"));
        const headers = {
          headers: {
            Authorization: `Bearer ${profileData.profile.user.auth_token}`,
          },
        };
        const children = profileData.profile.children;
        const childrenLive = children.filter((item) => item.has_live_course)[0];
        const { data } = await axios.get(
          `${BASE_URL}/ferias_no_club/list_lucky_numbers/${childrenLive.id}?apikey=${API_KEY}`,
          headers
        );

        setCuponsList(data.response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingCupons(false);
      }
    };

    getCoinList();
    getCuponsList();
  }, []);

  useEffect(() => {
    const getCoinsQuantity = () => {
      let coins = 0;
      coinsList.forEach((obj) => (coins = coins + Number(obj.coins)));

      let cupons = cuponsList.length;

      const coinsQuantity = coins - cupons * 60;

      if (coinsQuantity < 60) {
        const percentageToNextCupon = Math.floor((coinsQuantity * 100) / 60);

        setPercentage(percentageToNextCupon);
        setCoinsToNextCupons(60 - coinsQuantity);
      } else {
        const cuponsToSum = Math.floor(coins / 60);
        cupons = cupons + cuponsToSum;

        const percentageToNextCupon = Math.floor(
          ((coins - cupons * 60) * 100) / 60
        );
        setPercentage(percentageToNextCupon);
        setCoinsToNextCupons(60 - (coins - cupons * 60));
      }

      return coinsQuantity;
    };

    getCoinsQuantity(); // Chamada inicial
  }, [coinsList, cuponsList]);

  const formatarData = (dataString) => {
    const dia = dataString.substring(8, 10);
    const mes = dataString.substring(5, 7);
    const ano = dataString.substring(0, 4);
    const horas = dataString.substring(11, 13);
    const minutos = dataString.substring(14, 16);

    const dataFormatada = `${dia}/${mes}/${ano} às ${horas}:${minutos}`;

    return dataFormatada;
  };

  const renderizarLinhasCoins = () => {
    return coinsList.map((objeto, index) => {
      const {
        coins,
        club_class_name,
        club_class_time,
        description,
        criado_em,
        nome_do_tipo,
      } = objeto;

      const dataOriginal = new Date(criado_em);
      const dataModificada = new Date(
        dataOriginal.getTime() + 3 * 60 * 60 * 1000
      );

      // Converte a data para o formato desejado (dd/mm/aaaa hh:mm)
      const dataFormatada = dataModificada.toLocaleString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const type =
        nome_do_tipo === "CLUB"
          ? `${club_class_name} de ${formatarData(club_class_time)}`
          : description;

      return (
        <tr key={index}>
          <td style={styles.celulaStyle}>{coins}</td>
          <td style={styles.celulaStyle}>{dataFormatada}</td>
          <td
            style={{
              ...styles.celulaStyle,
              textAlign: "left",
              paddingLeft: "16px",
            }}
          >
            {type || ""}
          </td>
        </tr>
      );
    });
  };

  const renderizarLinhasCupons = () => {
    return cuponsList.map((objeto) => {
      const { id, number } = objeto;

      // Converte a data para o formato desejado (dd/mm/aaaa hh:mm)
      const numeroFormatado = String(number).padStart(4, "0");

      return (
        <tr key={id}>
          <td style={styles.celulaStyleCupons}>{numeroFormatado}</td>
        </tr>
      );
    });
  };

  const coinsQuantity = () => {
    let coins = 0;
    coinsList.forEach((obj) => (coins = coins + Number(obj.coins)));

    const cupons = cuponsList.length;

    const coinsQuantity = coins - cupons * 60;

    return coinsQuantity;
  };

  const renderCuponsOrButton = () => {
    if (cuponsList.length > 0) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <img
            src={setas_ferias_club}
            style={{ width: "8%" }}
            alt="Seta apontando para a tabela"
          />
          <div style={{ position: "relative", width: "40%" }}>
            <img
              src={tickets}
              style={{
                width: "100px",
                position: "absolute",
                zIndex: "3",
                left: "0",
                marginLeft: "-68px",
              }}
              alt="Seta apontando para a tabela"
            />
            <table style={styles.tabelaStyleCupons}>
              <thead>
                <tr>
                  <th
                    style={{
                      ...styles.celulaStyleCupons,
                      ...styles.cabecalhoStyleCupons,
                    }}
                  >
                    <span style={{ zIndex: "10" }}>CUPONS RECEBIDOS</span>
                  </th>
                </tr>
              </thead>
              <tbody>{renderizarLinhasCupons()}</tbody>
            </table>
          </div>
          <img src={wow} style={{ width: "6%" }} alt="WOW" />
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {/* <img src={setas_ferias_club} style={{width: '8%'}} alt="Seta apontando para a tabela" />
          <a href={`${window.location.origin}/live/singleclass?categoria=clubdoooo`} rel="noopener noreferrer">
            <button className="pushable" style={styles.button}>
              <span className="front" style={styles.buttonSpan}>
                RESERVAR AULA
              </span>
            </button>
          </a>
          <img src={wow} style={{width: '6%'}} alt="WOW" /> */}
          <h3>Fique atento às nossas próximas promoções.</h3>
        </div>
      );
    }
  };

  const getMessage = () => {
    const coins = coinsQuantity();

    if (coins < 60) {
      return (
        <p style={{ marginTop: "16px" }}>
          <strong>
            {coinsToNextCupons === 1 ? "Falta" : "Faltam"} {coinsToNextCupons}{" "}
            {coinsToNextCupons === 1 ? "koin" : "koins"}
          </strong>{" "}
          para ganhar seu próximo número da sorte!
        </p>
      );
    } else if (coins >= 60 && coins < 120) {
      return (
        <>
          <p style={{ marginTop: "16px" }}>
            <strong>
              {coinsToNextCupons === 1 ? "Falta" : "Faltam"} {coinsToNextCupons}{" "}
              {coinsToNextCupons === 1 ? "koin" : "koins"}
            </strong>{" "}
            para ganhar seu próximo número da sorte!
          </p>
          <h4>Você ganhou 1 número da sorte hoje.</h4>
          <p>Todos os números da sorte são atribuídos ao final do dia</p>
        </>
      );
    } else {
      const cuponQuantity = Math.floor(coins / 60);

      return (
        <>
          <p style={{ marginTop: "16px" }}>
            <strong>
              {coinsToNextCupons === 1 ? "Falta" : "Faltam"} {coinsToNextCupons}{" "}
              {coinsToNextCupons === 1 ? "koin" : "koins"}
            </strong>{" "}
            para ganhar seu próximo número da sorte!
          </p>
          <h4 style={{ marginTop: "16px" }}>
            Você ganhou {cuponQuantity} números da sorte hoje.
          </h4>
          <p>Todos os números da sorte são atribuídos ao final do dia</p>
        </>
      );
    }
  };

  return (
    <>
      <div style={styles.mainDiv}>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <img
            src={evento_ferias}
            style={{ width: "32vw" }}
            alt="Evento férias no club"
          />
          <img src={premios} style={{ width: "20vw" }} alt="Prémios" />
        </div>
        {cuponsList.length > 0
            && 
            <h1 style={{ marginTop: "80px", marginBottom: "50px", color: "#0d0a66", textAlign: "center"}} >
                A promoção Férias no Club foi um sucesso.
              <br />
                Adoramos a participação de todos vocês em nossas aulas.
              <br />
                Foi uma excelente oportunidade de aprender muito, com muita diversão!
            </h1>
        }
        <h1 style={styles.title}>
          {cuponsList.length > 0
            ? "CONFIRA SEUS NÚMEROS DA SORTE:"
            : "O PERÍODO DA PROMOÇÃO ACABOU."}
        </h1>
        {loadingCupons ? <p></p> : renderCuponsOrButton()}
        {/* {
        percentage < 100 &&
          <div style={styles.progressBar}>
            <img src={koin_progress_bar} alt="moeda" style={{position: 'absolute', width: '60px', zIndex: '3', marginTop: '-15px', marginLeft: '-40px'}} />
            <span style={styles.progressBarSpan}></span>
          </div>

      } */}
        {/* {!loadingCupons && 
        getMessage()
      } */}
        <h1 style={{ ...styles.title, marginTop: "32px" }}>
          SEU EXTRATO DE KOINS:
        </h1>
        <div
          className="coins-history-club"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {loadingCoins ? (
            <p></p>
          ) : (
            <div
              style={{ width: "60%", position: "relative", marginTop: "60px" }}
            >
              <img
                src={koin_star}
                alt="coin"
                style={{
                  width: "60px",
                  marginLeft: "-36px",
                  marginBottom: "-54px",
                  position: "absolute",
                }}
              />
              <img
                style={{
                  position: "absolute",
                  width: "80px",
                  marginLeft: "90%",
                  marginTop: "-60px",
                  zIndex: "6",
                }}
                src={congratz}
                alt="congratz"
              />
              <table style={styles.tabelaStyle}>
                <thead>
                  <tr>
                    <th
                      style={{
                        ...styles.celulaStyle,
                        ...styles.cabecalhoStyle,
                      }}
                    >
                      KOINS
                    </th>
                    <th
                      style={{
                        ...styles.celulaStyle,
                        ...styles.cabecalhoStyle,
                      }}
                    >
                      DATA DE RECEBIMENTO
                    </th>
                    <th
                      style={{
                        ...styles.celulaStyle,
                        ...styles.cabecalhoStyle,
                        position: "relative",
                      }}
                    >
                      COMO EU GANHEI?{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>{renderizarLinhasCoins()}</tbody>
              </table>
            </div>
          )}
          <a
            href="https://curso.kidsa.com/regulamento-ferias"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="pushable" style={styles.button}>
              <span className="front" style={styles.buttonSpan}>
                VEJA O REGULAMENTO
              </span>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};
