import React, { useEffect } from "react";
import { useParams, useLocation, Redirect, useHistory } from "react-router-dom";
import {
  Grid,
  makeStyles,
  CircularProgress,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import PandaPlayer from '../components/PandaPlayer';
import { load } from "../localStorage";
import { Auth } from "../client/api";
import { API_KEY } from "../Constants";
import Loading from "../components/Loading";
import useFetchDataDirectVideo from "../hook/useFetchDataDirectVideo";
import SimpleDialog from "../components/SimpleDialog";
import DoneIcon from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";
import screenfull from "screenfull";
import FullscreenButton from "../components/FullscreenButton";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { isMobile } from "../utils";

const mobile = isMobile();
// ReactPlayer.addCustomPlayer(PandaPlayer);
const useStyles = () =>
  makeStyles((theme) => ({
    container: {
      paddingTop: "180px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "85px",
      },
      paddingBottom: "80px",
      display: "flex",
      alignItems: "center",
      justifyContent: 'center',
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
    },
    containerLandscape: {
      display: "flex",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
    },
    playerLandscape: {
      height: "100vh !important",
      width: "100vw !important",
    },
    player: {
      height: "100% !important",
      width: "100% !important",
      borderWidth: "0",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    playerChange: {
      height: "100% !important",
      width: "85% !important",
      borderWidth: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },  
    presenceValidateContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "20px",
      paddingTop: "10px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      fontSize: "60px",
    },
    inner: {
      margin: "0 auto",
      width: "100%",
      backgroundColor: "white",
      "&> .close": {
        display: "none",
      },
    },
    innerFull: {
      position: "relative",
      backgroundColor: "white",
      "&> .close": {
        position: "absolute",
        top: 10,
        right: 10,
      },
    },
    fullScreenButton: {
      position: "absolute",
      right: "25px",
      top: "188px",
      [theme.breakpoints.down("sm")]: {
        top: "200px",
        right: "5px",
      },
      height: "50px",
      width: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#3f51b5",
      opacity: "0.6",
      zIndex: 999,
      color: "#fff",
      cursor: "pointer",
      "&:hover": {
        opacity: "1",
      },
    },
    
    backButton: {
      position: "absolute",
      left: "5px",
      top: "188px",
      [theme.breakpoints.down("sm")]: {
        top: "200px",
      },
      height: "50px",
      width: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#3f51b5",
      opacity: "0.6",
      zIndex: 999,
      color: "#fff",
      cursor: "pointer",
      "&:hover": {
        opacity: "1",
      },
    },
  }));

//Reserve ID
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RecordedVideoPlayer = ({}) => {
  // Para desativar o Panda Player basta mudar a variável usePandaPlayer para false
  const usePandaPlayer = true;
  const [pandaPlayerType, setPandaPlayerType] = React.useState('video_hls');
  const [changePlayer, setChangePlayer] = React.useState(true);
  const theme = useTheme();
  const styles = useStyles(theme)();
  let history = useHistory();
  let { class_date } = useParams();
  const query = useQuery();
  const vimeo_id = query.get("vimeo_id");
  const panda_id = query.get("panda_id");
  const params = new URLSearchParams(history.location.search);
  const { profile } = load("user");
  const token = profile.user.auth_token;
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];
  const [validating, setValidating] = React.useState(false);
  const [validate, setValidate] = React.useState("NOT_STARTED");
  const [error, setError] = React.useState(null);
  const [fullScreen, setFullScreen] = React.useState(false);
  const matches = useMediaQuery("(orientation: landscape)");
  let state = useFetchDataDirectVideo(
    "/classplayer/" + vimeo_id,
    null,
    token,
    vimeo_id,
    panda_id
  );
  
  const activatePandaPlayer = () => {
    ReactPlayer.addCustomPlayer(PandaPlayer);
    setPandaPlayerType('video_player')
  }

  useEffect(() => {
    if(usePandaPlayer) {
      activatePandaPlayer();
    }
    
  }, []);

  React.useEffect(() => {
    if (mobile && state.loading === false) {
      var elem = document.getElementById("inner");

      if (screenfull.isEnabled) {
        screenfull.request(elem);
        setFullScreen(true);
      }
    }

  }, [state.loading]);

  function handleFullScreen() {
    const player = document.querySelector("#player-video");
    if (document.fullscreenElement) {
      // Elemento já está em fullscreen, sair do fullscreen
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      // Elemento não está em fullscreen, entrar no fullscreen
      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.webkitRequestFullscreen) {
        // Safari
        player.webkitRequestFullscreen();
      } else if (player.msRequestFullscreen) {
        // IE11
        player.msRequestFullscreen();
      }
      setFullScreen(true);
    }
  }

  const handleValidatePrensence = () => {
    setValidate("VALIDATING");
    setValidating(true);
    const response = Auth(token)
      .put(
        `livecourseclassdatereserve/validate/${class_date}?apikey=${API_KEY}`,
        {
          profile_id: childrenLive.id,
        }
      )
      .then((response) => {
        setValidate("VALIDATED");
        setError(null);

        setTimeout(() => {
          history.push("/live/turmas");
        }, 2000);
      })
      .catch((error) => {
        setValidate("NOT_VALIDATED");
        console.log(error);
        setError("Erro ao validar presença");
      });
  };

  if (state.loading) return <Loading />;

  return (
    <div id="inner">
      <SimpleDialog open={validating}>
        <div className={styles.presenceValidateContainer}>
          {validate === "VALIDATING" ? <CircularProgress /> : ""}
          {validate === "VALIDATED" ? (
            <DoneIcon style={{ color: "#00bcd4" }} className={styles.icon} />
          ) : (
            ""
          )}
          {validate === "NOT_VALIDATED" ? (
            <Clear style={{ color: "#FF3C3D" }} className={styles.icon} />
          ) : (
            ""
          )}
          <div>
            {validate === "VALIDATED" ? (
              <>
                {" "}
                <Typography variant="h2" color="primary">
                  Presença validada com sucesso <br />
                  Em instantes você será redirecionado para a página de turmas.
                </Typography>
              </>
            ) : (
              ""
            )}

            {validate === "VALIDATING" ? (
              <>
                {" "}
                <Typography variant="h2" color="primary">
                  Por favor aguarde estamos
                  <br />
                  Validando a sua presença
                </Typography>
              </>
            ) : (
              ""
            )}

            {validate === "NOT_VALIDATED" ? (
              <>
                {" "}
                <Typography variant="h2" color="primary">
                  Houve um erro ao validar sua presença <br />
                  Por favor tente novamente.
                </Typography>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {validate === "NOT_VALIDATED" && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleValidatePrensence}
            >
              Tentar Novamente
            </Button>
          )}
        </div>
      </SimpleDialog>
      <Grid item xs={12} id="player-video">
        <div
          className={
            matches && fullScreen ? styles.containerLandscape : styles.container
          }
        >
          <div className={styles.backButton} onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </div>
          {typeof state.files.link === "undefined" && pandaPlayerType === 'video_hls' ? (
            <div
              className={styles.fullScreenButton}
              onClick={() => handleFullScreen()}
            >
              {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </div>
          ) : (
            <></>
          )}

          {
            !state.loading && 
            <ReactPlayer
            playing={true}
            controls
            changePlayer={setChangePlayer}
            pandaControls={`play-large,rewind,play,${profile.masterAccess ? 'progress,' : ''}current-time,volume,captions,settings,fullscreen,cast,airplay`}
            playsinline
            url={
              typeof state.files.link === "undefined"
                ? state.files[pandaPlayerType]
                : state.files.link
            }
            className={
              usePandaPlayer && changePlayer ? styles.playerChange : styles.player
            }
            muted={false}
            config={{
              file: {
                attributes: {
                  disablePictureInPicture: true,
                  autopictureinpicture: "false",
                },
              },
            }}
            onEnded={() => {
              setValidating(true);

              handleValidatePrensence();
            }}
          />
          }
        </div>
      </Grid>
    </div>
  );
};

const forceFullscreen = (
  Component,
  force = false,
  colorButtonFullscreen = "black"
) => {
  const Force = (props) => {
    const styles = useStyles();
    const [fullscreen, setFullscreen] = React.useState(false);

    const forceFullscreen = () => {
      var elem = document.getElementById("inner");
      if (screenfull.isEnabled && !screenfull.isFullscreen) {
        screenfull.request(elem).catch((error) => console.error(error));
      }
    };

    React.useEffect(() => {
      // if (screenfull.isEnabled) {
      //   if (force) {
      forceFullscreen();
      // }
      // }

      // return () => {
      //   if (screenfull.isEnabled) screenfull.off("change");
      // };
    }, []);
    console.log(screenfull);
    return (
      <div id="inner" className={!fullscreen ? styles.inner : styles.innerFull}>
        <Component {...props} />
        {screenfull.isEnabled && (
          <FullscreenButton
            color={colorButtonFullscreen}
            onClick={() => {
              screenfull.exit();
            }}
            fullscreen={fullscreen}
          />
        )}
      </div>
    );
  };
  return Force;
};

export default RecordedVideoPlayer;
