import React from 'react';
import {
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {    
    textAlign: 'left',
    letterSpacing: 2,
    margin: '0 0 5px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      textAlign: 'center'
    }
  }
}));

export default props => {
  const styles = useStyles();
  
  return (
    <Typography variant='h2' className={styles.title}>{props.children}</Typography>
  )
}