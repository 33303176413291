import React from "react";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import SmallDevices from "./SmallDevices";
import BigDevices from "./BigDevices";
import SelectChild from "../../containers/SelectChild";
import LogoutButton from "../../containers/LogoutButton";
import NoticeWallBell from "../NoticeWall/NoticewallBell";
import { load } from "../../localStorage";

const useStyles = makeStyles({
  logoutBigDevices: {
    marginLeft: 20,
  },
  logoutSmallDevices: {
    color: "black",
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    },
  },
  noticeWallBell: {
    marginLeft: "100px",
  },
});

export default ({ options, isLive }) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { profile } = load("user");
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (matches) {
    return (
      <>
        {isLive}
        {!matches ? <SelectChild /> : ""}
        <SmallDevices
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          options={options}
        >
          <LogoutButton
            disableRipple
            path="/"
            label="Sair"
            className={styles.logoutSmallDevices}
          />
        </SmallDevices>
      </>
    );
  }

  return (
    <BigDevices options={options} styles={styles}>
      {isLive && <SelectChild />}
      {typeof childrenLive === "undefined" || childrenLive == null ? (
        <></>
      ) : (
        <NoticeWallBell className={styles.noticeWall} isLive={isLive} />
      )}

      <LogoutButton path="/" label="Sair" className={styles.logoutBigDevices} />
    </BigDevices>
  );
};
