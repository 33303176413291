import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    fontWeight: 500,
  },
}));

function KoinsShopButton() {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{ marginRight: "10px" }}>
      <Button
        variant="text"
        color="primary"
        className={classes.button}
        endIcon={<ShoppingCart />}
        onClick={() => history.push("/koins")}
      >
        Troque aqui seus Koins
      </Button>
    </div>
  );
}

export default KoinsShopButton;
