import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Button} from '@material-ui/core';


export default ({onClick}) => {
    return (
    <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={onClick}
    >
        Voltar
    </Button>
    )
}