import React from 'react';
import {
  Grid
} from '@material-ui/core';
import Paper from '../styles/paper';
import FormRecoverPassword from '../containers/FormRecoverPassword';
import Title from '../ui/title';

export default props => {
  return (
    <React.Fragment>
      <Grid item xs={11}>
        <Title>RECUPERAR ACESSO</Title>
      </Grid>
      <Grid item xs={11} sm={6} md={4}>
        <Paper>
          <FormRecoverPassword />
        </Paper>
      </Grid>
    </React.Fragment>
  )
}