import React, { useEffect } from "react";
import {
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useParams, useLocation, Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPlayer from "react-player";
import PandaPlayer from '../components/PandaPlayer';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Loading from "../components/Loading";
import { load } from "../localStorage";
import useFetchDataDirectVideo from "../hook/useFetchDataDirectVideo";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import forceFullscreen from "../hoc/forceFullscreen";
import { isMobile } from "../utils";
import { Auth } from "../client/api";
import { API_KEY } from "../Constants";

const mobile = isMobile();

const useStyles = () =>
  makeStyles((theme) => ({
    container: {
      paddingTop: "180px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "85px",
      },
      paddingBottom: "80px",
      display: "flex",
      alignItems: "center",
      justifyContent: 'center',
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
    },
    containerLandscape: {
      display: "flex",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
    },
    presenceValidateContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "20px",
      paddingTop: "10px",
    },
    player: {
      height: "100% !important",
      width: "100% !important",
      borderWidth: "0",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },   
    playerChange: {
      height: "100% !important",
      width: "85% !important",
      borderWidth: "0",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      fontSize: "60px",
    },
    fullScreenButton: {
      position: "absolute",
      right: "25px",
      top: "188px",
      [theme.breakpoints.down("sm")]: {
        top: "200px",
        right: "5px",
      },
      height: "50px",
      width: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#3f51b5",
      opacity: "0.6",
      zIndex: 999,
      color: "#fff",
      cursor: "pointer",
      "&:hover": {
        opacity: "1",
      },
    },
    backButton: {
      position: "absolute",
      left: "5px",
      top: "188px",
      [theme.breakpoints.down("sm")]: {
        top: "200px",
      },
      height: "50px",
      width: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#3f51b5",
      opacity: "0.6",
      zIndex: 999,
      color: "#fff",
      cursor: "pointer",
      "&:hover": {
        opacity: "1",
      },
    },
  }));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Video = () => {
  // Para desativar o Panda Player basta mudar a variável usePandaPlayer para false
  const usePandaPlayer = true;
  const [pandaPlayerType, setPandaPlayerType] = React.useState('video_hls');
  const [changePlayer, setChangePlayer] = React.useState(true);
  const [fullScreen, setFullScreen] = React.useState(false);
  const dispatch = useDispatch();
  let history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const vimeoId = params.get("vimeo_id");
  var pandaId = params.get("panda_id");
  if (pandaId == "null" || pandaId == "undefined") {
    pandaId = null;
  }
  const homework_id = params.get("homework_id");
  const matches = useMediaQuery("(orientation: landscape)");
  const homeworkNumber = params.get("homework");
  const single_class = params.get("singleclass");

  const url_base = single_class === "true" ? "singleclasshomework" : "livecourseclasshomework";
  const theme = useTheme();
  const styles = useStyles(theme)();
  let { id } = useParams();
  let query = useQuery();

  const token = load("user").profile.user.auth_token;
  const { profile } = load("user");
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];

  const [currentId, setCurrentId] = React.useState(Number(id));
  const [endPoint, setEndPoint] = React.useState("directvideo/" + currentId);

  let state = useFetchDataDirectVideo(endPoint, null, token, vimeoId, pandaId);

  const activatePandaPlayer = () => {
    ReactPlayer.addCustomPlayer(PandaPlayer);
    setPandaPlayerType('video_player')
  }

  function handleFullScreen() {
    const player = document.querySelector("#player-video");
    if (document.fullscreenElement) {
      // Elemento já está em fullscreen, sair do fullscreen
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      // Elemento não está em fullscreen, entrar no fullscreen
      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.webkitRequestFullscreen) {
        // Safari
        player.webkitRequestFullscreen();
      } else if (player.msRequestFullscreen) {
        // IE11
        player.msRequestFullscreen();
      }
      setFullScreen(true);
    }
  }

  useEffect(() => {
    if(usePandaPlayer) {
      activatePandaPlayer();
    }
    
  }, []);

  if (state.loading !== true) {
    console.log(state.files);
  }

  if (state.loading) return <Loading />;
  if (state.files === null || state.files === undefined)
    return <p>Erro ao tentar carregar o conteúdo</p>;
  if (state.error) return <Redirect push to="/videos" />;

  

  return (
    <Grid item
    xs={12}
    className={matches && fullScreen ? styles.containerLandscape : styles.container}
    id="player-video">
      <div className={styles.backButton} onClick={() => history.goBack()}>
        <ArrowBackIcon />
      </div>
      {typeof state.files.link === "undefined" && pandaPlayerType === 'video_hls' ? (
          <div
            className={styles.fullScreenButton}
            onClick={() => handleFullScreen()}
          >
            {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </div>
        ) : (
          <></>
        )}
      <ReactPlayer
        playing
        playsinline
        controls
        changePlayer={setChangePlayer}
        pandaControls={`play-large,rewind,play,${profile.masterAccess ? 'progress,' : ''}current-time,volume,captions,settings,fullscreen,cast,airplay`}
        url={
          typeof state.files.link === "undefined"
            ? state.files[pandaPlayerType]
            : state.files.link
        }
        className={
          usePandaPlayer && changePlayer ? styles.playerChange : styles.player
        }
        config={{
          file: {
            attributes: {
              disablePictureInPicture: true,
              autopictureinpicture: "false",
            },
          },
        }}
        onEnded={() => {
          const categoryId = query.get("category_id");

          if (categoryId == 10) {
            Auth(token)
              .get(
                `${url_base}/validate?apikey=${API_KEY}&homework=${homeworkNumber}&homework_id=${homework_id}&replacementHomework=1&classDateId=${homework_id}&profile_id=${childrenLive.id}`
              )
              .then(() => {
                dispatch({
                  type: "REFRESH_COIN",
                });
              });
          } else {
            Auth(token)
              .get(
                `${url_base}/validate?apikey=${API_KEY}&homework=${homeworkNumber}&homework_id=${homework_id}&profile_id=${childrenLive.id}`
              )
              .then(() => {
                dispatch({
                  type: "REFRESH_COIN",
                });
              });
          }

          setCurrentId((currentId) => currentId + 1);
          setEndPoint(
            "videocategory/" + categoryId + "/item/" + currentId + "/next"
          );

          if (categoryId == 10) {
            setTimeout(function () {
              history.goBack();
            }, 5000);
          } else {
            history.goBack();
          }
        }}
      />
    </Grid>
  );
};

export default Video
