import React from "react";

import BasicInformation from "./BasicInformation";
import AccountInformation from "./AccountInformation";
import AddressInformation from "./AddressInformation";
// import CourseInformation from "./CourseInformation"; Ocultado para correção das falhas encontradas.
import NoticeWall from "./NoticeWall";

export default ({ menuValue }) => {
  if (menuValue === "basic_information") return <BasicInformation />;
  if (menuValue === "account_information") return <AccountInformation />;
  if (menuValue === "address_information") return <AddressInformation />;
  // if (menuValue === "course_information") return <CourseInformation />; Ocultado para correção das falhas encontradas.
  if (menuValue === "notice_wall") return <NoticeWall />;
  else return <BasicInformation />;
};
