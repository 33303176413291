import React from "react";

import MemoryGameHomework from "./MemoryGameHomework";
import DragAndDropHomework from "./DragAndDropHomework";
import ListenAndFindHomework from "./ListenAndFindHomework";
import ListenAndTapHomework from "./ListenAndTapHomework";

export default ({ games }) => {
  return (
    <>
      {Object.keys(games).map((value, index) => {
        if (value === "MEMORY_GAME")
          return <MemoryGameHomework key={'gt' + index} memoryGameHomework={games[value]} />;
        else if (value === "DRAG_DROP_GAME")
          return <DragAndDropHomework key={'gt' + index} dragAndDropHomework={games[value]} />;
        else if (value === "LISTEN_AND_FIND_GAME")
          return (
            <ListenAndFindHomework key={'gt' + index} listenAndFindGameHomework={games[value]} />
          );
        else if (value === "LISTEN_AND_TAP_GAME")
          return (
            <ListenAndTapHomework key={'gt' + index} listenAndTapGameHomework={games[value]} />
          );
        else
            return(<></>);
      })}
    </>
  );
};
