export const load = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) return null;
    return JSON.parse(serializedState);
  } catch (err) {
    console.log('Error occurred while persisting state to Local Storage');
    return undefined;
  }
};

export const save = (data, key) => {
  try {
    const serializedState = JSON.stringify(data);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.log('Error occurred while persisting state to Local Storage');
  }
};
