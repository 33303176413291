import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import * as Yup from "yup";
// import MenuItem from '@material-ui/core/MenuItem';
import { withFormik } from "formik";
import moment from "moment";
import MaskedInput from "react-text-mask";
import { newChild } from "../redux/actions";
import { getError, getProcessing } from "../redux/actions/utils";
import Message from "../ui/message";
import CustomTextField from "../styles/textfield";
import SubmitSkinButton from "../styles/submit-skin-button";
import { useHistory } from "react-router-dom";

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        "/",
        /[0-9]/,
        /[0-9]/,
        "/",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholderChar={"\u2000"}
      showMask
      guide={false}
    />
  );
};

const NewChild = (props) => {
  const history = useHistory();
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    initialValues,
    setFieldValue,
  } = props;

  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    // loadData() ;

    if (!props.processing && mounted) {
      resetForm(initialValues);
      goToLive();
    }
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.processing]);

  const goToLive = () => {
    history.push("/live");
  };

  const changeGender = (event) => {
    setFieldValue("gender", Number(event.target.value));
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <CustomTextField
            id="full_name"
            name="full_name"
            value={values.full_name}
            label="Nome Completo"
            variant="outlined"
            required
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.full_name && touched.full_name}
            helperText={
              errors.full_name && touched.full_name && errors.full_name
            }
          />
        </Grid>
        {/* <Grid item xs={12}>
          <CustomTextField
            id='last_name'
            name='last_name'
            type='last_name'
            value={values.last_name}
            label='Sobrenome'
            variant='outlined'
            required
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.last_name && touched.last_name}
            helperText={
              errors.last_name && touched.last_name && errors.last_name
            }
          />
        </Grid> */}
        <Grid item xs={12}>
          <CustomTextField
            id="birthday"
            name="birthday"
            type="birthday"
            label="Data do nascimento"
            variant="outlined"
            value={values.birthday}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            required
            fullWidth
            error={errors.birthday && touched.birthday}
            helperText={errors.birthday && touched.birthday && errors.birthday}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Gênero</FormLabel>
            <RadioGroup
              aria-label="gender"
              id="gender"
              name="gender"
              value={values.gender}
              onChange={changeGender}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Feminino"
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Masculino"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <SubmitSkinButton type="submit" fullWidth disabled={props.processing}>
            {!props.processing ? (
              "ADICIONAR NOVA CRIANÇA"
            ) : (
              <CircularProgress size={20} color="secondary" />
            )}
          </SubmitSkinButton>
        </Grid>
        {props.error.length > 0 && (
          <Grid item xs={12}>
            <Message error={true} text={props.error} />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

const validationSchema = Yup.object({
  full_name: Yup.string().test(
    "valid_full_name",
    "Por digite o nome inteiro",
    (val) => {
      if (typeof val !== "undefined") {
        return val.split(" ").length > 1;
      } else {
        return false;
      }
    }
  ),

  // last_name: Yup.string("Digite o sobrenome").required(
  //   "Sobrenome é obrigatório"
  // ),
  birthday: Yup.mixed()
    .test("valid-date", "Digite uma data de aniversário válida", (val) =>
      moment(val, "DD/MM/YYYY").isValid()
    )
    .test("valid-length", "Data de aniversário inválida", (val) => {
      return val ? val.replace(/[/_]/g, "").length === 8 : false;
    }),
});

const FormNewChild = withFormik({
  mapPropsToValues: () => ({
    full_name: "",
    // last_name: "",
    birthday: "",
    gender: 0,
  }),
  validationSchema: validationSchema,
  handleSubmit: (values, { props }) => {
    var full_name = values.full_name;
    var last_name = full_name.split(" ").pop();
    var first_name = full_name.split(" ").slice(0, -1).join(" ");

    props.newChild({
      avatar_id: 1,
      first_name: first_name,
      last_name: last_name,
      birthday: values.birthday,
      gender: values.gender,
    });
  },
  displayName: "NewChild",
})(NewChild);

const mapStateToProps = (state) => ({
  error: getError(state),
  processing: getProcessing(state),
});

export default connect(mapStateToProps, { newChild })(FormNewChild);
