import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme => ({
  root: {
    height: 50,
    backgroundColor: '#DC2B2B',
    border: '3px solid white',
    borderRadius: 25,
    boxShadow: '0 10px 20px 0 rgba(0,0,0,.25), inset 0 0 0 2px #424242',
    '&:hover': {
      backgroundColor: '#DC2B2B',
      opacity: 0.8,
    }

  },
  label: {

    color: '#fff',
  },
  startIcon: {
    width: 24,
    height: 24,
    [theme.breakpoints.down('sm')]: {
      width: 12,
      height: 12
    }
  }
}))(Button)