import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import Download from "../assets/background-download.png";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    fontWeight: 500,
  },
}));

function downloadImage() {
  var link = document.createElement("a");
  link.href = Download;
  link.download = "background-download.png";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default () => {
  const classes = useStyles();
  return (
    <div style={{ marginRight: "10px" }}>
      <Button
        variant="text"
        color="primary"
        className={classes.button}
        endIcon={<CloudDownload />}
        onClick={() => downloadImage()}
      >
        Background do mês
      </Button>
    </div>
  );
};
