import React, { useState } from "react";
import { useParams, useLocation, Redirect, useHistory } from "react-router-dom";
import useFetch from "../hook/useFetch";
import { load } from "../localStorage";
import Loading from "../components/Loading";
import SimpleDialog from "../components/SimpleDialog";
import { Auth } from "../client/api";
import { API_KEY } from "../Constants";
import { useDispatch } from "react-redux";

import {
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

const useStyles = () =>
  makeStyles((theme) => ({
    container: {
      paddingTop: "180px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "85px",
      },
      paddingBottom: "80px",
      display: "flex",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
    },
    presenceValidateContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "20px",
      paddingTop: "10px",
    },
    player: {
      height: "100% !important",
      width: "100% !important",
    },
    icon: {
      fontSize: "60px",
    },
    backButton: {
      position: "absolute",
      right: "20px",
      top: "calc(100vh /2 )",
      [theme.breakpoints.down("sm")]: {
        top: "calc(100vh /2 )",
      },
      height: "150px",
      width: "250px",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#3f51b5",
      opacity: "0.6",
      color: "#fff",
      cursor: "pointer",
      "&:hover": {
        opacity: "1",
      },
    },
  }));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const { profile } = load("user");
  const token = profile.user.auth_token;
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];
  let { id } = useParams();
  const state = useFetch(`scratch/${id}`, null, token);
  const theme = useTheme();
  const styles = useStyles(theme)();
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [validate, setValidate] = React.useState("NOT_STARTED");
  const homework_id = params.get("homework_id");
  const homeworkNumber = params.get("homework");
  const [passwordError, setPasswordError] = useState(null);
  const [inputPassword, setInputPassword] = useState("");

  if (state.loading) return <Loading />;
  if (state.data === null) return <p>Erro ao tentar carregar o conteúdo</p>;

  const handleValidate = () => {
    if (inputPassword !== state.data.password) {
      setPasswordError("Senha Incorreta");
      return;
    }
    setPasswordError(null);
    setValidate("VALIDATING");
    const response = Auth(token)
      .get(
        `/livecourseclasshomework/validate?apikey=${API_KEY}&homework=${homeworkNumber}&homework_id=${homework_id}&profile_id=${childrenLive.id}`,
        {}
      )
      .then((response) => {
        setValidate("VALIDATED");
        dispatch({
          type: "REFRESH_COIN",
        });

        setTimeout(() => {
          history.push("/live/homework/detail/" + homework_id);
        }, 2000);
      })
      .catch((error) => {
        setValidate("NOT_VALIDATED");
      });
  };

  return (
    <>
      <SimpleDialog open={passwordDialog}>
        <Typography variant="h2" color="primary">
          Para validar suas moedas <br />
          Por favor digite a senha recebida ao final da tarefa e clique em
          validar.
        </Typography>
        <TextField
          label="Senha"
          variant="outlined"
          fullWidth
          error={passwordError !== null}
          helperText={passwordError}
          value={inputPassword}
          onChange={(e) => setInputPassword(e.target.value)}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "15px", height: "45px" }}
          disabled={validate !== "NOT_STARTED"}
          onClick={handleValidate}
        >
          {validate === "VALIDATING" && (
            <CircularProgress color="info" size={20} />
          )}
          {validate === "NOT_STARTED" && <>Validar</>}
          {validate === "VALIDATED" && <>Moeda Validada com sucesso</>}
        </Button>
      </SimpleDialog>
      <Grid item xs={12} className={styles.container}>
        <div
          className={styles.backButton}
          onClick={() => setPasswordDialog(true)}
        >
          <h2>
            Clique Aqui
            <br />
            Para validar suas coins
          </h2>
        </div>
        <iframe
          src={state.data.embed_link + "/embed"}
          allowtransparency="true"
          frameborder="0"
          scrolling="no"
          allowfullscreen
          className={styles.player}
        ></iframe>
      </Grid>
    </>
  );
};
