import { Paper } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    borderRadius: 25,
    boxShadow: '0 5px 25px 0 rgba(0,0,0,.2)',
    padding: 40,
    backgroundColor: '#f8f8f8'
  },
}))(Paper)