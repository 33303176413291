import React from 'react';
import { Link } from 'react-router-dom';

export default ({ styles, logo, to, children }) => {
  return (
    <>
      <h1 className={styles.title}>
        <Link to={to}>
          <img src={logo} alt={`kidsa ${to}`} className={styles.logo} />
        </Link>
      </h1>
      {children}
    </>
  );
};
