import React from "react";
import { Grid } from "@material-ui/core";
import GridAutoFlow from "../GridAutoFlow";
import BoxContent from "../BoxContent";
import Subtitle from "../../ui/subtitle";

export default ({ memoryGameHomework }) => {
  return (
    <Grid item xs={11}>
      {memoryGameHomework.length !== 0 && <Subtitle>Memory Games</Subtitle>}
      <GridAutoFlow>
        {memoryGameHomework.map((value, index) => {
          if (value.length === 0) return null;
          const { game_category_id, item_id, homework_id, id  } = value;
          const { image_thumb } = value.object_related;
          const { name }  = value.object_related.object_related;
          const image = image_thumb[0].secure_url_image;
        
          return(
            <BoxContent 
              {...value}
              key={index}
              image={image}
              name={name}
              path='game'
              id={item_id}
              category_id={game_category_id}
              homework_id={homework_id}
              homeworkNumber = {id}
              watched = {value.completed}
            />
          );
         })}
      </GridAutoFlow>
    </Grid>
  );
};
