import React from 'react';
import { Button } from '@material-ui/core';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { userSignOut } from '../redux/actions';

const LogoutButton = ({
  className,
  path,
  label,
  userSignOut,
  disableRipple,
}) => {
  let history = useHistory();
  return (
    <Button
      disableRipple={disableRipple}
      startIcon={<ExitToAppOutlinedIcon />}
      className={className}
      onClick={() => {
        userSignOut();
        history.push(path);
      }}
    >
      {label}
    </Button>
  );
};

export default connect(null, { userSignOut })(LogoutButton);
