import React from "react";

import { Grid, useMediaQuery } from "@material-ui/core";
import HeaderPage from "../../components/HeaderPage";
import useFetch from "../../hook/useFetch";
import { load } from "../../localStorage";
import GridAutoFlow from "../../components/GridAutoFlow";
import BoxContent from "../../components/BoxContent";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";

export default () => {
  let history = useHistory();
  const { profile } = load("user");
  const token = profile.user.auth_token;
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];
  const mobile = useMediaQuery("(max-width:960px)");
  const state = useFetch(
    `livecourse/homeworks?profile_id=${childrenLive.id}&finished=false`,
    null,
    token
  );

  const stateFinished = useFetch(
    `livecourse/homeworks?profile_id=${childrenLive.id}&finished=true`,
    null,
    token
  );
  const goToHomework = (id) => {
    history.push(`/live/homework/detail/${id}`); // rota do homework detalhado
  };

  if (state.loading || stateFinished.loading) return <Loading />;
  if (state.data === null || stateFinished.data === null)
    return <p>Erro ao tentar carregar o conteúdo</p>;
  if (state.data.length < 1 && stateFinished.data.length < 1)
    return (
      <>
        <HeaderPage
          title="Você ainda não possui nenhum Homework."
          description="Por favor, volte novamente mais tarde."
        />
      </>
    );

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={
        mobile
          ? { paddingTop: 280, paddingBottom: 100 }
          : { paddingTop: 220, paddingBottom: 100 }
      }
    >
      <HeaderPage title="Atividades" description="" />
      <Grid item xs={11} style={{ display: "flex", justifyContent: "center" }}>
        <GridAutoFlow>
          {state.data.map((item, index) => {
            return (
              <BoxContent
                {...item}
                image={item.image_thumb}
                key={index}
                homework={true}
                goToHomework={goToHomework}
              />
            );
          })}
        </GridAutoFlow>
      </Grid>
      <HeaderPage title="Atividades Finalizadas" description="" />
      <Grid item xs={11} style={{ display: "flex", justifyContent: "center" }}>
        <GridAutoFlow>
          {stateFinished.data.map((item, index) => {
            return (
              <BoxContent
                {...item}
                image={item.image_thumb}
                key={index}
                homework={true}
                goToHomework={goToHomework}
              />
            );
          })}
        </GridAutoFlow>
      </Grid>
    </Grid>
  );
};
