function cropText(text, max) {
  var info = cropTextWithoutFormat(text, max)
  const newText = info.text + (info.more ? '...' : '');
  return newText
}

function cropTextWithoutFormat(text, max) {
  const newText = text.slice(0, max);
  return {
    more: text.length > max,
    text: newText
  }
}

function getURL(req) {
  let url
  if (req) {
    // Server side rendering
    url = req.protocol + '://' + req.get('host') + req.originalUrl
  } else {
    // Client side rendering
    url = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + window.location.pathname
  }
  return url;
}

function isMobile() {
  return /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(navigator.userAgent) && !window.MSStream
}

function isLandscape() {
  return (window.orientation === 90 || window.orientation === -90);
}

export {
  cropText,
  cropTextWithoutFormat,
  getURL,
  isMobile,
  isLandscape
}