import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import screenfull from "screenfull";
import FullscreenButton from "../components/FullscreenButton";
import { Auth } from "../client/api";
import { load } from "../localStorage";
import { API_KEY, BASE_URL } from "../Constants";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  inner: {
    margin: "0 auto",
    width: "100%",
    backgroundColor: "white",
    "&> .close": {
      display: "none",
    },
  },
  innerFull: {
    position: "relative",
    backgroundColor: "white",
    "&> .close": {
      position: "absolute",
      top: 10,
      right: 10,
    },
  },
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default (
  Component,
  path,
  mobile = false,
  force = false,
  colorButtonFullscreen = "black"
) => {
  const Force = (props) => {
    const styles = useStyles();
    let history = useHistory();
    let query = useQuery();
    const singleclass = query.get("singleclass");
    const dispatch = useDispatch();
    const params = new URLSearchParams(history.location.search);
    const homework_id = params.get("homework_id");
    const homeworkNumber = params.get("homework");

    var homeworkPath = "/live/homework/detail/" + homework_id;

    if (singleclass === "true") {
      homeworkPath += "?singleclass=true";
    }

    const [fullscreen, setFullscreen] = React.useState(false);

    const { profile } = load("user");
    const token = profile.user.auth_token;

    const back = () => {
      history.goBack();
    };

    const homework = async () => {
      if (homework_id === null) {
        back("/live/turmas");
      } else {
        if (localStorage.getItem("gameEnd") === "true") {
          dispatch({
            type: "REFRESH_COIN",
          });
          localStorage.removeItem("gameEnd");
          localStorage.removeItem("homeworkInfo");

          if (query.get("category_id") == 10) {
            back();
          } else {
            history.push(homeworkPath);
          }
        } else if (localStorage.getItem("gameEnd") === "loading") {
          alert("Por favor aguarde a validação dos Coins");
        } else {
          localStorage.removeItem("gameEnd");
          localStorage.removeItem("homeworkInfo");

          if (query.get("category_id") == 10) {
            back();
          } else {
            history.push(homeworkPath);
          }
        }
      }
    };

    const forceFullscreen = () => {
      var elem = document.getElementById("inner");
      if (screenfull.isEnabled && !screenfull.isFullscreen) {
        screenfull.request(elem).catch(() => {
          force && homework_id === null ? back() : homework();
        });
      }
    };

    React.useEffect(() => {
      const homeworkInfo = {
        BASE_URL: BASE_URL,
        API_KEY: API_KEY,
        homeworkNumber: homeworkNumber,
        homework_id: homework_id,
        singleclass: singleclass === "true" ? true : false,
      };
      localStorage.setItem("homeworkInfo", JSON.stringify(homeworkInfo));
      if (screenfull.isEnabled) {
        if (force) {
          forceFullscreen();
        }
      }

      return () => {
        if (screenfull.isEnabled) screenfull.off("change");
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div id="inner" className={!fullscreen ? styles.inner : styles.innerFull}>
        <Component {...props} />
        {screenfull.isEnabled && (
          <FullscreenButton
            color={colorButtonFullscreen}
            onClick={() => {
              screenfull.exit();

              homework();
            }}
            fullscreen={fullscreen}
          />
        )}
      </div>
    );
  };

  return Force;
};
