import { useEffect, useReducer, useRef } from "react";
import axios from "axios";
import videoReducer, {
  SET_LOADING,
  SET_FILES,
  SET_ERROR,
} from "./videoReducer";
import { addAuthorization } from "./utils";
import { BASE_URL, API_KEY } from "../Constants";

const useFetchDataDirectVideo = (
  endPoint,
  params,
  token,
  vimeoId,
  pandaId = null
) => {
  const [state, dispatch] = useReducer(videoReducer, {
    loading: true,
    video: null,
    files: null,
    error: null,
  });

  const source = useRef(null);
  var videoId = pandaId !== "null" && pandaId !== null;

  useEffect(() => {
    const loadVideo = async () => {
      dispatch({ type: SET_LOADING });
      loadFiles(videoId);
    };

    const loadFiles = async (videoId) => {
      var url = `${BASE_URL}/${
        pandaId !== "null" && pandaId !== null ? "panda" : "vimeo"
      }/${
        pandaId !== "null" && pandaId !== null ? pandaId : vimeoId
      }?apikey=${API_KEY}`;
      source.current = axios.CancelToken.source();

      try {
        const response = await axios({
          url: url,
          method: "GET",
          data: params,
          headers: addAuthorization(token),
          cancelToken: source.token,
        });

        dispatch({ type: SET_FILES, payload: response.data.result });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          dispatch({ type: SET_ERROR, error: error });
          // throw error;
        }
      }
    };

    loadVideo();

    return () => source.current.cancel(`${endPoint} canceled by the user.`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endPoint]);

  return state;
};

export default useFetchDataDirectVideo;
