import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import user from "../reducers/user";
import coin from "../reducers/coin";
import profile from "../reducers/profile";
import notice_wall from "../reducers/notice_wall";
import { load, save } from "../../localStorage";

const appReducer = combineReducers({
  user,
  coin,
  profile,
  notice_wall,
});

// process.env.NODE_ENV !== 'production' &&
const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
var persistedState = {};

let persistedUser = load("user");
if (persistedUser !== null) {
  persistedState.user = persistedUser;
}

const initialState = {};

const store = applyMiddleware(thunk)(createStore)(
  appReducer,
  { ...persistedState, ...initialState },
  devTools
);

store.subscribe(() => {
  const state = store.getState();
  // console.log(state);
  save(state.user, "user");
});

export default store;
