import React from "react";
import { Grid } from "@material-ui/core";
import GridAutoFlow from "../GridAutoFlow";
import BoxContent from "../BoxContent";
import Subtitle from "../../ui/subtitle";
import Thumb_class_reposition from "../../assets/thumb_kidsa_reposition.png";

export default ({ classReplacement }) => {
  return (
    <Grid item xs={11}>
      {classReplacement.length !== 0 && (
        <Subtitle>Video de Reposição de Aula</Subtitle>
      )}
      <GridAutoFlow>
        {classReplacement.map((value, index) => {
          if (value.length === 0) return null;
          var { image_thumb, name, id, vimeo_id, panda_id } =
            value.object_related;
          if (vimeo_id === "") vimeo_id = null;
          if (panda_id === "") panda_id = null;
          const image =
            image_thumb.length === 0
              ? Thumb_class_reposition
              : image_thumb[0].secure_url_image;
          return (
            <BoxContent
              {...value}
              key={index}
              image={image}
              name={name}
              path="directvideo"
              id={id}
              category_id={0}
              homework_id={value.homework_id}
              vimeo_id={vimeo_id}
              watched={value.completed}
              panda_id={panda_id}
            />
          );
        })}
      </GridAutoFlow>
    </Grid>
  );
};
