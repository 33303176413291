import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import notShow from "../../hoc/notShow";
import Menu from "../Menu";
import CoinsContainer from "../CoinsContainer";
import LogoPlay from "../../assets/logo-kidsa-play-topo.png";
import LogoLive from "../../assets/kidsa_english_logo.png";
import NoticeWallBell from "../NoticeWall/NoticewallBell";

import { Auth } from "../../client/api";
import { load } from "../../localStorage";
import { API_KEY } from "../../Constants";

import AddIcon from "@material-ui/icons/Add";

import { Redirect } from "react-router-dom";

import KoinsShopButton from "../KoinsShopButton";
import BackgroundDowloadButton from "../BackgroundDowloadButton";
import SendVideoButton from "../SendVideoButton";
import InviteClub from "../InviteClub";

const useStyles = (isLive) =>
  makeStyles((theme) => ({
    container: {
      backgroundColor: isLive ? "white" : "#004dc9",
      boxShadow: "none",
      "&> .MuiToolbar-root": {
        justifyContent: "space-between",
      },
      "& .MuiButton-text": {
        color: isLive ? "#494949" : "white",
        textAlign: "center",
      },
    },
    title: {
      display: "flex",
      flexGrow: 1,
      margin: 0,
      "&> a": {
        display: "contents",
      },
    },
    logo: {
      height: isLive ? 75 : 110,
      margin: "10px 0",
      [theme.breakpoints.down("sm")]: {
        height: 55,
      },
    },
  }));

function PromotionalLink() {
  const date = new Date();
  console.log({ dataServidorUTC: date.getUTCDate() });
  if (date.getUTCDate() >= 16 && date.getUTCDate() <= 30) {
    return "/live/promotion-page";
  } else {
    return "/live/calendar";
  }
}

const play = [
  { label: "Kidsa live", to: "/live/calendar" },
  { label: "Vídeos", to: "/videos" },
  { label: "Games", to: "/games" },
  { label: "Aplicativo", href: "https://kidsa.com/" },
  { label: "Dúvidas Frequentes", href: "https://kidsa.com/faq/" },
  { label: "Fale Conosco", href: "https://kidsa.com/contato/" },
];

const live = [
  { label: "Home", to: "/live/calendar" },
  { label: "Meu Curso", to: "/live/turmas" },
  { label: "Minhas Reservas", to: "/live/my-reserves" },
  { label: "Atividades", to: "/live/homework" },
  { label: "Kidsa play", to: "/home" },
  { label: "Dúvidas Frequentes", href: "https://kidsa.com/faq/" },
  { label: "Fale Conosco", href: "https://kidsa.com/contato/" },
  // { label: "Férias no Club", to: "/feriasnoclub" },
  // { label: "INDIQUE AMIGOS", href: "https://kidsa.com/2/indicou0/" },
  // { label: 'Minhas atividades', to: '/live/minhas-atividades' },
  // { label: 'Mapa do curso', to: '/live/mapa-curso' },
  // { label: 'Área dos pais', to: '/live/area-pais' },
  // area user
];

const getCoins = async (setCoin) => {
  if (load("user") !== null && load("user").profile != null) {
    const { profile } = load("user");
    const token = profile.user.auth_token;

    const coin = await Auth(token).get(`coins?apikey=${API_KEY}`);
    if (coin.data.response.coins > 0) setCoin(coin.data.response.coins);
    else setCoin(0);
  }
};

const getCredit = async (setCredit) => {
  if (load("user") !== null && load("user").profile != null) {
    const { profile } = load("user");
    const token = profile.user.auth_token;

    const credit = await Auth(token).get(`customer/me?apikey=${API_KEY}`);
    if (credit.data.result.credits > 0) setCredit(credit.data.result.credits);
    else setCredit(0);
  }
};

const Index = (props) => {
  const dispatch = useDispatch();
  const [coin, setCoin] = useState(0);
  const [credit, setCredit] = useState(0);
  const [redirecionar, setRedirecionar] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const coinsRefresh = useSelector((state) => state.coin, [coin]);

  const { pathname } = props;
  var isLive = pathname.indexOf("live") === 1 || window.location.pathname === "/feriasnoclub";
  const styles = useStyles(isLive)();

  getCredit(setCredit);
  getCoins(setCoin);

  const setRedirect = () => {
    setRedirecionar(true);
    setTimeout(() => {
      setRedirecionar(false);
    }, 1000);
  };

  useEffect(() => {
    getCoins(setCoin);
    if (coinsRefresh === true) {
      dispatch({
        type: "REFRESHED_COIN",
      });
    }
  }, [coinsRefresh, dispatch]);
  if (matches)
    return (
      <AppBar position="fixed" className={styles.container}>
        <Toolbar>
          <Header
            styles={styles}
            logo={isLive ? LogoLive : LogoPlay}
            to={isLive ? "/live/calendar" : "/home"}
          >
            {props.logged && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <NoticeWallBell isLive={isLive} />
                <Menu isLive={isLive} options={isLive ? live : play} />
              </div>
            )}
          </Header>
        </Toolbar>
        <div
          style={{
            padding: "0 5px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isLive ? <KoinsShopButton /> : <></>}
          {props.logged && (
            <CoinsContainer coins={coin} mobile={true} isLive={isLive} />
          )}
        </div>

        <div
          style={{
            padding: "0 5px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            alignItems: "center",
          }}
        >
          {isLive ? <BackgroundDowloadButton /> : <></>}
          {isLive && (
            <Button color="primary" size="small" onClick={() => setRedirect()}>
              {" "}
              CRÉDITOS: {credit}
              <AddIcon />
            </Button>
          )}
        </div>

        <div
          style={{
            // padding: "0 5px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
            alignItems: "center",
          }}
        >
          {/* <InviteClub /> */}
          <SendVideoButton />
        </div>
      </AppBar>
    );
  else
    return (
      <AppBar position="fixed" className={styles.container}>
        <Toolbar>
          <Header
            styles={styles}
            logo={isLive ? LogoLive : LogoPlay}
            to={isLive ? "/live/calendar" : "/home"}
          >
            {props.logged && (
              <div>
                <div style={{ color: "black" }}></div>

                <Menu isLive={isLive} options={isLive ? live : play} />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "15px",
                    marginBottom: "15px",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <InviteClub /> */}
                  <SendVideoButton />
                  {isLive ? <BackgroundDowloadButton /> : <></>}
                  <KoinsShopButton />
                  {isLive && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setRedirect()}
                    >
                      {" "}
                      CRÉDITOS: {credit}
                      <AddIcon />
                    </Button>
                  )}

                  {props.logged && (
                    <CoinsContainer coins={coin} isLive={isLive} />
                  )}
                </div>

                {/* 
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                
                  
                </Grid> */}
              </div>
            )}

            {redirecionar && <Redirect to="/live/credit" />}
          </Header>
        </Toolbar>
      </AppBar>
    );
};

export default notShow(Index);
