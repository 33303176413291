import React from "react";
import SubmitSkinButton from "../styles/submit-skin-button";
import { useHistory } from "react-router-dom";

const HomeworkButton = (hasHomeworks, styles, classId, singleHomework) => {
  const history = useHistory();
  if (
    hasHomeworks !== false &&
    hasHomeworks !== null &&
    typeof hasHomeworks !== "undefined"
  ) {
    return (
      <>
        <SubmitSkinButton
          fullWidth
          className={styles.button}
          onClick={() => {
            history.push(
              `/live/homework/detail/${classId}?singleclass=${singleHomework}`
            );
          }}
        >
          Ir para Atividades
        </SubmitSkinButton>
      </>
    );
  } else {
    return <></>;
  }
};

export default HomeworkButton;
