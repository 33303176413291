import React from "react";
import {makeStyles} from "@material-ui/core";

import SmallBtn from "../assets/smallBtn.png";
import FeedbackGood from "../assets/feedbackGood.png";
import FeedbackBad from "../assets/feedbackBad.png";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ED6868",
    zIndex: 10000,
  },
  containerAreaText: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  areaTextFeedback: {
    backgroundColor: "white",
    borderRadius: 22,
    shadowOffset: {width: 0, height: 3},
    shadowColor: "black",
    shadowOpacity: 0.4,
    shadowRadius: 0,
    padding: 2,
  },
  outsideWhiteBorder: {
    backgroundColor: "black",
    borderRadius: 21,
    padding: 2,
  },
  middle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "red",
    padding: 2,
    borderRadius: 20,
    borderWidth: 1,
  },
  textFeedback: {
    fontSize: 24,
    backgroundColor: "transparent",
    paddingHorizontal: 20,
  },
  containerButton: {
    position: "absolute",
    right: 10,
    bottom: 10,
    width: 192,
    height: 65,
  },
  actionButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    background: `url(${SmallBtn})`,
    backgroundSize: "192px 65px",
    backgroundRepeat: "no-repeat",
    width: 192,
    height: 65,
  },
  textButton: {
    fontSize: 18,
    color: "#424242",
    backgroundColor: "transparent",
    display: "flex",
    position: "absolute",
  },
});

export default ({hide, text1, pass, onReloadGame}) => {
  const styles = useStyles();

  const createReloadButtonGame = () => {
    if (!pass) {
      return (
        <div className={styles.containerButton}>
          <button className={styles.actionButton} onClick={onReloadGame}>
            <p className={styles.textButton}>TRY AGAIN</p>
          </button>
        </div>
      );
    }

    return null;
  };

  if (hide) {
    return null;
  }

  const image = pass ? FeedbackGood : FeedbackBad;
  const background = pass ? {backgroundColor: "#4DFF79"} : {backgroundColor: "#F84C4C"};
  const colorText = pass ? {color: "#424242"} : {color: "white"};

  return (
    <div className={styles.container}>
      <div
        style={{position: "relative", display: "flex", maxWidth: 300, justifyContent: "center", alignItems: "center"}}
      >
        <img src={image} alt="background" width={300} height={300} />
        <div className={styles.containerAreaText}>
          <div className={styles.areaTextFeedback}>
            <div className={styles.outsideWhiteBorder}>
              <div className={styles.middle} style={background}>
                <p className={styles.textFeedback} style={colorText}>
                  {text1 || "Updating..."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {createReloadButtonGame()}
    </div>
  );
};
