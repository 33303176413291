import React, { useState, useEffect } from "react";
import "./ChooseCredit.css";
import CardCredit from "../../containers/CardCredit";
import { load } from "../../localStorage";
import { API_KEY } from "../../Constants";
import { Auth } from "../../client/api";
import FriendCredit from "../../components/FriendCredit";

import Grid from "@material-ui/core/Grid";

// config

const initialState = {
  credits: [],
};

const ChooseCredit = (props) => {
  const [state, setState] = useState(initialState);
  const [reload, setReload] = useState(false);
  const [token, setToken] = useState(false);

  const loadData = async () => {
    if (token !== false) {
      // const response = await Auth(token).post(`/creditpackage/creditPayment?apikey=${API_KEY}`);
      const response = await Auth(token).get(
        `/creditpackage/?apikey=${API_KEY}`
      );
      console.log(response.data.result);
      setReload(true);
      setState({ credits: response.data.result });
    }
  };

  useEffect(() => {
    const { profile } = load("user");
    if (profile != null) {
      setToken(profile.user.auth_token);
    }

    if (!reload) {
      loadData();
    }
  }, [reload, loadData]);

  return (
    <div className="creditContainer">
      <Grid
        container
        justify="center"
        spacing={2}
        style={{ paddingBottom: "20px" }}
      >
        {state.credits.map((credit) => {
          return <CardCredit credit={credit} token={token} />;
        })}
        {/* <FriendCredit /> */}
      </Grid>
    </div>
  );
};

export default ChooseCredit;
