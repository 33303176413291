// User
export const USER_SIGN_IN_START = 'USER_SIGN_IN_START';
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_FAILURE = 'USER_SIGN_IN_FAILURE';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const USER_CLEAR = 'USER_CLEAR';
export const USER_RECOVER_PASSWORD_START = 'USER_RECOVER_PASSWORD_START';
export const USER_RECOVER_PASSWORD_IN_SUCCESS =
  'USER_RECOVER_PASSWORD_IN_SUCCESS';
export const USER_RECOVER_PASSWORD_IN_FAILURE =
  'USER_RECOVER_PASSWORD_IN_FAILURE';

// Child
export const NEW_CHILD_IN_START = 'NEW_CHILD_IN_START';
export const NEW_CHILD_IN_SUCCESS = 'NEW_CHILD_IN_SUCCESS';
export const NEW_CHILD_IN_FAILURE = 'NEW_CHILD_IN_FAILURE';

export const CHILD_REQUEST_START = 'CHILD_REQUEST_START';
export const CHILD_APPLY_LIVE_COURSE_SUCCESS =
  'CHILD_APPLY_LIVE_COURSE_SUCCESS';
export const CHILD_APPLY_LIVE_COURSE_FAILURE =
  'CHILD_APPLY_LIVE_COURSE_FAILURE';
