import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      text: {
        color: 'white',
        fontWeight: 'bold',
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: 'bold',
        textAlign: 'center',
        display: 'block',
      },
    },
  },
});

theme.typography.h1 = {
  color: '#494949',
  textAlign: 'center',
  marginTop: 20,
  marginBottom: 5,
  fontWeight: 'bold',
};

theme.typography.h2 = {
  color: '#494949',
  textAlign: 'center',
  fontWeight: 'bold',
  marginBottom: 20,
};

theme.typography.body1 = {
  fontSize: 14,
  textAlign: 'center',
  fontWeight: 'bold',
  [theme.breakpoints.down('xs')]: {
    fontSize: 13,
  },
};

export default theme;
