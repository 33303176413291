import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { save, load } from '../localStorage';
import { Auth } from '../client/api';
import { API_KEY } from '../Constants';

const useStyles = makeStyles({
  container: {
    minHeight: '100%',
    backgroundColor: 'white',
  },
});

function getME(token, callback) {
  Auth(token)
    .get('/customer/me?apikey=' + API_KEY)
    .then((response) => {
      callback(response.data.result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export default (props) => {
  const styles = useStyles();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (loading) {
      const user = load('user');

      if (!user || !user.profile) {
        setLoading(false);
        return;
      }

      const { profile } = user;

      getME(profile.user.auth_token, (result, error) => {
        setLoading(false);

        if (result) {
          const copy = {
            ...user,
            profile: {
              ...result,
            },
          };
          save(copy, 'user');
        }

        // const reloadProfile = load('user').profile;
        // console.log(
        //   `reloadProfile.subscription.allow_live_access ${reloadProfile.subscription.allow_live_access} - reloadProfile.user.type_user ${reloadProfile.user.type_user}`
        // );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) return null;

  return (
    <Grid container justify='center' alignItems='center' className={styles.container}>
      {props.children}
    </Grid>
  );
};
