import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge, makeStyles, IconButton } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import useFetch from "../../hook/useFetch";
import { load } from "../../localStorage";

const useStyles = () => makeStyles(() => ({}));

export default ({ isLive }) => {
  const classes = useStyles();
  const refreshed_notice_wall = useSelector((state) => state.notice_wall);
  const history = useHistory();
  const { profile } = load("user");
  const { children } = profile;
  const token = profile.user.auth_token;
  const childrenLive = children.filter((item) => item.has_live_course)[0];
  const dispatch = useDispatch();

  if (childrenLive !== undefined) {
    const state = useFetch(
      `noticewall/verify?profile_id=${childrenLive.id}`,
      null,
      token
    );

    const [notice_wall_notify, setNotice_wall_notify] = useState(0);
    useEffect(() => {
      if (state.loading === false && state.data !== null) {
        setNotice_wall_notify(state.data.count);
      }
      if (refreshed_notice_wall === true) {
        setNotice_wall_notify(0);
      }
    }, [state, refreshed_notice_wall]);

    if (isLive) {
      return (
        <IconButton
          onClick={() => history.push("/live/profile?menu=notice_wall")}
        >
          <Badge badgeContent={notice_wall_notify} color={"primary"}>
            <Notifications color={"error"} fontSize="large" />
          </Badge>
        </IconButton>
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};
