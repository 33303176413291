import React, { useState, useEffect } from "react";

import Loading from "../../components/Loading";
import HeaderPage from "../../components/HeaderPage";
import { load } from "../../localStorage";
import useFetch from "../../hook/useFetch";
import { Grid } from "@material-ui/core";
import ClassSliderContent from "../../components/ClassSliderContent";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import client from "../../client/api";
import { API_KEY } from "../../Constants";

const useStyles = makeStyles({
  selectClassGroupContainer: {
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectClassGroup: {
    border: "none",
    padding: "5px",
    fontSize: "25px",
    fontWeight: "bolder",
    textAlign: "center",
  },
  selectClassGroupMobi: {
    marginTop: "50px",
    border: "none",
    padding: "5px",
    fontSize: "18px",
    fontWeight: "bolder",
    width: "95vw",
    textAlign: "center",
  },
});

export default () => {
  const { profile } = load("user");
  const token = profile.user.auth_token;
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];
  const classes = useStyles();
  const [classGroup, setClassGroup] = useState(null);
  const [state, setState] = useState([]);
  const [stateWatched, setStateWatched] = useState([]);
  const [stateNotWatch, setStateNotWatch] = useState([]);
  const [loading, setLoading] = useState(true);
  const mobile = useMediaQuery("(max-width:960px)");
  const [fetchData, setFetchData] = React.useState(0);

  const groupList = useFetch(
    `livecourseclassturmas/getNameOfTurmaByProfile?profile_id=${childrenLive.id}`,
    null,
    token,
    fetchData
  );

  function filterFiled(listClassGroup) {
    var filterListClassGroup = listClassGroup.filter((classGroup) => {
      if (classGroup.filed) {
        var updateAt = new Date(classGroup.updated_at);
        console.log(updateAt);
        var dateStart = new Date(2022, 9, 25);

        if (updateAt > dateStart) {
          return classGroup;
        }
      } else {
        return classGroup;
      }
    });
    return filterListClassGroup;
  }

  function isFiled() {
    var seleted = groupList.data.filter(
      (item) => item.class_group_id == classGroup
    );

    return seleted[0].filed;
  }

  async function getData() {
    setLoading(true);
    var reserved = await client.get(
      `livecourseclassturmas/turma/0?profile_id=${childrenLive.id}&class_group_id=${classGroup}&apikey=${API_KEY}`
    );

    setState({ data: reserved.data.result });

    var watched = await client.get(
      `livecourseclassturmas/turma/2?profile_id=${childrenLive.id}&class_group_id=${classGroup}&apikey=${API_KEY}`
    );
    setStateWatched({ data: watched.data.result });

    var notWatch = await client.get(
      `livecourseclassturmas/turma/3?profile_id=${childrenLive.id}&class_group_id=${classGroup}&apikey=${API_KEY}`
    );
    setStateNotWatch({ data: notWatch.data.result });
  }

  useEffect(() => {
    if (groupList.loading === false) {
      if (groupList.data.length > 0) {
        setClassGroup(groupList.data[0].class_group_id);
      } else {
        setLoading(false);
      }
    }
  }, [groupList]);

  useEffect(() => {
    if (classGroup !== null) {
      getData().then(() => {
        setLoading(false);
      });
    }
  }, [classGroup]);

  if (loading) return <Loading />;

  if (groupList.data.length === 0)
    return <h4>Aviso : O aluno logado não está vinculado a nenhuma turma.</h4>;

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ paddingTop: 240, paddingBottom: 140 }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <HeaderPage
            title={
              filterFiled(groupList.data).length === 1
                ? groupList.data[0].group_name
                : ""
            }
          >
            {filterFiled(groupList.data).length === 1 ? (
              ""
            ) : (
              <div className={classes.selectClassGroupContainer}>
                <select
                  className={
                    mobile
                      ? classes.selectClassGroupMobi
                      : classes.selectClassGroup
                  }
                  defaultValue={classGroup}
                  onChange={(e) => setClassGroup(e.target.value)}
                >
                  {filterFiled(groupList.data).map((classGroupItem, index) => (
                    <option value={classGroupItem.class_group_id} key={index}>
                      {classGroupItem.group_name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </HeaderPage>
          <div
            style={{
              color: "black",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "18px",
            }}
          >
            {filterFiled(groupList.data).length === 1
              ? "Aviso : Todas as aulas estão configuradas no HORÁRIO DE BRASÍLIA."
              : "Aviso : Agora você pode ver o histórico das suas turmas anteriores, clicando acima."}
          </div>
        </div>
        {groupList.data.find((value) => value.class_group_id === 5420 && !value.filed) && 
        <div style={{ fontSize: '2rem', fontWeight: 'bold', color: 'black', textAlign: 'center' }}>
          Clique abaixo para acessar a nova plataforma e assistir suas aulas: 
          <br></br>
          <a href="https://www.portal.kidsa.com" style={{ color: 'blue', textDecoration: 'underline' }}>
            portal.kidsa.com
          </a>
        </div>}
        {groupList.data.length === 0 ||
        isFiled() ||
        state.data === null ||
        state.data.length === 0 ? (
          ""
        ) : (
          <ClassSliderContent
            title="Aulas"
            allowGetInClassButton={true}
            allowCancel={true}
            classContent={state}
            profile={profile}
          />
        )}

        {groupList.data.length === 0 ||
        stateWatched.data === null ||
        stateWatched.data.length === 0 ? (
          ""
        ) : (
          <ClassSliderContent
            title="Assistidas"
            allowGetInClassButton={false}
            allowCancel={false}
            classContent={stateWatched}
            profile={profile}
            statusChip="watched"
            watched={true}
          />
        )}

        {groupList.data.length === 0 ||
        stateNotWatch.data === null ||
        stateNotWatch.data.length === 0 ? (
          ""
        ) : (
          <ClassSliderContent
            title="Não Assistidas - Aulas gravadas"
            allowGetInClassButton={false}
            allowCancel={false}
            classContent={stateNotWatch}
            profile={profile}
          />
        )}
      </Grid>
    </>
  );
};
