import React from "react";
import { Grid, Typography, makeStyles, Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  containerButtonFilter: {
    position: "absolute",
    right: 0,
    top: 20,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      marginBottom: 50,
    },
    cursor: {
      cursor: "pointer",
    },
  },
}));

export default () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <>
      <Grid item xs={11} style={{ position: "relative" }}>
        <Typography variant="h1">
          As aulas de turma retornam dia 2 de agosto.
        </Typography>

        <Typography variant="h1">
          Aproveite nossa Colônia de Férias! Você pode reservar até 3 aulas por
          semana!
        </Typography>

        <Link className={classes.cursor}>
          <Typography
            variant="h1"
            color="primary"
            onClick={() => history.push("/live/turmas")}
          >
            Clique aqui para acessar página Meu Curso.
          </Typography>
        </Link>
      </Grid>
    </>
  );
};
