import React from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 140,
  },
  title: {
    color: '#004dc9',
  },
  about: {
    textAlign: 'center',
  },
  button: {
    color: 'black',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
}));

export default ({ image, title, description, path }) => {
  const styles = useStyles();

  return (
    <Card>
      <CardActionArea component={Link} to={path}>
        {image && (
          <CardMedia className={styles.media} image={image} title='Conteúdo' />
        )}
        <CardContent>
          <Typography gutterBottom variant='h1' className={styles.title}>
            {title || 'Title'}
          </Typography>
          <Typography variant='body1' component='p' className={styles.about}>
            {description || 'description'}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button className={styles.button} component={Link} to={path}>
          Acessar
        </Button>
      </CardActions>
    </Card>
  );
};
