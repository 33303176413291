import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { load } from "../../localStorage";
import useFetch from "../../hook/useFetch";
import CountUp from "react-countup";

import KoinsExchangeHeaderPicture from "../../assets/koinsExchangeHeaderPic.png";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "10px",
    padding: 10,
    maxWidth: 1200,
    width: "100%",
  },
  heading: {
    fontFamily: "Arial Black",
    textAlign: "center",
    fontSize: "2em",
  },
  placarContainer: {
    textAlign: "center",
    fontSize: "50px",
    color: "#007cba",
    fontWeight: "bold",
  },
  placarNumber: {
    color: "#1943b7",
  },
  subtitle: { fontSize: "40px", color: "#1943b7", textAlign: "center" },
  text: {
    fontFamily: "'Helvetica'",
    fontSize: "18px",
    color: "#5e636e",
    textAlign: "center",
  },
});

function KoinsExchangeHeader() {
  const { profile } = load("user");
  const token = profile.user.auth_token;
  const totalKoins = useFetch(`koinsexchangeorder/totalkoins`, null, token);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <figure>
        <img src={KoinsExchangeHeaderPicture} />
      </figure>
      <h2 className={classes.heading}>PLACAR ATUALIZADO</h2>
      <div className={classes.placarContainer}>
        <span className={classes.placarNumber}>
          {totalKoins.loading == true ? (
            "0"
          ) : (
            <CountUp end={parseInt(totalKoins.data) + 17540} duration={4} />
          )}
        </span>{" "}
        ={" "}
        <span className={classes.placarNumber}>
          {" "}
          {totalKoins.loading == true ? (
            "0"
          ) : (
            <CountUp
              end={(parseInt(totalKoins.data) + 13840) / 307}
              duration={4}
            />
          )}{" "}
        </span>{" "}
        aulas doadas
      </div>
      <div className={classes.subtitle}>
        Koins que foram doadas até o momento
      </div>
      <div className={classes.text}>
        Selecione o prêmio de sua criança, preencha o formulário que entraremos
        em contato com você!
      </div>
    </div>
  );
}

export default KoinsExchangeHeader;
