import React from "react";
import "./CardCredit.css";
import { Auth } from "../client/api";
import { API_KEY } from "../Constants";

export default class CardCredit extends React.Component {
  async redirect(credit) {
    const url = await Auth(this.props.token).get(
      `/creditpackage/${credit.id}/link?apikey=${API_KEY}`
    );

    window.location.href = url.data.link;
  }

  render() {
    const { credit } = this.props;
    return (
      <div className="product-card">
        <div className="product-tumb">
          <img
            src={
              credit.image_thumb.length > 0
                ? credit.image_thumb[0].secure_url_image
                : ""
            }
            alt=""
          />
        </div>
        <div className="product-details">
          <p>{credit.description}</p>
          <div className="product-bottom-details">
            {/* <div className="product-price">R${`${parseFloat(credit.value_money).toFixed(2)}`.replace('.',',')}</div> */}

            <div className="product-price">
              R$
              {`${parseFloat(credit.value_money).toFixed(2)}`.replace(".", ",")}
            </div>
            <div className="product-links">
              <p>CRÉDITOS : {credit.value_credits} </p>
            </div>
          </div>
          <div className="container-Button">
            <button
              className="btn-Credits"
              onClick={() => {
                this.redirect(credit);
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
              COMPRAR{" "}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
