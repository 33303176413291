import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 200,
    right: 30,
    border: "1px solid #000",
  },
}));

export default () => {
  const [capsLock, setCapsLock] = useState(false);
  function testCapsLock(event) {
    if (event.code === "CapsLock") {
      let isCapsLockOn = event.getModifierState("CapsLock");
      if (isCapsLockOn) {
        toast.warning("Caps Lock Ativo", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.warning("Caps Lock Desativado", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }
  }
  useEffect(() => {
    document.addEventListener("keyup", testCapsLock);
  });

  const styles = useStyles();
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        pauseOnHover
      />

      <ToastContainer />
    </>
  );
};
