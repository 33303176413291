import React from "react";
import { Toolbar, Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";

export default ({ options, children }) => {
  return (
    <Toolbar disableGutters>
      {options.map((item, index) => {
        if (item.to && item.to.length > 1) {
          return (
            <Button
              key={index}
              component={NavLink}
              to={item.to}
              activeClassName="active-menu"
            >
              {item.label}
            </Button>
          );
        }

        return (
            <Button key={index} onClick={() => {
              if(!item.href){
                return;
              }
              window.open(item.href, "_blank")
            }}>
              {item.label}
            </Button>
        );
      })}
      {children}
    </Toolbar>
  );
};
