import React from "react";
import { makeStyles, ButtonBase } from "@material-ui/core";

import IconWatched from "../assets/watched.png";
import IconReserved from "../assets/reserved.png";

const useStyles = (live) =>
  makeStyles({
    container: {
      position: "relative",
      margin: "0 5px",
      maxWidth: "275px",
      color: "white",
    },
    info: {
      position: "absolute",
      left: 0,
      top: 0,
      padding: 5,
      color: "black",
    },
    link: {
      textDecoration: "none",
      display: "block",
    },
    image: {
      padding: "0 5px",
      height: 200,
      width: "100%",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "none",
      backgroundColor: "#F1f1f1",
    },
    nameLiveCourse: {
      fontSize: "16px !important",
    },
    title: {
      color: "black",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 16,
    },
    chip: {
      position: "absolute",
      right: 5,
      top: 5,
      width: 60,
    },
    button: {
      margin: "0 0 20px 0",
    },
  });

const Box = (props) => {
  const {
    live,
    liveClass,
    reserved,
    reserve,
    statusChip,
    replacementvideoid = false,
    hasHomeworks,
    classId,
    replacementhomeworkid = false,
    recorded = false,
    recorded_class_video = null,
    singleHomework,
  } = props;

  const styles = useStyles(live || liveClass || reserved)();

  const mostrarData = (init_at_complete_format, hora, reserved = true) => {
    if (hora !== "") {
      let separaData = init_at_complete_format.split(" ");
      return `${separaData[0]} ${hora}`;
    } else {
      let separaData = init_at_complete_format.split(" ");
      return `${separaData[0]} ${separaData[1]}`;
    }
  };

  const diaDaSemana = (init_at_complete_format) => {
    const currentDate = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // adiciona um dia a data
  
    var semana = [
      "Domingo",
      "Segunda-Feira",
      "Terça-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feira",
      "Sábado",
    ];
    var separar = init_at_complete_format.split(" ");
  
    if(currentDate.toLocaleDateString('pt-BR') === separar[0]){
      return "Hoje";
    } else if(tomorrow.toLocaleDateString('pt-BR') === separar[0]) {
      return "Amanhã";
    }
  
    var data = separar[0].split("/");
    data = new Date(data[2], data[1] - 1, data[0]);
    return semana[data.getDay()];
  };

  const renderChip = (statusChip) => {
    if (statusChip === "watched") {
      return (
        <img
          src={IconWatched}
          alt="Watched"
          className={styles.chip}
          color="primary"
        />
      );
    } else if (statusChip === "reserved") {
      return (
        <img
          src={IconReserved}
          alt="Reserved"
          className={styles.chip}
          color="primary"
        />
      );
    }

    return null;
  };

  if (reserve) {
    // const { live_course_class_date, allow_cancel,hora } = props;
    const {
      id,
      image_thumb,
      link,
      live_course_class_description,
      live_course_class_name,
      init_at_complete_format,
      teacher,
      hora,
      recordedclass = false,
      init_at,
      recorded = false,
      recorded_class_video = null,
      singleHomework,
    } = props;

    // const { name, image_thumb } = live_course_class;
    const currentImage = image_thumb[0].secure_url_image;

    return (
      <div className={styles.container}>
        <ButtonBase
          onClick={() => {
            // if (reserved ) {
            props.onOpenDialog({
              id: id,
              name: live_course_class_name,
              description: live_course_class_description,
              // countReserves: count_reserves,
              // numberParticipants: number_participants,
              hasHomeworks: hasHomeworks,
              classId: classId,
              reserved: true,
              watched: false,
              teacher: teacher,
              initHour: init_at_complete_format,
              replacementvideoid: replacementvideoid,
              replacementhomeworkid: replacementhomeworkid,
              // allowCancel: allow_cancel,
              // hasVacancy: has_vacancy,
              init_at_complete_format: init_at_complete_format,
              link: link,
              recordedclass: recordedclass,
              init_at: init_at,
              recorded: recorded,
              recorded_class_video: recorded_class_video,

              singleHomework: singleHomework,
            });
            // }
          }}
          style={{ display: "block", width: "100%" }}
        >
          {renderChip(statusChip)}
          <div
            className={styles.image}
            style={{
              backgroundImage: "url(" + currentImage + ")",
            }}
          ></div>
          <h3 className={styles.title}>{` ${diaDaSemana(
            init_at_complete_format
          )} - ${mostrarData(
            init_at_complete_format,
            hora,
            true,
            live_course_class_description
          )}`}</h3>
        </ButtonBase>
        {/* {!reserved && (
          <SubmitSkinButton
            className={styles.button}
            fullWidth
            onClick={() => props.makeReserve(id)}
          >
            {!props.processing ? (
              'FAZER RESERVA'
            ) : (
              <CircularProgress size={20} color='secondary' />
            )}
          </SubmitSkinButton>
        )} */}
      </div>
    );
  }
};

export default Box;
