import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerButtonFilter: {
    // position: 'absolute',
    // right: 0,
    // top: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    // backgroundColor:'yellow',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 50,
    },
  },
}));

export default ({ title, description, children }) => {
  const styles = useStyles();
  return (
    <Grid item xs={12} style={{ position: 'relative' }}>
      <Typography variant='h1'>{title}</Typography>
      <Typography variant='h2'>{description}</Typography>
      <div className={styles.containerButtonFilter}>{children}</div>
    </Grid>
  );
};
