import React from "react";
import { Button, Chip, makeStyles } from "@material-ui/core";
import { RotateLeft } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import forceFullscreen from "../hoc/forceFullscreen";
import { Auth } from "../client/api";
import { load } from "../localStorage";
import ListenAndTap from "../game/ListenAndTap";
import Book from "./Book";
import Loading from "../components/Loading";
import { isMobile, isLandscape } from "../utils";
import { BASE_URL, API_KEY } from "../Constants";

//  DragndroEnd

const useStyles = makeStyles({
  iframeMobile: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    border: "none",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    zIndex: 0,
  },
  iframeDesktop: {
    width: "100%",
    height: "100vh",
  },
});

var json = null;
let contentWindow;
let mobile = isMobile();

const Game = () => {
  const styles = useStyles();

  let { id } = useParams();

  const [showGame, setShowGame] = React.useState(false);
  const [showBook, setShowBook] = React.useState(false);
  const [template, setTemplate] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [landscape, setLandscape] = React.useState(isLandscape());
  const [startGame, setStartGame] = React.useState(false);
  const [loadedGame, setLoadedGame] = React.useState(false);

  let myRef = React.createRef();

  const loadGame = () => {
    setLoading(true);

    const token = load("user").profile.user.auth_token;
    Auth(token)
      .get("gamecategory/item/" + id + "?apikey=" + API_KEY, { id: Number(id) }) // GET DATA CONTENT
      .then(
        (response) => {
          const { type_item_game_category, object_related } =
            response.data.result;
          json = object_related;

          if (type_item_game_category === 4) {
            // LISTEN_AND_TAP_GAME
            setLoading(false);
            setShowGame(true);
            setLoadedGame(true);
            return;
          } else if (type_item_game_category === 5) {
            // BOOK
            setLoading(false);
            setShowBook(true);
            setLoadedGame(true);
            return;
          }

          var url = BASE_URL + "/games/";

          if (type_item_game_category === 0)
            // MEMORY_GAME
            url += "memory/index.html";
          else if (type_item_game_category === 1)
            // DRAG_DROP_GAME
            url += "dragndrop/index.html";
          else if (type_item_game_category === 3)
            // LISTEN_AND_FIND_GAME
            url += "listenandfindgame/index.html";

          fetch(url)
            .then(function (response) {
              if (response.ok) {
                return response.text();
              }
              throw new Error("Error message.");
            })
            .then(function (data) {
              setLoading(false);
              setTemplate(data);
              setLoadedGame(true);

              setTimeout(() => {
                if (!isMobile()) {
                  start();
                }
              }, 3000);
            })
            .catch(function (err) {
              // console.log("failed to load ", url, err.message);
              setLoading(false);
              setLoadedGame(false);
            });
        },
        ({ response }) => {
          // console.log(response.data.error);
          setLoading(false);
          setLoadedGame(false);
        }
      )
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        setLoadedGame(false);
      });
  };

  const start = () => {
    contentWindow = document.getElementById("game").contentWindow;
    contentWindow.loadGame(JSON.stringify(json));
    contentWindow.startGame();
  };

  React.useEffect(() => {
    if (isMobile()) {
      if (!loadedGame && landscape) loadGame();
      window.addEventListener(
        "orientationchange",
        function () {
          setLandscape(isLandscape());
        },
        false
      );
    } else {
      if (!loadedGame) loadGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, loadedGame, landscape, startGame]);

  return (
    <div style={{ backgroundColor: "white" }}>
      {/* Memory game and drag/drop */}
      {template !== null && (
        <div
          style={
            !mobile
              ? { display: "block" }
              : { display: landscape ? "block" : "none", zIndex: 1 }
          }
        >
          <iframe
            id="game"
            title="game"
            srcDoc={template}
            className={mobile ? styles.iframeMobile : styles.iframeDesktop}
            frameBorder={0}
            scrolling="false"
          />
        </div>
      )}

      {/* Game Listen and tap */}
      {showGame && (
        <ListenAndTap
          ref={myRef}
          isMobile={mobile}
          json={json}
          style={
            !mobile
              ? { display: "flex" }
              : { display: landscape ? "flex" : "none" }
          }
        />
      )}

      {/* Book */}
      {showBook && (
        <Book
          ref={myRef}
          isMobile={mobile}
          json={json}
          forceFullScreen={false}
          style={
            !mobile
              ? { display: "flex" }
              : { display: landscape ? "flex" : "none" }
          }
        />
      )}

      {/* Warnings */}
      <div
        style={{ display: !mobile || loading ? "none" : "block", zIndex: 2 }}
      >
        {mobile && !landscape && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginLeft: "-111px",
              marginTop: "-16px",
            }}
          >
            <Chip
              color="secondary"
              label="ROTATE YOUR SMARTPHONE"
              icon={<RotateLeft />}
            />
          </div>
        )}

        <Button
          disableRipple
          style={{
            display: startGame ? "none" : landscape ? "block" : "none",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginLeft: "-60px",
            marginTop: "-16px",
          }}
          onClick={() => {
            setStartGame(true);

            if (showGame) {
              myRef.current.startGame();
            } else {
              start();
            }
          }}
          color="primary"
        >
          START GAME
        </Button>
      </div>

      {/* Loading */}
      {loading && <Loading />}
    </div>
  );
};

export default forceFullscreen(Game, "/games", mobile, true);
