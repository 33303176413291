import React, { useEffect, useState } from "react";
import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import ButtonHome from "../components/ButtonHome";
import Loading from "../components/Loading";
import useFetch from "../hook/useFetch";
import { load } from "../localStorage";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";

import LogoKidsaLive from "../assets/kidsa_english_logo.png";
import LogoKidsaPlay from "../assets/logo-kidsa-play.png";
import PromotionAsset from "../assets/promocional_banner.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "block",
    margin: "0 auto",
    maxHeight: "100px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },

  dialogContainer: {
    width: "100%",
    height: "100%",
    zIndex: "9999",
    position: "absolute",
    top: "0",
    border: "none",
    backgroundColor: "rgba(0,0,0,0.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bannerContainer: {
    display: "flex",
    backgroundColor: "#fff",
    height: "auto",
    width: "98%",
    maxWidth: "700px",
    flexDirection: "column",
    borderRadius: "8px",
    padding: "5px",
    boxShadow:
      "0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)",
  },
  closeContainer: {
    height: "25px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "15px",
  },
  closeDot: {
    backgroundColor: "#d3d3d3",
    display: "flex",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  link: {
    textAlign: "center",
    marginTop: "15px",
    fontSize: "24px",
    cursor: "pointer",
    color: "blue",
    fontWeight: "bold",

    "&:hover": {
      textDecoration: "underline blue",
    },
  },

  linkClose: {
    textAlign: "center",
    marginTop: "15px",
    fontSize: "20px",
    cursor: "pointer",
    color: "blue",
    fontWeight: "bold",
    marginBottom: "15px",
  },
}));

function PromotionalLink() {
  //   const date = new Date();
  //   console.log({'dataServidorUTC' : date.getUTCHours()});
  //  if(date.getUTCHours() >= 16 && date.getUTCHours() <= 23 ){
  //   return '/live/promotion-page'
  //  }
  //  else{
  return "/live/calendar";
  //  }
}

export default (props) => {
  const styles = useStyles();
  const token = load("user").profile.user.auth_token;
  const state = useFetch("kidsaplay/buttons", null, token);
  const [bannerDialog, setBannerDialog] = useState(false);
  const mobile = useMediaQuery("(max-width:960px)");
  const history = useHistory();

  useEffect(() => {
    var elemento = document.getElementById("modalBanner");
  }, []);

  const getPath = (typeButtonKidsaPlayName) => {
    switch (typeButtonKidsaPlayName.toLowerCase()) {
      case "video":
        return "/videos";
      case "game":
        return "/games";
      default:
        return "";
    }
  };

  const getImageThumbHome = (imageThumb) => {
    return imageThumb && imageThumb.length > 0
      ? imageThumb[0].secure_url_image
      : "";
  };

  if (state.loading) return <Loading />;
  if (state.data === null) return <p>Erro ao tentar carregar o conteúdo</p>;

  const buttons = [...state.data];
  const buttonLive = buttons.shift();

  function closeDialog() {
    setBannerDialog(false);
  }

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={
          mobile
            ? { paddingTop: 250, paddingBottom: 130 }
            : { paddingTop: 180, paddingBottom: 80 }
        }
      >
        <Grid item sm={11} md={5}>
          <Grid container justify="center" alignItems="center" spacing={1}>
            <Grid item xs={11}>
              <img
                src={LogoKidsaLive}
                alt="Kidsa Live"
                className={styles.logo}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <ButtonHome
                image={getImageThumbHome(buttonLive.image_thumb)}
                title={buttonLive.title}
                description={buttonLive.description}
                path={PromotionalLink()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={11} md={5}>
          <Grid container justify="center" alignItems="center" spacing={1}>
            <Grid item xs={11}>
              <img
                src={LogoKidsaPlay}
                alt="Kidsa Play"
                className={styles.logo}
              />
            </Grid>
            {buttons.map((item, index) => {
              let title = item.title;
              let description = item.description;
              let image = getImageThumbHome(item.image_thumb);
              let path = getPath(item.type_button_kidsa_play_name);

              return (
                <Grid item xs={11} sm={6} key={index}>
                  <ButtonHome
                    key={index}
                    image={image}
                    title={title}
                    description={description}
                    path={`${path}?title=${title}&description=${description}`}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      {bannerDialog ? (
        <dialog open className={styles.dialogContainer}>
          <div className={styles.bannerContainer}>
            <div className={styles.closeContainer}>
              <span className={styles.closeDot} onClick={() => closeDialog()}>
                <CloseIcon fontSize="small" />
              </span>
            </div>

            <img src={PromotionAsset} 
                onClick={() => closeDialog()}
                // onClick={() => {
                //     history.push("/live/singleclass?categoria=clubdoooo");
                //  }}
            />
            {/* <a
              onClick={() => {
                // window.location.href = "https://kidsa.com/curso/envie-seu-video/";
                history.push("/live/singleclass?categoria=clubdoooo");
                // Reserve agora!
              }}
              className={styles.link}
            >
              Reserve já!
            </a> */}
            <a onClick={() => closeDialog()} className={styles.linkClose}>
              Clique aqui para fechar.
            </a>
          </div>
        </dialog>
      ) : (
        <></>
      )}
    </>
  );
};
