import React from "react";
import { Provider } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { getValidUser } from "../redux/actions/utils";
import { createBrowserHistory } from "history";
import Div100vh from "react-div-100vh";

import Main from "../pages/Main";
import Home from "../pages/Home";
import Videos from "../pages/Videos";
import DirectVideo from "../pages/DirectVideo";
import Games from "../pages/Games";
import Video from "../pages/Video";
import Game from "../pages/Game";
import Book from "../pages/Book";
import Test from "../pages/Test";
import Login from "../pages/Login";
import RecoverPassword from "../pages/RecoverPassword";
import NotFound from "../pages/NotFound";
import FeriasNoClub from "../pages/FeriasNoClub";
import ClientApp from "../pages/ClientApp";
import KoinsExchange from "../pages/KoinsExchange";
import RecordedVideoPlayer from "../pages/RecordedVideoPlayer";
import Scratch from "../pages/Scratch";
import Youtube from "../pages/Youtube";

import LiveRoutes from "../pages/Live/routes";

import Header from "../components/Header";
import Footer from "../components/Footer";

import { load } from "../localStorage";
import KoinsExchangeGrid from "../components/KoinsExchange/KoinsExchangeGrid";

const privateRoutes = [
  {
    path: "/home",
    Component: <Home />,
  },
  {
    path: "/videos",
    Component: <Videos />,
  },
  {
    path: "/video/:id",
    Component: <Video />,
  },
  {
    path: "/directvideo/:id",
    Component: <DirectVideo />,
  },
  {
    path: "/scratch/:id",
    Component: <Scratch />,
  },
  {
    path: "/youtube/:id",
    Component: <Youtube />,
  },
  {
    path: "/classplayer/:id/:class_date",
    Component: <RecordedVideoPlayer />,
  },
  {
    path: "/games",
    Component: <Games />,
  },
  {
    path: "/game/:id",
    Component: <Game />,
  },
  {
    path: "/book/:id",
    Component: <Book />,
  },
  {
    path: "/test/:id",
    Component: <Test />,
  },
  {
    path: "/live",
    Component: <LiveRoutes />,
  },
  {
    path: "/koins",
    Component: <KoinsExchange />,
  },
  {
    path: "/clientapp",
    Component: <ClientApp />,
    // Component: <LiveRoutes />,
  },
  {
    path: "/feriasnoclub",
    Component: <Redirect to="/live/feriasnoclub" />,
  },
];

const history = createBrowserHistory();

const Root = ({ store }) => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Div100vh>
          <Route
            path="*"
            render={({ location }) => {
              const state = store.getState();
              const logged = getValidUser(state);
              return <Header logged={logged} pathname={location.pathname} />;
            }}
          />
          <Main>
            <Switch>
              <Route path="/recover-password">
                <RecoverPassword />
              </Route>
              <Switch>
                {privateRoutes.map((item, index) => {
                  return (
                    <PrivateRoute key={index} path={item.path} store={store}>
                      {item.Component}
                    </PrivateRoute>
                  );
                })}
                <Route exact path="/">
                  <Login />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Switch>
          </Main>
          <Footer />
        </Div100vh>
      </Router>
    </Provider>
  );
};

function PrivateRoute({ children, ...rest }) {
  const state = rest.store.getState();
  const logged = getValidUser(state);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        logged ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default Root;
