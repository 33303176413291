import React from "react";
import { makeStyles, IconButton, ButtonBase } from "@material-ui/core";
import { Info, LockOutlined } from "@material-ui/icons";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { Link } from "react-router-dom";
import { API_KEY } from "../Constants";
import useQuery from "../hook/useQuery";

import IconWatched from "../assets/watched.png";
import IconReserved from "../assets/reserved.png";
import IconHomeworkTest from "../assets/icon-homework-test.png";
import CoinChip from "./CoinChip";
import HomeworkCoinsChip from "./HomeworkCoinsChip";
import FullClassImage from "../assets/fullClass.png";
import MoneyImage from "../assets/money.png";


const diaDaSemana = (init_at_complete_format) => {
  const currentDate = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1); // adiciona um dia a data

  var semana = [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
  ];
  var separar = init_at_complete_format.split(" ");

  if(currentDate.toLocaleDateString('pt-BR') === separar[0]){
    return "Hoje";
  } else if(tomorrow.toLocaleDateString('pt-BR') === separar[0]) {
    return "Amanhã";
  }

  var data = separar[0].split("/");
  data = new Date(data[2], data[1] - 1, data[0]);
  return semana[data.getDay()];
};

const useStyles = (live) =>
  makeStyles({
    containerSingle: {
      position: "relative",
      margin: "0 5px",
      maxWidth: "260px",
      color: "white",
    },
    container: {
      position: "relative",
      margin: "0 5px",
      maxWidth: "260px",
      color: "white",
    },
    info: {
      position: "absolute",
      left: 0,
      top: 0,
      padding: 5,
      color: "black",
    },
    infoRight: {
      position: "absolute",
      right: 0,
      top: 0,
      padding: 5,
      color: "red",
    },
    link: {
      textDecoration: "none",
      display: "block",
    },
    image: {
      padding: "0 5px",
      height: 200,
      width: "100%",
      backgroundPosition: "center",
      backgroundColor: "#F1f1f1",
      backgroundSize: "cover",
      minHeight: "180px",
      minWidth: "260px",
    },
    testHomework: {
      height: 200,
      width: "100%",
      backgroundColor: "#FFD714",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    nameLiveCourse: {
      fontSize: "16px !important",
    },
    title: {
      color: "black",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 12,
    },
    titleSingle: {
      color: "black",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 16,
    },
    iconTestHomework: {
      position: "absolute",
      width: 80,
    },
    chip: {
      position: "absolute",
      right: 5,
      top: 5,
      width: 60,
    },
    button: {
      margin: "0 0 20px 0",
    },
    fullClass: {
      position: "absolute",
      right: 0,
      top: 2,
      width: 45,
    },
    fullClassIcon: {
      fontSize: "45px",
    },
  });

const Box = (props) => {
  const query = useQuery();
  const singleclass = query.get("singleclass");

  const {
    id,
    name,
    description,
    image,
    path,
    category_id,
    live,
    liveClass,
    reserved,
    calendar,
    reserve,
    homework,
    homework_id,
    vimeo_id,
    panda_id,
    coins,
    myreserve,
    novo,
    homeworkNumber,
    watched,
  } = props;

  let homeid = "";
  if (homeworkNumber) {
    homeid = `&homework=${homeworkNumber}`;
  }

  const styles = useStyles(live || liveClass || reserved)();

  const mostrarData = (init_at_complete_format, hora, reserved = true) => {
    if (hora != "") {
      let separaData = init_at_complete_format.split(" ");
      return `${separaData[0]} ${hora}`;
    } else {
      let separaData = init_at_complete_format.split(" ");
      return `${separaData[0]} ${separaData[1]}`;
    }
  };

  const renderChip = (
    reserved,
    watched,
    coins,
    hasCoinsHomework,
    renderNull,
    hasVacancy = true
  ) => {
    if (watched) {
      return (
        <img
          src={IconWatched}
          alt="Watched"
          className={styles.chip}
          color="primary"
        />
      );
    } else if (reserved) {
      return (
        <img
          src={IconReserved}
          alt="Reserved"
          className={styles.chip}
          color="primary"
        />
      );
    } else if (!hasVacancy) {
      return (
        <div className={styles.fullClass}>
          <img src={FullClassImage} width="40" height="40" />
        </div>
      );
    } else if (coins > 0) {
      return <CoinChip coins={coins} />;
    } else if (hasCoinsHomework !== false) {
      return <></>;
    }

    return null;
  };

  if (reserved && calendar != true) {
    const { live_course_class_date, allow_cancel } = props;
    const {
      id,
      count_reserves,
      number_participants,
      teacher,
      live_course_class,
      has_vacancy,
      reserved,
      watched,
      coins,
      init_at_format,
      init_at_complete_format,
      link,
      hora,
      single_class,
    } = props;

    const { name, image_thumb } = single_class;
    const currentImage = image_thumb[0].secure_url_image;

    return (
      <div className={styles.container}>
        <ButtonBase
          onClick={() => {
            if (reserved) {
              props.onOpenDialog({
                id: id,
                name: single_class.name,
                description: single_class.description,
                countReserves: count_reserves,
                numberParticipants: number_participants,
                reserved: reserved,
                watched: watched,
                teacher: teacher,
                initHour: init_at_format,
                allowCancel: allow_cancel,
                hasVacancy: has_vacancy,
                init_at_complete_format: init_at_complete_format,
                link: link,
              });
            }
          }}
          style={{ display: "block", width: "100%" }}
        >
          {renderChip(reserved, watched, false, false, true)}
          <div
            className={styles.image}
            style={{
              backgroundImage: "url(" + currentImage + ")",
            }}
          ></div>
          <h3 className={styles.titleSingle}>{` ${diaDaSemana(
            init_at_complete_format
          )} - ${mostrarData(init_at_complete_format, hora, true)}`}</h3>
        </ButtonBase>
        {/* {!reserved && (
          <SubmitSkinButton
            className={styles.button}
            fullWidth
            onClick={() => props.makeReserve(id)}
          >
            {!props.processing ? (
              'FAZER RESERVA'
            ) : (
              <CircularProgress size={20} color='secondary' />
            )}
          </SubmitSkinButton>
        )} */}
      </div>
    );
  }

  if (reserve) {
    const { live_course_class_date, allow_cancel, hora } = props;
    const {
      id,
      count_reserves,
      number_participants,
      teacher,
      live_course_class,
      has_vacancy,
      reserved,
      watched,
      init_at_format,
      init_at_complete_format,
      link,
    } = live_course_class_date;

    const { name, image_thumb } = live_course_class;
    const currentImage = image_thumb[0].secure_url_image;

    return (
      <div className={styles.container}>
        <ButtonBase
          onClick={() => {
            if (reserved) {
              props.onOpenDialog({
                id: id,
                name: live_course_class.name,
                description: live_course_class.description,
                countReserves: count_reserves,
                numberParticipants: number_participants,
                reserved: reserved,
                watched: watched,
                teacher: teacher,
                initHour: init_at_format,
                allowCancel: allow_cancel,
                hasVacancy: has_vacancy,
                init_at_complete_format: init_at_complete_format,
                link: link,
              });
            }
          }}
          style={{ display: "block", width: "100%" }}
        >
          {renderChip(reserved, watched, false, false, true, has_vacancy)}
          <div
            className={styles.image}
            style={{
              backgroundImage: "url(" + currentImage + ")",
            }}
          ></div>
          <h3 className={styles.titleSingle}>{` ${diaDaSemana(
            init_at_complete_format
          )} - ${mostrarData(init_at_complete_format, hora, true)}`}</h3>
        </ButtonBase>
        {/* {!reserved && (
          <SubmitSkinButton
            className={styles.button}
            fullWidth
            onClick={() => props.makeReserve(id)}
          >
            {!props.processing ? (
              'FAZER RESERVA'
            ) : (
              <CircularProgress size={20} color='secondary' />
            )}
          </SubmitSkinButton>
        )} */}
      </div>
    );
  }

  if (homework) {
    const { id, name, image_thumb, goToHomework, coins } = props;

    const currentImage = image_thumb[0].secure_url_image;

    return (
      <div className={styles.container}>
        <ButtonBase
          onClick={() => goToHomework(id)}
          style={{ display: "block", width: "100%" }}
        >
          {renderChip(false, false, coins, false, false)}
          <div
            className={styles.image}
            style={{
              backgroundImage: "url(" + currentImage + ")",
            }}
          ></div>
          <h3 className={styles.title}>
            {name} | {id}
          </h3>
        </ButtonBase>
      </div>
    );
  }

  if (liveClass) {
    const {
      id,
      count_reserves,
      number_participants,
      teacher,
      reserved,
      watched,
      init_at_format,
      allow_reserve,
      live_course_class,
      single_class,
      has_vacancy,
      link,
      hora,
      init_at_complete_format,
      credits,
    } = props;

    let className;
    let classdescription;

    const { name, image_thumb } = teacher;
    const describe = `${init_at_format} - ${name}`;

    let nameLiveCourse = null;
    let imageThumb;

    if (single_class) {
      className = single_class.name;
      classdescription = single_class.description;
      const { name, image_thumb, description } = single_class;
      nameLiveCourse = name;
      const [image] = image_thumb;
      imageThumb = image.secure_url_image;
    } else {
      imageThumb = image_thumb[1].secure_url_image;
    }

    const openDialog = () => {
      const dependencies = live_course_class
        ? live_course_class.dependencies.length > 0
          ? live_course_class.dependencies
          : null
        : null;

      props.onOpenDialog({
        id: id,
        name: live_course_class ? live_course_class.name : className,
        description: live_course_class
          ? live_course_class.description
          : classdescription,
        countReserves: count_reserves,
        numberParticipants: number_participants,
        reserved: reserved,
        watched: watched,
        teacher: teacher,
        image:
          allow_reserve || reserved
            ? teacher.image_thumb[1].secure_url_image
            : live_course_class.image_thumb[0].secure_url_image,
        initHour: init_at_format,
        dependencies: dependencies,
        allowReserve: allow_reserve,
        hasVacancy: has_vacancy,
        link: link,
        init_at_complete_format: init_at_complete_format,
        credits,
      });
    };

    return (
      <div className={styles.containerSingle}>
        <ButtonBase
          onClick={openDialog}
          style={{ display: "block", width: "100%" }}
        >
          {renderChip(reserved, watched, false, false, false, has_vacancy)}
          {imageThumb && (
            <div
              className={styles.image}
              style={{
                backgroundImage: "url(" + imageThumb + ")",
              }}
            ></div>
          )}
          {nameLiveCourse && (
            <h2 className={[styles.title, styles.nameLiveCourse].join(" ")}>
              {/* {nameLiveCourse} */}
            </h2>
          )}
          <h3 className={styles.titleSingle}>{` ${diaDaSemana(
            init_at_complete_format
          )} - ${mostrarData(init_at_complete_format, hora, true)}`}</h3>
        </ButtonBase>
        {!reserved && (
          <>
            <IconButton
              disableRipple
              className={styles.info}
              color="inherit"
              onClick={openDialog}
            >
              {allow_reserve ? <Info /> : <LockOutlined />}
            </IconButton>

            {credits > 0 && has_vacancy && (
              <IconButton
                disableRipple
                className={styles.infoRight}
                color="inherit"
                onClick={openDialog}
              >
                {/* <AttachMoneyIcon /> */}
                <img src={MoneyImage} width="18" />
              </IconButton>
            )}
          </>
        )}
      </div>
    );
  }

  if (live) {
    const {
      id,
      name,
      reserved,
      watched,
      available,
      dependencies,
      goToClass,
      coins,
    } = props;
    const currentImage = image[0].secure_url_image;
    return (
      <div className={styles.container}>
        <ButtonBase
          onClick={() => {
            if (available) {
              goToClass(id);
            } else {
              props.onOpenDialog({
                classId: id,
                name: name,
                description: description,
                image: currentImage,
                dependencies: dependencies,
                available: available,
              });
            }
          }}
          style={{ display: "block", width: "100%" }}
        >
          {renderChip(reserved, watched, false, false, true)}
          <div
            className={styles.image}
            style={{ backgroundImage: "url(" + currentImage + ")" }}
          ></div>
          <h3 className={styles.title}>{name}</h3>
        </ButtonBase>
        {name && description && (
          <IconButton
            disableRipple
            className={styles.info}
            color="inherit"
            onClick={() => {
              props.onOpenDialog({
                classId: id,
                name: name,
                description: description,
                image: currentImage,
                dependencies: dependencies,
                available: available,
              });
            }}
          >
            {available ? <Info /> : <LockOutlined />}
          </IconButton>
        )}
      </div>
    );
  }

  let params = "";
  if (category_id) {
    params += `${params.length === 0 ? "?" : "&"}category_id=${category_id}`;
  }
  if (homework_id) {
    params += `${params.length === 0 ? "?" : "&"}homework_id=${homework_id}`;
  }
  if (vimeo_id || panda_id) {
    params += `${
      params.length === 0 ? "?" : "&"
    }vimeo_id=${vimeo_id}&panda_id=${panda_id}`;
  }
  params += homeid;

  if (singleclass === "true") {
    params += "&singleclass=true";
  }

  const link = `/${path}/${id}${params}`;

  return (
    <div className={styles.containerSingle}>
      <Link to={link} className={styles.link}>
        {renderChip(false, watched, coins, false, false)}
        {path === "test" ? (
          <div className={styles.testHomework}>
            <img
              src={IconHomeworkTest}
              alt="HomeworkTest"
              className={styles.iconTestHomework}
              color="primary"
            />
          </div>
        ) : (
          <div
            className={styles.image}
            style={{ backgroundImage: "url(" + image + ")" }}
          ></div>
        )}
        <h3 className={styles.title}>{name}</h3>
      </Link>
      {name && description && (
        <IconButton
          className={styles.info}
          color="inherit"
          onClick={() => {
            props.onOpenDialog({
              name: name,
              description: description,
            });
          }}
        >
          <Info />
        </IconButton>
      )}
    </div>
  );
};

export default Box;
