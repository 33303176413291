import React from 'react';
import {makeStyles} from '@material-ui/styles'

import Coin from "../assets/coin.png";

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        right: 5,
        top: 5,
        width: 55,
        height: 55,
        backgroundImage: "url(" + Coin + ")",
        backgroundSize: "contain",
    },
    coin: {
      display: 'flex',
      justifyContent: 'center',
      alignItems : 'center',
      width: '100%',
      height: '100%',
      fontSize: 18,
      fontWeight: 'bold',
      color: 'rgba(0,0,0,0.8)',
    },
  });

export default ({ coins }) => {

    const classes = useStyles();
    return (
            <div className={classes.root}>
                <div className={classes.coin}>
                  {coins}
                </div>
            </div>
    );
}

