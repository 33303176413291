export default (state = false, action) => {
    switch(action.type){
        case 'REFRESH_COIN':
            return true;
        
        case 'REFRESHED_COIN':
            return false;
        
        default:
            return state;
    }
}