import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  CircularProgress,
  Link,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { userSignIn, userClear, userSignOut } from "../redux/actions";
import {
  getSubscription,
  getLoggedIn,
  getError,
  getAuthenticating,
} from "../redux/actions/utils";
import * as Yup from "yup";
import { withFormik } from "formik";
import md5 from "md5";
import { useLocation, useHistory, Link as L } from "react-router-dom";
import Message from "../ui/message";
import CustomTextField from "../styles/textfield";
import SubmitSkinButton from "../styles/submit-skin-button";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const SignIn = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    initialValues,
  } = props;

  const [messageSubscription, setMessageSubscription] = React.useState("");

  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    if (props.loggedIn) {
      resetForm(initialValues);

      if (props.hasSubscription) {
        history.push("/home");
      } else {
        setMessageSubscription(
          "Verifique a vigência do seu plano, entre em contato com nosso atendimento."
        );
        props.userSignOut();
      }
    }

    return () => {
      props.userClear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, props.loggedIn]);

  const onHandleFacebook = (response) => {
    const { accessToken, userID } = response;
    props.userSignIn({
      facebookAccessToken: accessToken,
      facebookUserId: userID,
    });
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <CustomTextField
            id="email"
            name="email"
            type="email"
            value={values.email}
            label="E-mail"
            variant="outlined"
            required
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            helperText={errors.email && touched.email && errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            sx={{ m: 1, width: "100%" }}
            style={{ width: "100%" }}
            variant="outlined"
          >
            <InputLabel
              htmlFor="outlined-password"
              style={
                errors.password && touched.password
                  ? { color: "red" }
                  : { color: "#d0d0d3" }
              }
            >
              Senha *
            </InputLabel>
            <OutlinedInput
              id="outlined-password-login"
              aria-describedby="component-helper-text"
              type={showPassword ? "text" : "password"}
              name="password"
              value={values.password}
              label="Senha *"
              variant="outlined"
              required
              color="info"
              style={{
                borderColor: "#d0d0d3",
              }}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password && touched.password}
              helperText={errors.password && touched.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password && touched.password && (
              <FormHelperText
                id="component-helper-text"
                style={{
                  marginTop: 3,
                  marginBottom: 3,
                  marginLeft: 6,
                  marginRight: 6,
                  color: "red",
                  fontFamily: "Arial",
                  fontSize: 8,
                }}
              >
                A senha é obrigatória
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Link
            component={L}
            underline="always"
            style={{
              textAlign: "center",
              display: "block",

              fontWeight: "bold",
            }}
            color="secondary"
            to="/recover-password"
          >
            Perdeu sua senha? Clique aqui!
          </Link>
        </Grid>
        <Grid item xs={12}>
          <SubmitSkinButton
            type="submit"
            fullWidth
            disabled={props.isAuthenticating}
          >
            {!props.isAuthenticating ? (
              "ENTRAR"
            ) : (
              <CircularProgress size={20} color="secondary" />
            )}
          </SubmitSkinButton>
        </Grid>
        {props.error.length > 0 && (
          <Grid item xs={12}>
            <Message error={true} text={props.error} />
          </Grid>
        )}
        {messageSubscription.length > 0 && (
          <Grid item xs={12}>
            <Message error={false} text={messageSubscription} />
            <Link
              underline="always"
              style={{
                textAlign: "center",
                display: "block",
                marginTop: 10,
                fontWeight: "bold",
              }}
              color="secondary"
              target="_blank"
              rel="noopener"
              href="https://www.pagenglish.kidsa.com"
            >
              Assinar o Kidsa English
            </Link>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

const validationSchema = Yup.object({
  email: Yup.string("Digite seu e-mail")
    .email("E-mail inválido")
    .required("E-mail é obrigatório"),
  password: Yup.string("Digite sua senha").required("Senha é obrigatória"),
});

const FormSignIn = withFormik({
  mapPropsToValues: () => ({
    email: "",
    password: "",
  }),
  validationSchema: validationSchema,
  handleSubmit: (values, { props }) => {
    props.userSignIn({ email: values.email, password: md5(values.password) });
  },
  displayName: "SignIn",
})(SignIn);

const mapStateToProps = (state) => ({
  hasSubscription: getSubscription(state),
  loggedIn: getLoggedIn(state),
  error: getError(state),
  isAuthenticating: getAuthenticating(state),
});

export default connect(mapStateToProps, { userSignIn, userClear, userSignOut })(
  FormSignIn
);
