export const SET_START = 'SET_START';
export const SET_DATA = 'SET_DATA';
export const SET_ERROR = 'SET_ERROR';

const dataReducer = (state, action) => {
  switch (action.type) {
    case SET_START:
      return {
        ...state,
        data: null,
        loading: true,
      };
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default dataReducer;
