import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "15px",
  },
  buttons: {
    display: "flex",
    columnGap: "15px",
  },
});

export default () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.container}>
      <h1>QUER SER ASSINANTE KIDSA LIVE E TER ACESSO AS AULAS AO VIVO</h1>

      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() =>
            window.open("https://kidsa.com/curso/organico/", "_blank")
          }
        >
          Clique Aqui
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => history.push("/home")}
        >
          Voltar
        </Button>
      </div>
      <a href="#" onClick={() => window.location.reload()}>
        <small>JÁ É ASSINANTE DO KIDSA LIVE? CLIQUE AQUI</small>
      </a>
    </div>
  );
};
