import React from 'react';
import { 
  Typography 
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledTypography = withStyles({
  root: {
    fontSize: 14,
    display: 'block',
    marginTop: 30,
    fontWeight: 900,
    textAlign: 'center'
  }
})(Typography);

export default props => {
  const { error, text } = props

  return (
    <StyledTypography color={error ? 'error' : 'secondary'}>{text}</StyledTypography>
  )
}