import React from "react";
import { makeStyles } from "@material-ui/core";

import BackButton from "./BackButton";

const useStyles = (color = "white", full = false) =>
  makeStyles({
    fullscreen: {
      position: "absolute",
      top: full ? 5 : 130,
      right: 5,
      color: color,
      zIndex: 10000,
    },
    fullscreenMobile: {
      position: "absolute",
      top: 5,
      right: 5,
      color: color,
      zIndex: 10000,
    },
  });

export default ({ color, fullscreen, onClick }) => {
  const styles = useStyles(color, fullscreen)();
  return (
    <div className={fullscreen ? styles.fullscreen : styles.fullscreenMobile}>
      <BackButton onClick={onClick} />
    </div>
  );
};
