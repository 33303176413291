import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { useDispatch } from "react-redux";
import useFetch from "../../hook/useFetch";
import { load } from "../../localStorage";
import { makeStyles } from "@material-ui/core/styles";
import ProfileContainer from "./ProfilePages/ProfileContainer";
import Loading from "../../components/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useQuery from "../../hook/useQuery";
import Auth from "../../client/api";
import { API_KEY } from "../../Constants";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "150px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    marginBottom: 50,
  },
  profile: {
    width: "60%",
    height: "400px",
    display: "flex",
    flexDirection: "row",
  },
  profileTabs: {
    width: "30%",
    maxWidth: "300px",
    minWidth: "200px",
  },
  profileContent: {
    paddingLeft: "10px",

    flex: 1,
  },
  menuTabs: {
    textAlign: "left",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: "100%",
  },
}));

function getChildLive(listChildren) {
  const atLeastOneChildLive = listChildren.filter(
    (item) => item.has_live_course === true
  );

  return atLeastOneChildLive.length > 0 ? atLeastOneChildLive[0] : null;
}

function ChildrenProfile() {
  const classes = useStyles();
  const query = useQuery();
  var selectedMenu = query.get("menu");
  const [menuValue, setMenuValue] = useState("account_information");
  const { profile } = load("user");
  const { children } = profile;
  const token = profile.user.auth_token;
  const childrenLive = children.filter((item) => item.has_live_course)[0];

  const fetchReport = async () => {
    let dataFim = new Date();
    let dataInicio = new Date();
    dataInicio.setDate(dataFim.getDate() - 30);
    const customer_id = profile.id;

    const response = await Auth.get(
      `/creditpackage/credit-history?date_begin=${dataInicio.toLocaleDateString(
        "pt-BR"
      )}
      &date_end=${dataFim.toLocaleDateString("pt-BR")}
      &customer_id=${customer_id}
      &apikey=${API_KEY}
      `
    );

    if (response.data.result.length === 0) {
      alert("Nenhum registro encontrado");
      return;
    }

    const aluno = response.data.result[0].customer;
    const header = [
      "Data Movimento",
      "Operação",
      "Crédito",
      "Observação",
      "Aula",
      "Assistida",
    ];
    const result = response.data.result.map((el) => {
      return [
        el.data,
        el.operacao,
        Math.abs(el.creditos),
        el.observacao,
        el.aula,
        el.assistida,
      ];
    });
    console.log(result);
    const exportData = [["Aluno", aluno], header, ...result];
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);
    const workbook = {
      Sheets: { Histórico: worksheet },
      SheetNames: ["Histórico"],
    };
    XLSX.writeFile(workbook, "historico_creditos.xlsx");
  };

  const dispatch = useDispatch();
  const notify = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

  function handleChange(event, newValue) {
    setMenuValue(newValue);
  }

  const state = useFetch(`profile/${childrenLive.id}`, null, token);
  if (!state.loading) {
    dispatch({
      type: "SET_PROFILE",
      payload: state.data,
    });
  }

  useEffect(() => {
    const notification = localStorage.getItem("notification");
    if (notification) {
      localStorage.removeItem("notification");
      // notify("Sucesso em salvar as informações do perfil.");
      notify(notification);
    }
    setMenuValue(selectedMenu);
  }, [selectedMenu]);

  if (state.loading) return <Loading />;
  else
    return (
      <div className={classes.root}>
        <h1 className={classes.title}>Perfil</h1>
        <div className={classes.profile}>
          <div className={classes.profileTabs}>
            <div>
              <Tabs
                orientation="vertical"
                value={menuValue}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                indicatorColor="primary"
                className={classes.tabs}
              >
                <Tab
                  label="Informações de Login"
                  className={classes.menuTabs}
                  value={"account_information"}
                />
                <Tab
                  label="Informações do Aluno(a)"
                  className={classes.menuTabs}
                  value={"basic_information"}
                />
                <Tab
                  label="Informações do Responsável"
                  className={classes.menuTabs}
                  value={"address_information"}
                />
                {/* <Tab
                  label="Informações do Curso"
                  className={classes.menuTabs}  //Ocultado para correção das falhas encontradas.
                  value={"course_information"}
                /> */}
                <Tab
                  label="Mural de Avisos"
                  className={classes.menuTabs}
                  value={"notice_wall"}
                  disabled={
                    typeof childrenLive === "undefined" || childrenLive == null
                      ? true
                      : false
                  }
                />

                <Tab
                  label="Extrato de Créditos"
                  className={classes.menuTabs}
                  onClick={fetchReport}
                />
              </Tabs>
            </div>
          </div>
          <div className={classes.profileContent}>
            <ProfileContainer menuValue={menuValue} />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
}

export default ChildrenProfile;
