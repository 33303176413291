import React, { useState, useEffect } from "react";
import SubmitSkinButton from "../styles/submit-skin-button";
import client from "../client/api";
import { API_KEY, BASE_URL } from "../Constants";
import { CircularProgress } from "@material-ui/core";
import axios from 'axios';

const LogInClassButton = (
  reserved,
  styles,
  time,
  link,
  recordedclass,
  recorded_class_video,
  single_class = false,
  onClose,
  init_at_complete_format,
  id 
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [buttonText, setButtonText] = useState(false);
  const [debugMessage, setDebugMessage] = useState("");
  const [messageToStudent, setMessageToStudent] = useState("");
  const [classLink, setClassLink] = useState("");


  async function verifyTimeClass() {
    setIsLoading(true);
    var url = `/singleclassdate/allowentry/${time.id}?apikey=${API_KEY}`;
    if (single_class !== true) {
      url = `/livecourseclassdate/allowentry/${time.id}?apikey=${API_KEY}`;
    }
    const response = await client.post(url);

    if (response.data.meta.code !== 200) {
      alert(response.data.error);
      setIsLoading(false);
      return;
    } else {
      window.open(response.data.result, "_blank");
      setIsLoading(false);
      return;
    }
  }

  const inTimeForClass = async (dateStr1) => {
    setClassLink(link)
    // setShowButton(true);
    // return;

    try {

      const response = await fetch(`${BASE_URL}/time/iso?apikey=${API_KEY}`);
      if (!response.ok) {
        throw new Error(`Erro ao buscar a data atual. Status: ${response.status}`);
      }
      const dataString2 = await response.json();

      // const dataString2 = "2023-12-10T23:49:10.552Z";

      const partes = dateStr1.split(" ");
      const dataPartes = partes[0].split("/");
      const horaPartes = partes[1].split("h");
      const dataString1 = `${dataPartes[2]}-${dataPartes[1]}-${dataPartes[0]}T${horaPartes[0]}:${horaPartes[1]}:00.000-03:00`;

      const data1 = new Date(dataString1);
      const data2 = new Date(dataString2);

      const dezMinutosEmMilissegundos = 10 * 60 * 1000;
      // console.log("inTimeForClass",data2.getTime() >= data1.getTime() - dezMinutosEmMilissegundos);

      return (data2.getTime() >= data1.getTime() - dezMinutosEmMilissegundos);

    } catch (error) {
      console.error('Erro na função inTimeForClass:', error.message);
      setShowButton(true);
      return true;
    }
  };

  const getClassLink = async () => {

    const userString = localStorage.getItem('user');
    if (!userString) {
      console.error('Chave "user" não encontrada no localStorage.');
      return null;
    }

    const user = JSON.parse(userString);

    if (!user || !user.profile || !user.profile.children) {
      console.error('Estrutura de dados inválida em "user".');
      return null;
    }

    const childrenList = user.profile.children;

    const child = childrenList.find((item) => item.has_live_course === true);
    const profileData = JSON.parse(localStorage.getItem("user"));

    const headers = {
      headers: {
        Authorization: `Bearer ${profileData.profile.user.auth_token}`,
      },
    };

    const body = {
      userName: child.first_name.trim(),
      classType: single_class ? 'singleClassDate' : 'liveCourseClassDate',
      classId: id,
    }

    await axios.post(`${BASE_URL}/conference/generateAttendeeLink`, body, headers)
    .then(({data}) => {
      if(!data.teacherInClass) {
        setMessageToStudent(<span>Por favor, clique em FECHAR e volte mais próximo do horário da aula para entrar na sala.<br/>O seu professor ainda está finalizando a aula anterior e arrumando a sala para você entrar.</span>)
        setShowButton(false)
      }else {
        console.log("data",data.link);
        setClassLink(data.link);
        console.log(classLink);

        setShowButton(true)
      }
    }
    )
    .catch((error) => {
      console.log(error);
    })
  };

  const verifyTime = async () => {
    const inTime = await inTimeForClass(init_at_complete_format);
    if(!inTime) {
      setShowButton(false);
      setMessageToStudent(<span>Volte quando faltar menos de 10 minutos<br/>para entrar na sala.</span>)
    } else if((inTime && link.includes("BBB") && !recordedclass) || (inTime && link === "" && !recordedclass))  {
      await getClassLink();
    } else {
      setShowButton(true);
    }
    setLoaded(true);
  }

  useEffect(() => {
    
    verifyTime();

    const intervalId = setInterval(() => verifyTime(), 15 * 1000);
    
    const text = (single_class === true && reserved === true) ? "Aula reservada" : "Fechar";
    setButtonText(text);

    return () => {
      clearInterval(intervalId);
    }

  }, [])

  if (
    (reserved == true &&
      recordedclass === false &&
      recorded_class_video === null) ||
    (single_class === true && reserved === true)
  ) {
    return (
      <>
        {
          !loaded ? <h2 style={{textAlign: "center",}}>Carregando...</h2> :
            (showButton ?
              <SubmitSkinButton
                fullWidth
                className={styles.button}
                onClick={async () => {
                  window.open(classLink, "_blank");
                  setIsLoading(true);
                  if(single_class) {
                    try {
                      const url = `/singleclassdate/giveclubcoins/${time.id}?apikey=${API_KEY}`;
                      await client.post(url);
                    } catch (error) {
                      console.log(error);
                    }
                  }

                  setIsLoading(false);
                }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Entrar na Sala"}
              </SubmitSkinButton>
            :
              <>
                <SubmitSkinButton
                  fullWidth
                  className={styles.closeButton}
                  onClick={() => {
                    onClose();
                  }}
                >
                  {isLoading ? <CircularProgress size={24} /> : buttonText}
                </SubmitSkinButton>
                <p style={{textAlign: "center", marginBottom: "16px",marginTop: "16px"}}>{messageToStudent}</p>
                <p style={{textAlign: "center", marginBottom: "16px"}}>
                  {debugMessage}
                </p>
              </>
            )
        }
      </>
    );
  }
};

export default LogInClassButton;
