import { useEffect, useReducer } from 'react';
import axios from 'axios';
import dataReducer, { SET_START, SET_DATA, SET_ERROR } from './dataReducer';
import { addAuthorization } from './utils';
import { BASE_URL, API_KEY } from '../Constants';

const useFetch = (endPoint, params, token, reload) => {
  const [state, dispatch] = useReducer(dataReducer, {
    loading: true,
    data: null,
    error: null,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    const url = `${BASE_URL}/${endPoint}`; // ?apikey=${API_KEY}

    const loadData = async () => {
      try {
        const response = await axios({
          url: url,
          method: 'GET',
          params: {
            apikey: API_KEY,
          },
          data: params,
          headers: addAuthorization(token),
          cancelToken: source.token,
        });
        // console.log('response : ', response);
        dispatch({
          type: SET_DATA,
          payload: response.data.result,
        });
        
      } catch (error) {
        if (axios.isCancel(error)) {
         
        } else {
          dispatch({ type: SET_ERROR, payload: error });
          // throw error;
        }
      }
    };

    dispatch({
      type: SET_START,
    });
    loadData();

    return () => source.cancel(`${endPoint} canceled by the user.`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endPoint, reload]);

  return state;
};

export default useFetch;
