export const SET_LOADING = 'SET_LOADING';
export const SET_VIDEO = 'SET_VIDEO';
export const SET_FILES = 'SET_FILES';
export const SET_ERROR = 'SET_ERROR';

const videoReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_VIDEO:
      return {
        ...state,
        video: action.payload,
        files: null,
      };
    case SET_FILES:
      return {
        ...state,
        files: action.payload,
        loading: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default videoReducer;
