import React, { useState } from "react";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useSelector } from "react-redux";
import client from "../../../client/api";
import { API_KEY } from "../../../Constants";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../components/Loading";
import { load } from "../../../localStorage";

const formatDateToInput = (date) => {
  date = date.split("/");
  return `${date[2]}-${date[1]}-${date[0]}`;
};

const formatDateToRequest = (date) => {
  date = moment(date).format("DD/MM/YYYY");
  return date;
};

const useStyles = makeStyles(() => ({
  inputField: {
    marginBottom: 12,
  },
  submitButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  dualField: {
    display: "flex",
    columnGap: "10px",
  },
  f1: { flex: 1 },
}));

const validationSchema = yup.object({
  first_name: yup
    .string("Digite seu primeiro nome.")
    .required("O Primeiro Nome é um campo requerido."),
  last_name: yup
    .string("Digite seu último nome.")
    .required("O Último Nome é um campo requerido."),
  password: yup
    .string("Digite sua nova senha")
    .min(6, "A senha deverá ter no minímo 6 caracteres."),
});

function getChildLive(listChildren) {
  const atLeastOneChildLive = listChildren.filter(
    (item) => item.has_live_course === true
  );

  return atLeastOneChildLive.length > 0 ? atLeastOneChildLive[0] : null;
}

function BasicInformation() {
  const [loading, setLoading] = useState(false);
  const profileData = useSelector((state) => state.profile);
  const { profile } = load("user");
  const children = getChildLive(profile.children);

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      gender: profileData.gender_type,
      birthDate: formatDateToInput(profileData.birthday),
      phone: profileData.phone,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const response = await client.put(
        `/profile/${children.id}?apikey=${API_KEY}`,
        {
          first_name: values.first_name,
          last_name: values.last_name,
          gender_type: values.gender,
          birthday: formatDateToRequest(values.birthDate),
        }
      );
      if (response.status !== 200) {
        toast.error("Falha ao Atualizar dados, por favor tente novamente.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      } else {
        localStorage.setItem(
          "notification",
          "Sucesso em salvar as informações do perfil."
        );
        window.location.reload();
      }
    },
  });
  if (profileData === {}) return <></>;
  if (loading) return <Loading />;
  else
    return (
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.dualField}>
            <TextField
              id="first_name"
              name="first_name"
              fullWidth
              label="Primeiro Nome"
              variant="outlined"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              className={classes.inputField}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />

            <TextField
              id="last_name"
              name="last_name"
              fullWidth
              label="Último Nome"
              variant="outlined"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              className={classes.inputField}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </div>

          <div className={classes.dualField}>
            <FormControl variant="outlined" className={[classes.f1]}>
              <InputLabel>Sexo</InputLabel>
              <Select
                id="gender"
                name="gender"
                label="Sexo"
                variant="outlined"
                value={formik.values.gender}
                onChange={formik.handleChange}
                className={[classes.inputField, classes.f1]}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
              >
                <MenuItem value="1">Masculino</MenuItem>
                <MenuItem value="2">Feminino</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="birthDate"
              name="birthDate"
              label="Data de Nascimento"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.birthDate}
              onChange={formik.handleChange}
              className={[classes.inputField, classes.f1]}
              error={
                formik.touched.birthDate && Boolean(formik.errors.birthDate)
              }
              helperText={formik.touched.birthDate && formik.errors.birthDate}
            />
          </div>

          <div className={classes.submitButton}>
            <Button color="primary" variant="contained" type="submit">
              Salvar
            </Button>
          </div>
        </form>
      </div>
    );
}

export default BasicInformation;
