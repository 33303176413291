import React from "react";
import { Grid } from "@material-ui/core";
import GridAutoFlow from "../GridAutoFlow";
import BoxContent from "../BoxContent";
import Subtitle from "../../ui/subtitle";
import Thumb_videos from "../../assets/thumb_kidsa_videos.png";

export default ({ videoHomework }) => {
  return (
    <Grid item xs={11}>
      {videoHomework.length !== 0 && <Subtitle>Videos</Subtitle>}
      <GridAutoFlow>
        {videoHomework.map((value, index) => {
          if (value.length === 0) return null;
          const { image_thumb, name, id, vimeo_id, panda_id } =
            value.object_related;
          const { coins } = value;
          const image =
            image_thumb.length === 0
              ? Thumb_videos
              : image_thumb[0].secure_url_image;

          return (
            <BoxContent
              {...value}
              coins={coins}
              key={index}
              image={image}
              name={name}
              path="directvideo"
              id={id}
              category_id={0}
              homework_id={value.homework_id}
              vimeo_id={vimeo_id}
              panda_id={panda_id}
              homeworkNumber={value.id}
              watched={value.completed}
            />
          );
        })}
      </GridAutoFlow>
    </Grid>
  );
};
