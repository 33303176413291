import React from "react";
import {Grid} from "@material-ui/core";
import GridAutoFlow from "../GridAutoFlow";
import BoxContent from "../BoxContent";
import Subtitle from "../../ui/subtitle";

export default ({booksHomework}) => {
  return (
    <Grid item xs={11}>
      {booksHomework.length !== 0 && <Subtitle>Book</Subtitle>}
      <GridAutoFlow>
        {booksHomework.map((value, index) => {
          if (value.length === 0) return null;
          const { name, image_thumb } = value.object_related;
          const image = image_thumb[0].secure_url_image;
          return (
            <BoxContent
              {...value}
              key={index}
              image={image}
              name={name}
              path="book"
              id={value.id}
              category_id={4}
              watched={value.completed}
            />
          );
        })}
      </GridAutoFlow>
    </Grid>
  );
};
