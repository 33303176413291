import { Paper } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',    
    borderRadius: 6,
    boxShadow: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    // display: 'flex',
  },
}))(Paper)