import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import useFetch from "../hook/useFetch";
import { load } from "../localStorage";
import Loading from "../components/Loading";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 180,
    marginBottom: 80,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 220,
      marginTop: 75,
      paddingBottom: 130,
    },
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default (Component, endPoint, key) => {
  const Mount = (props) => {
    const styles = useStyles();

    const token = load("user").profile.user.auth_token;
    const state = useFetch(endPoint, null, token);

    let query = useQuery();

    if (state.loading) return <Loading />;
    if (state.data === null) return <p>Erro ao tentar carregar o conteúdo</p>;

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={styles.content}
      >
        <Component
          content={state.data}
          title={query.get("title")}
          description={query.get("description")}
          {...props}
        />
      </Grid>
    );
  };

  return Mount;
};
