import React from "react";
import { makeStyles } from "@material-ui/core";

import Koin from "../../assets/koins-exchange.png";
import Teste from "../../assets/teste-ilutracao.png";

const useStyles = makeStyles({
  container: {
    width: 320,
    height: 300,
    backgroundColor: "#FFF69B",
    borderRadius: 8,
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 10,

    "&:hover": {
      opacity: 0.7,
    },
  },
  heading: {
    fontFamily: "Arial Black",
    textAlign: "center",
    fontSize: "1.8em",
  },
  subtitle: {
    fontFamily: "Arial Black",
    textAlign: "center",
    fontSize: "1.1em",
    color: "red",
  },
  ilustracaoContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  ilutration: {
    flex: 1,
    maxWidth: 165,
  },
  koinsContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    columnGap: 10,
  },
  koinsIustration: {
    width: 50,
  },
  koinsValor: {
    fontSize: "1.8em",
    color: "#8E8100",
    fontWeight: 500,
    textAlign: "justify",
  },
});

const is_enabled = (item, koins) => {
  var value = null;
  if (item.minValue !== 0) {
    value = item.minValue;
  } else {
    value = item.value;
  }

  return parseInt(koins) >= value;
};

function KoinsExchangeItem({ handleOpen, item, koins }) {
  var enabled = is_enabled(item, koins);

  const classes = useStyles();
  return (
    <div className={classes.container} onClick={() => handleOpen(item)}>
      <div className={classes.heading}>{item.title}</div>
      {item.subtitle != null && item.subtitle != "null" ? (
        <div className={classes.subtitle}>{item.subtitle}</div>
      ) : (
        ""
      )}

      <figure className={classes.ilustracaoContainer}>
        <img
          src={item.cover_image}
          className={classes.ilutration}
          width={130}
        />
      </figure>
      <div className={classes.koinsContainer}>
        <figure>
          <img
            src={Koin}
            alt="ilutração do koin"
            className={classes.koinsIustration}
          />
        </figure>
        {item.min_value !== 0 ? (
          <div className={classes.koinsValor}>
            A partir de <br />
            {item.min_value} Koins
          </div>
        ) : (
          <div className={classes.koinsValor}>{item.value} Koins</div>
        )}
      </div>
    </div>
  );
}

export default KoinsExchangeItem;
