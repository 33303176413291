import React from "react";
import Slide from "../components/Slide";
import CustomDialog from "../ui/custom-dialog";
import mountContent from "../hoc/mountContent";
import HeaderPage from "../components/HeaderPage";
import Thumb_kidsa_play from "../assets/thumb_kidsa_play.png";
import { useMediaQuery } from "@material-ui/core";
const Videos = (props) => {
  const [info, setInfo] = React.useState(null);

  const onOpenDialog = (info) => {
    setInfo(info);
  };

  const onCloseDialog = () => {
    setInfo(null);
  };

  return (
    <>
      {props.title && props.description && (
        <HeaderPage title={props.title} description={props.description} />
      )}

      {props.content.map((value, index) => {
        if (value.items.length <= 0) return null;

        var newValues = value.items.map((item) => {
          const { id, video } = item;

          return {
            id: id,
            name: video.name,
            description: video.description,
            image_thumb:
              video.image_thumb.length === 0
                ? Thumb_kidsa_play
                : video.image_thumb[0].secure_url_image,
            coins: item.coins,
            watched: item.watched,
          };
        });

        return (
          <Slide
            key={index}
            title={value.name}
            values={newValues}
            path="video"
            category_id={value.id}
            onOpenDialog={onOpenDialog}
          />
        );
      })}
      {info && (
        <CustomDialog title={info.name} open={true} onClose={onCloseDialog}>
          {info.description}
        </CustomDialog>
      )}
    </>
  );
};

export default mountContent(Videos, "videocategory", 1);
