import React from "react";
import { Grid } from "@material-ui/core";
import GridAutoFlow from "../GridAutoFlow";
import BoxContent from "../BoxContent";
import Subtitle from "../../ui/subtitle";

export default ({ scratchHomework }) => {
  return (
    <Grid item xs={11}>
      {scratchHomework.length !== 0 && <Subtitle>Scratch</Subtitle>}
      <GridAutoFlow>
        {scratchHomework.map((value, index) => {
          if (value.length === 0) return null;

          const { image_thumb, name, id, password } = value.object_related;
          const { coins } = value;
          const image = image_thumb[0].secure_url_image;

          return (
            <BoxContent
              {...value}
              coins={coins}
              key={index}
              image={image}
              name={name}
              path="scratch"
              id={id}
              category_id={0}
              homework_id={value.homework_id}
              homeworkNumber={value.id}
              watched={value.completed}
            />
          );
        })}
      </GridAutoFlow>
    </Grid>
  );
};
