import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Link,
  Container,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Height } from "@material-ui/icons";

import PromotionAsset from "../../assets/promocional_banner.png";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 215,
    paddingBottom: 100,
  },

  promotionalPic: {
    maxWidth: "900px",
    width: "100%",
    maxHeight: "45vh",
  },
  cursor: {
    cursor: "pointer",
  },
}));

export default () => {
  const history = useHistory();
  const classes = useStyles();
  const breakHeight = useMediaQuery("(max-height: 780px)");
  const breakWidth = useMediaQuery("(max-width: 700px)");
  return (
    <div className={classes.root}>
      {/* <Link className={classes.cursor}>
        <Typography
          variant="h2"
          color="primary"
          onClick={() => history.push("/live/calendar")}
          className={classes.cursor}
        >
          Clique aqui para acessar a página inicial do Kidsa.
        </Typography>
      </Link> */}
      <figure
        onClick={() => history.push("/live/calendar")}
        style={{ cursor: "pointer" }}
      >
        <img src={PromotionAsset} className={classes.promotionalPic} />
      </figure>
      <Link className={classes.cursor}>
        <Typography
          variant="h1"
          color="primary"
          onClick={() => history.push("/live/calendar")}
          className={classes.cursor}
        >
          Clique aqui para acessar a página inicial.
        </Typography>
      </Link>
    </div>
  );
};
