import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { VideoCall } from "@material-ui/icons";
import Download from "../assets/background-download.png";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    fontWeight: 500,
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <div style={{ marginRight: "10px" }}>
      <Button
        variant="text"
        color="primary"
        className={classes.button}
        endIcon={<VideoCall />}
        onClick={() => {
          window.location.href = "https://kidsa.com/curso/envie-seu-video/";
        }}
      >
        Envie seu vídeo
      </Button>
    </div>
  );
};
