import React from "react";

import ClassReplacement from "./ClassReplacement";
import VideoHomework from "./VideoHomework";
import BooksHomework from "./BooksHomework";
import TestHomework from "./TestHomework";
import FilesHomework from "./FilesHomework";
import GameType from "./GameType";
import ScratchType from "./ScratchHomework";
import YoutubeHomework from "./YoutubeHomework";

export default ({ homeworks }) => {
  return (
    <>
      {Object.keys(homeworks).map((value, index) => {
        if (value === "class_replacement") {
          return (
            <ClassReplacement
              key={"ghc" + index}
              classReplacement={homeworks[value]}
            />
          );
        } else if (value === "video") {
          return (
            <VideoHomework
              key={"ghc" + index}
              videoHomework={homeworks[value]}
            />
          );
        } else if (value === "games") {
          return (
            <GameType
              key={"ghc" + index}
              games={organizeGameByType(homeworks.games)}
            />
          );
        } else if (value === "book") {
          return (
            <BooksHomework
              key={"ghc" + index}
              booksHomework={homeworks[value]}
            />
          );
        } else if (value === "test") {
          return (
            <TestHomework key={"ghc" + index} testHomework={homeworks[value]} />
          );
        } else if (value === "youtube") {
          return (
            <YoutubeHomework
              key={"y" + index}
              youtubeHomework={homeworks[value]}
            />
          );
        } else if (value === "scratch") {
          return (
            <ScratchType
              key={"si" + index}
              scratchHomework={homeworks[value]}
            />
          );
        } else if (value === "file") {
          return (
            <FilesHomework
              key={"ghc" + index}
              filesHomework={homeworks[value]}
            />
          );
        } else {
          if (process.env.NODE_ENV === "production") return <></>;
          else {
            console.log(
              "The Homework type: " +
                value +
                " is not recognized from FrontEnd."
            );
            return <></>;
          }
        }
      })}
    </>
  );
};

function organizeGameByType(games) {
  let gamesOrganized = {
    MEMORY_GAME: [],
    DRAG_DROP_GAME: [],
    LISTEN_AND_TAP_GAME: [],
    LISTEN_AND_FIND_GAME: [],
  };
  games.forEach((game) => {
    const type = game.object_related.type_item_game_category_name;
    try {
      gamesOrganized[type].push(game);
    } catch {
      if (process.env.NODE_ENV === "production") return;
      else {
        console.log(
          "The Homework Game: " + type + " is not recognized from FrontEnd."
        );
      }
    }
  });
  return gamesOrganized;
}
