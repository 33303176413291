import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GridAutoFlow from "../GridAutoFlow";
import Subtitle from "../../ui/subtitle";
import GetAppIcon from "@material-ui/icons/GetApp";

export const useStyles = makeStyles({
  root: {
    color: "#fff",
  },
});

export default ({ filesHomework }) => {
  const classes = useStyles();
  return (
    <Grid item xs={11}>
      {filesHomework.length !== 0 && (
        <Subtitle>Atividade para Imprimir</Subtitle>
      )}
      <GridAutoFlow>
        {filesHomework.map((value, index) => {
          if (value.length === 0) return null;
          const { name, secure_url_file } = value.object_related;
          return (
            <a href={secure_url_file} style={{ textDecoration: "none" }}>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#006AFF",
                  margin: "8px",
                  padding: "5px 10px 5px 10px",
                  borderRadius: "8px",
                  justifyContent: "space-between",
                  border: "1px solid rgba(0,0,0,0.1)",
                  columnGap: "10px",
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75);",
                  cursor: "pointer",
                  height: "150px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <GetAppIcon color="primary" className={classes.root} />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    fontSize: "16px",
                    color: "#fff",
                  }}
                >
                  {name}
                </div>
              </div>
            </a>

            // <a href={secure_url_file} style={{textDecoration: 'none', color: 'black'}}>
            //   <h3>
            //     {name} | {id}
            //   </h3>
            // </a>
          );
        })}
      </GridAutoFlow>
    </Grid>
  );
};
