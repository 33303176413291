import React, { Component } from 'react';
import ReactPlayer from 'react-player';

class PandaPlayer extends Component {
  static canPlay(url) {
    const isPandaVideoDomain = url.includes('pandavideo.com.br');
    const isPandaVideoEmbedUrl = url.includes('embed/?v=');
    return isPandaVideoDomain && isPandaVideoEmbedUrl;
  }

  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
  }

  mounted = false
  isReady = false
  isPlaying = false 
  isLoading = true 
  loadOnReady = null
  startOnPlay = true
  seekOnPlay = null
  onDurationCalled = false

  handleMessageEvent = (event) => {
    const { data } = event;
    if (data.message === 'panda_ended') {
      this.props.onEnded();
    }
    if (data.message === 'panda_ready') {
      const iframe = document.getElementById('panda-player').contentWindow;
      iframe.postMessage({ type: 'play' }, '*');
      this.handleReady();
    }
  };

  componentDidMount() {
    const { url, onEnded } = this.props;
    this.mounted = true;

    if (PandaPlayer.canPlay(url)) {

      window.addEventListener('message', this.handleMessageEvent, false);

      const onPandaPlayerApiLoad = () => {
        const player = new PandaPlayer('panda-player', {
            onReady: () => {
            } 
          })
          this.playerRef.current = player;
      };

      if (window.onPandaPlayerApiLoad) {
        onPandaPlayerApiLoad();
      } else {
        window.onPandaPlayerApiLoad = onPandaPlayerApiLoad;
        const script = document.createElement('script');
        script.src = 'https://player.pandavideo.com.br/api.js';
        script.async = true;
        document.body.appendChild(script);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessageEvent, false);
    clearTimeout(this.progressTimeout);
    clearTimeout(this.durationCheckTimeout);
    this.mounted = false;
  
   
    const player = this.playerRef.current;
    if (player) {
      this.playerRef.current = null; 
    }
    
    const iframe = document.getElementById('panda-player')
    iframe.remove()
    if (window.onPandaPlayerApiLoad) {
      delete window.onPandaPlayerApiLoad;
      const script = document.querySelector('script[src="https://player.pandavideo.com.br/api.js"]');
      if (script) {
        script.remove();
      }
    }
  }
  
  componentDidUpdate = (prevProps) => {
    clearTimeout(this.progressTimeout)
    clearTimeout(this.durationCheckTimeout)
    if (this.isReady && this.props.stopOnUnmount) {
      this.player.stop()

      if (this.player.disablePIP) {
        this.player.disablePIP()
      }
    }
    this.mounted = false
  }
  
  handlePlayerMount = (player) => {
    return;
  }
  
  getDuration = () => {
    return null;
  }
  
  getCurrentTime = () => {
    return null;
  }
  
  getSecondsLoaded = () => {
    return null;
  }
  
  getInternalPlayer = (key) => {
    return null;
  }
  
  progress = () => {
    return;
  }
  
  seekTo = (amount, type, keepPlaying = false) => {
    return;
  }
  
  handleReady = () => {
    const { changePlayer } = this.props;
    changePlayer(false);
    return;
  }
  
  handlePlay = () => {
    const iframe = document.getElementById('panda-player').contentWindow;
    iframe.postMessage({ type: 'play' }, '*');
  }
  
  handlePause = (e) => {
    return;
  }
  
  handleEnded = () => {
    return;
  }
  
  handleError = (...args) => {
    return;
  }
  
  handleDurationCheck = () => {
    return;
  }
  
  handleLoaded = () => {
    return;
  }

  load = () => {

  }

  render() {
    const { className, url, pandaControls } = this.props;

    if (PandaPlayer.canPlay(url)) {
      return (
        <iframe
          id="panda-player"
          ref={this.playerRef}
          className={className}
          src={url+`&playOpensFullscreen=true&controls=${pandaControls}`}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
          allowFullScreen
          title="Panda Player"
        />
      );
    }

    return (
      <ReactPlayer
        url={url}
        playing
        controls
        onEnded={this.props.onEnded}
        className={className}
      />
    );
  }
}

export default PandaPlayer;

