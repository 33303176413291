import React from "react";

import { Grid, useMediaQuery } from "@material-ui/core";
import HeaderPage from "../../components/HeaderPage";
import { load } from "../../localStorage";
import { useParams } from "react-router-dom";
import useFetch from "../../hook/useFetch";
import useQuery from "../../hook/useQuery";
import Loading from "../../components/Loading";

import GridHomeworkContent from "../../components/GridHomeworkContent";

export default () => {
  const { profile } = load("user");
  const id = useParams();
  const token = profile.user.auth_token;
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];
  const query = useQuery();
  const singleclass = query.get("singleclass");
  const mobile = useMediaQuery("(max-width:960px)");
  const state = useFetch(
    singleclass === "true"
      ? `singleclass/homeworks/detail/${id.id}?profile_id=${childrenLive.id}`
      : `livecourse/homeworks/detail/${id.id}?profile_id=${childrenLive.id}`,
    null,
    token
  );

  if (state.loading) return <Loading />;
  if (state.data === null) return <p>Erro ao tentar carregar o conteúdo</p>;

  const getHomeworksByType = (type_name) => {
    var homeworks = state.data
      .map((homework) => {
        if (homework.type_homework_name === type_name) {
          homeworks = homework;
          homeworks.homework_id = parseInt(id.id);
          return homeworks;
        }
      })
      .filter((d) => d !== undefined);
    return homeworks;
  };

  const homeworks = {
    class_replacement: getHomeworksByType("CLASS_REPLACEMENT"),
    file: getHomeworksByType("FILE"),
    video: getHomeworksByType("VIDEO"),
    youtube: getHomeworksByType("YOUTUBE"),
    scratch: getHomeworksByType("SCRATCH"),
    games: getHomeworksByType("GAME_CATEGORY"),
    book: getHomeworksByType("BOOK"),
    test: getHomeworksByType("TEST"),
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={
        mobile
          ? { paddingTop: 280, paddingBottom: 60 }
          : { paddingTop: 150, paddingBottom: 60 }
      }
    >
      <HeaderPage title="Detalhes das Atividades" description="" />
      <GridHomeworkContent homeworks={homeworks} />
    </Grid>
  );
};
