import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";

export default ({ options, children, handleClick, handleClose, anchorEl }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        disableRipple
        disableFocusRipple
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((item, index) => {
          if (item.to && item.to.length > 1) {
            return (
              <MenuItem
                key={index}
                component={NavLink}
                to={item.to}
                onClick={handleClose}
                activeClassName="Mui-selected"
                exact
              >
                {item.label}
              </MenuItem>
            );
          }

          return (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                window.open(item.href, "_blank");
              }}
            >
              {item.label}
            </MenuItem>
          );
        })}
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          {children}
        </MenuItem>
      </Menu>
    </div>
  );
};
