import React from "react";

import Loading from "../../components/Loading";
import HeaderPage from "../../components/HeaderPage";
import { load } from "../../localStorage";
import useFetch from "../../hook/useFetch";
import { Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ClassSliderContent from "../../components/ClassSliderContent";
import { FullscreenExit } from "@material-ui/icons";

export default () => {
  const { profile } = load("user");
  const token = profile.user.auth_token;
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];

  const [fetchData, setFetchData] = React.useState(0);

  const state = useFetch(
    `singleclassdate/status/0?profile_id=${childrenLive.id}`,
    null,
    token,
    fetchData
  );

  const stateWatched = useFetch(
    `singleclassdate/status/1?profile_id=${childrenLive.id}`,
    null,
    token,
    fetchData
  );

  const stateNotWatch = useFetch(
    `singleclassdate/status/2?profile_id=${childrenLive.id}`,
    null,
    token,
    fetchData
  );

  if (state.loading) return <Loading />;
  if (state.data === null) return <p>Erro ao tentar carregar o conteúdo</p>;

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ paddingTop: 180, paddingBottom: 160 }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <HeaderPage title="Minhas Reservas" />
          <div
            style={{
              color: "black",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Aviso : Todos as aulas estão configuradas no HORÁRIO DE BRASÍLIA.
          </div>
        </div>

        {state.data === null || state.data.length === 0 ? (
          ""
        ) : (
          <ClassSliderContent
            title="Aulas"
            allowGetInClassButton={true}
            allowCancel={true}
            classContent={state}
            profile={profile}
            single={true}
            singleclass={true}
          />
        )}

        {stateWatched.data === null || stateWatched.data.length === 0 ? (
          ""
        ) : (
          <ClassSliderContent
            title="Assistidas"
            allowGetInClassButton={false}
            allowCancel={false}
            classContent={stateWatched}
            profile={profile}
            statusChip="watched"
            watched={true}
            singleHomework={true}
          />
        )}

        {stateNotWatch.data === null || stateNotWatch.data.length === 0 ? (
          ""
        ) : (
          <ClassSliderContent
            title="Não Assistidas "
            allowGetInClassButton={false}
            allowCancel={false}
            classContent={stateNotWatch}
            profile={profile}
            watched={true}
            singleHomework={true}
          />
        )}
      </Grid>
    </>
  );
};
