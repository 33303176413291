import { useEffect, useReducer, useRef } from "react";
import axios from "axios";
import videoReducer, {
  SET_LOADING,
  SET_VIDEO,
  SET_FILES,
  SET_ERROR,
} from "./videoReducer";
import { addAuthorization } from "./utils";
import { BASE_URL, API_KEY } from "../Constants";

const useFetchDataVideo = (endPoint, params, token) => {
  const [state, dispatch] = useReducer(videoReducer, {
    loading: true,
    video: null,
    files: null,
    error: null,
  });

  const source = useRef(null);

  useEffect(() => {
    const loadVideo = async () => {
      dispatch({ type: SET_LOADING });

      const url = `${BASE_URL}/${endPoint}?apikey=${API_KEY}`;

      source.current = axios.CancelToken.source();

      try {
        const response = await axios({
          url: url,
          method: "GET",
          data: params,
          headers: addAuthorization(token),
          cancelToken: source.token,
        });

        if (response.data.result === null) {
          dispatch({ type: SET_ERROR, error: "No has more videos" });
          return;
        }

        const { video } = response.data.result;
        dispatch({ type: SET_VIDEO, payload: response.data.result.video });

        if (video.panda_id !== null) {
          loadFiles(video.panda_id);
          console.log(video.panda_id);
        } else {
          loadFiles(null, video.vimeo_id);
          console.log("vimeo");
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          dispatch({ type: SET_ERROR, error: error });
          // throw error;
        }
      }
    };

    const loadFiles = async (panda_id = null, vimeoId = null) => {
      var url = "";
      if (panda_id !== null) {
        url = `${BASE_URL}/panda/${panda_id}?apikey=${API_KEY}`; //AQUIIIIIIIIIIIIIIIIIIII
      } else {
        url = `${BASE_URL}/vimeo/${vimeoId}?apikey=${API_KEY}`; //AQUIIIIIIIIIIIIIIIIIIII
      }
      source.current = axios.CancelToken.source();

      try {
        const response = await axios({
          url: url,
          method: "GET",
          data: params,
          headers: addAuthorization(token),
          cancelToken: source.token,
        });

        dispatch({ type: SET_FILES, payload: response.data.result });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          dispatch({ type: SET_ERROR, error: error });
          // throw error;
        }
      }
    };

    loadVideo();

    return () => source.current.cancel(`${endPoint} canceled by the user.`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endPoint]);

  return state;
};

export default useFetchDataVideo;
