import React from 'react';
import { connect } from 'react-redux';
import { Grid, CircularProgress, Link } from '@material-ui/core';
import { Link as L } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { userRecoverPassword, userClear } from '../redux/actions';
import {
  getError,
  getAuthenticating,
  getMessage,
} from '../redux/actions/utils';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import Message from '../ui/message';
import CustomTextField from '../styles/textfield';
import SubmitSkinButton from '../styles/submit-skin-button';

const RecoverPassword = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    initialValues,
  } = props;

  const location = useLocation();

  React.useEffect(() => {
    resetForm(initialValues);

    return () => {
      props.userClear();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <CustomTextField
            id='email'
            name='email'
            value={values.email}
            label='E-mail'
            variant='outlined'
            required
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            helperText={errors.email && touched.email && errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitSkinButton
            type='submit'
            fullWidth
            disabled={props.isAuthenticating}
          >
            {!props.isAuthenticating ? (
              'RECUPERAR'
            ) : (
              <CircularProgress size={20} color='secondary' />
            )}
          </SubmitSkinButton>
        </Grid>
        {props.error && props.error.length > 0 && (
          <Grid item xs={12}>
            <Message error={true} text={props.error} />
          </Grid>
        )}
        {props.message && props.message.length > 0 && (
          <Grid item xs={12}>
            <Message error={false} text={props.message} />
            <Link
              underline='always'
              component={L}
              to='/'
              style={{
                textAlign: 'center',
                display: 'block',
                marginTop: 10,
                fontWeight: 'bold',
              }}
              color='secondary'
            >
              Voltar para a página inicial
            </Link>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

const validationSchema = Yup.object({
  email: Yup.string('Digite seu e-mail')
    .email('E-mail inválido')
    .required('E-mail é obrigatório'),
});

const FormRecoverPassword = withFormik({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema: validationSchema,
  handleSubmit: (values, { props }) => {
    props.userRecoverPassword({ email: values.email });
  },
  displayName: 'RecoverPassword',
})(RecoverPassword);

const mapStateToProps = (state) => ({
  error: getError(state),
  message: getMessage(state),
  isAuthenticating: getAuthenticating(state),
});

export default connect(mapStateToProps, { userRecoverPassword, userClear })(
  FormRecoverPassword
);
