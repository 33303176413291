import React from "react";
import {
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Typography,
  Button,
} from "@material-ui/core";
import { useParams, useLocation, Redirect, useHistory } from "react-router-dom";
// import { findDOMNode } from 'react-dom';
import ReactPlayer from "react-player";
// import screenfull from 'screenfull';
// import FullscreenButton from '../components/FullscreenButton';
import Loading from "../components/Loading";
import { load } from "../localStorage";
import useFetch from "../hook/useFetch";
import forceFullscreen from "../hoc/forceFullscreen";
import { isMobile } from "../utils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SimpleDialog from "../components/SimpleDialog";
import DoneIcon from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";
import { Auth } from "../client/api";
import { API_KEY } from "../Constants";

const mobile = isMobile();

const useStyles = () =>
  makeStyles((theme) => ({
    container: {
      paddingTop: "180px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "85px",
      },
      paddingBottom: "80px",
      display: "flex",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
    },
    presenceValidateContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "20px",
      paddingTop: "10px",
    },
    player: {
      height: "100% !important",
      width: "100% !important",
    },
    icon: {
      fontSize: "60px",
    },
    backButton: {
      position: "absolute",
      left: "5px",
      top: "188px",
      [theme.breakpoints.down("sm")]: {
        top: "90px",
      },
      height: "50px",
      width: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#3f51b5",
      opacity: "0.6",
      color: "#fff",
      cursor: "pointer",
      "&:hover": {
        opacity: "1",
      },
    },
  }));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Video = () => {
  let { id } = useParams();
  let query = useQuery();

  const { profile } = load("user");
  const token = profile.user.auth_token;
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];
  let history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const theme = useTheme();
  const styles = useStyles(theme)();
  const [validate, setValidate] = React.useState("NOT_STARTED");
  const [validating, setValidating] = React.useState(false);
  const [error, setError] = React.useState(null);
  const homework_id = params.get("homework_id");
  const homeworkNumber = params.get("homework");

  const state = useFetch(`youtubevideo/${id}`, null, token);

  if (state.loading) return <Loading />;
  if (state.video === null) return <p>Erro ao tentar carregar o conteúdo</p>;
  // if (state.error) return <Redirect push to="/videos" />;

  const handleValidate = () => {
    setValidate("VALIDATING");
    setValidating(true);
    const response = Auth(token)
      .get(
        `/livecourseclasshomework/validate?apikey=${API_KEY}&homework=${homeworkNumber}&homework_id=${homework_id}&profile_id=${childrenLive.id}`,
        {}
      )
      .then((response) => {
        setValidate("VALIDATED");
        setError(null);

        setTimeout(() => {
          history.push("/live/homework/detail/" + homework_id);
        }, 2000);
      })
      .catch((error) => {
        setValidate("NOT_VALIDATED");
        console.log(error);
        setError("Erro ao validar presença");
      });
  };

  function exitFullscreen() {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  return (
    <>
      <SimpleDialog open={validating}>
        <div className={styles.presenceValidateContainer}>
          {validate === "VALIDATING" ? <CircularProgress /> : ""}
          {validate === "VALIDATED" ? (
            <DoneIcon style={{ color: "#00bcd4" }} className={styles.icon} />
          ) : (
            ""
          )}
          {validate === "NOT_VALIDATED" ? (
            <Clear style={{ color: "#FF3C3D" }} className={styles.icon} />
          ) : (
            ""
          )}
          <div>
            {validate === "VALIDATED" ? (
              <>
                {" "}
                <Typography variant="h2" color="primary">
                  Validado com sucesso <br />
                  Em instantes você será redirecionado para a página de turmas.
                </Typography>
              </>
            ) : (
              ""
            )}

            {validate === "VALIDATING" ? (
              <>
                {" "}
                <Typography variant="h2" color="primary">
                  Por favor aguarde estamos
                  <br />
                  processando seu dever de casa.
                </Typography>
              </>
            ) : (
              ""
            )}

            {validate === "NOT_VALIDATED" ? (
              <>
                {" "}
                <Typography variant="h2" color="primary">
                  Houve um erro. <br />
                  Por favor tente novamente.
                </Typography>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {validate === "NOT_VALIDATED" && (
            <Button variant="contained" color="primary">
              Tentar Novamente
            </Button>
          )}
        </div>
      </SimpleDialog>
      <Grid item xs={12} className={styles.container}>
        <div className={styles.backButton} onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </div>
        <ReactPlayer
          url={state.data.embed_link}
          className={styles.player}
          playing
          playsinline
          controls
          config={{
            youtube: {
              playerVars: { showinfo: 1 },
            },
          }}
          onEnded={() => {
            // const categoryId = query.get("category_id");
            // setCurrentId((currentId) => currentId + 1);
            // setEndPoint(
            //   "videocategory/" + categoryId + "/item/" + currentId + "/next"
            // );
            exitFullscreen();
            handleValidate();
          }}
        />
      </Grid>
    </>
  );
};

export default Video;
