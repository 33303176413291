import React from "react";
import { Button, Chip, makeStyles } from "@material-ui/core";
import { RotateLeft } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import forceFullscreen from "../hoc/forceFullscreen";
import { Auth } from "../client/api";
import { load } from "../localStorage";
import Loading from "../components/Loading";
import Progress from "../components/Progress";
import { isMobile, isLandscape } from "../utils";
import { API_KEY } from "../Constants";
import ReactAudioPlayer from "react-audio-player";
import { useDispatch } from "react-redux";
import useQuery from "../hook/useQuery";

import backwardImage from "../assets/icon-button-backward.png";
import fowardImage from "../assets/icon-button-foward.png";

const query = new URLSearchParams(window.location.search);
const homewordId = query.get("homework_id");

const useStyles = makeStyles({
  mobile: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    border: "none",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    zIndex: 0,
  },
  desktop: {
    position: "relative",
    width: "100%",
    height: "100vh",
    "& .MuiCircularProgress-root": {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: "-25px",
      marginLeft: "-25px",
    },
  },
  preloading: {
    position: "relative",
    width: "100%",
    height: "100vh",
    zIndex: 1101,
    backgroundColor: "white",
    "& .MuiCircularProgress-root": {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: "-25px",
      marginLeft: "-25px",
    },
  },
  button: {
    width: "50px",
    height: "50px",
    position: "absolute",
    top: "50%",
    marginTop: "-25px",
    border: "none",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: 45,
    cursor: "pointer",
  },
  backward: {
    backgroundImage: `url(${backwardImage})`,
    left: "20px",
  },
  forward: {
    backgroundImage: `url(${fowardImage})`,
    right: "20px",
  },
  bottom: {
    position: "absolute",
    bottom: 25,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 30px",
  },
  textProgress: {
    fontWeight: "bold",
  },
});

let mobile = isMobile();

const Book = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  let rap = React.useRef(null);

  let { id } = useParams();

  const [loading, setLoading] = React.useState(null);
  const [landscape, setLandscape] = React.useState(isLandscape());
  const [data, setData] = React.useState(null);
  const [indexPage, setIndexPage] = React.useState(0);
  const [start, setStart] = React.useState(false);
  const [audioPlaying, setAudioPlaying] = React.useState(false);

  const query = useQuery();
  const singleclass = query.get("singleclass");

  // let myRef = React.createRef();

  var typeOfClassUrl =
    singleclass === "true" ? "singleclasshomework" : "livecourseclasshomework";

  const loadGame = () => {
    setLoading(true);
    const token = load("user").profile.user.auth_token;

    Auth(token)
      .get(typeOfClassUrl + "/" + id + "/detail?apikey=" + API_KEY) // GET DATA CONTENT
      .then((response) => {
        setLoading(false);
        setData(response.data.result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const prevPage = () => {
    setAudioPlaying(true);
    setIndexPage((indexPage) => indexPage - 1);
  };

  const nextPage = () => {
    setAudioPlaying(true);
    setIndexPage((indexPage) => indexPage + 1);
  };

  const getCoin = (typeOfClassUrl) => {
    const token = load("user").profile.user.auth_token;
    var homeworkInfo = JSON.parse(localStorage.getItem("homeworkInfo"));
    homeworkInfo.homework_id !== null &&
      Auth(token)
        .get(
          `${typeOfClassUrl}/validate?apikey=${API_KEY}&homework=${id}&homework_id=${homewordId}`
        )
        .then(() => {
          dispatch({
            type: "REFRESH_COIN",
          });
        });
  };

  React.useEffect(() => {
    const dataBook = props.json;
    if (dataBook) {
      setLoading(false);
      setData(dataBook);
    } else {
      if (!data) {
        if (mobile) {
          if (landscape) {
            loadGame();
          }
          window.addEventListener(
            "orientationchange",
            function () {
              setLandscape(isLandscape());
            },
            false
          );
        } else {
          loadGame();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscape]);

  if (!data) {
    return (
      <div className={styles.preloading}>
        {mobile && !landscape ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginLeft: "-111px",
              marginTop: "-16px",
            }}
          >
            <Chip
              color="secondary"
              label="ROTATE YOUR SMARTPHONE"
              icon={<RotateLeft />}
            />
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }

  const page = data.pages[indexPage];

  const image =
    page.image_background.length > 0
      ? page.image_background[0].secure_url_image
      : "";
  const audio = page.audio.secure_url_audio;
  const total = data.pages.length;

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          zIndex: 1101,
        }}
        className={mobile ? styles.mobile : styles.desktop}
      >
        {start && !audioPlaying && (
          <>
            {indexPage > 0 && (
              <button
                className={`${styles.backward} ${styles.button}`}
                onClick={prevPage}
              ></button>
            )}
            {indexPage + 1 < total && (
              <button
                className={`${styles.forward} ${styles.button}`}
                onClick={nextPage}
              ></button>
            )}

            <div className={styles.bottom}>
              <Progress index={indexPage + 1} length={total} />
              <div style={{ marginLeft: 20 }}>
                <p className={styles.textProgress}>
                  {indexPage + 1}/{total}
                </p>
              </div>
            </div>
          </>
        )}

        <ReactAudioPlayer
          src={audio}
          ref={(element) => {
            rap = element;
          }}
          autoPlay={start}
          onPlay={() => {
            setAudioPlaying(true);
          }}
          onEnded={() => {
            setAudioPlaying(false);
            if (indexPage + 1 === total) {
              getCoin(typeOfClassUrl);
            }
          }}
        />

        {/* Botão só deve aparecer no começo do book, para liberar o áudio inicial, tanto desktop como mobile */}
        {!start && (
          <Button
            disableRipple
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-18px",
              marginLeft: "-51px",
              backgroundColor: "white",
            }}
            onClick={() => {
              setStart(true);
              rap.audioEl.current.play();
            }}
            color="primary"
          >
            START BOOK
          </Button>
        )}
      </div>
    </>
  );
};

export default forceFullscreen(
  Book,
  `/live/homeworks/${homewordId}`,
  mobile,
  true
);
