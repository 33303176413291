import React from "react";
import SubmitSkinButton from "../styles/submit-skin-button";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const monitoraTempo = (time) => {
  if (time !== false) {
    const fakeDate = new Date(2022, 1, 22, 10, 0);

    const classDate = new Date(time);
    const now = new Date();

    if (now >= classDate) {
      return true;
    } else {
      return;
    }
  } else {
    return true;
  }
};

const RecordedClassButton = (
  recorded,
  recorded_class_video,
  styles,
  time = false,
  class_date
) => {
  const history = useHistory();

  console.log("replacementData : ", recorded_class_video);
  var url = "";
  if (recorded_class_video !== null) {
    if (recorded_class_video.panda_id !== null) {
      url = `/live/classplayer/${class_date}?panda_id=${recorded_class_video.panda_id}`;
    } else {
      url = `/live/classplayer/${class_date}?vimeo_id=${recorded_class_video.vimeo_id}`;
    }
  }

  if (recorded) {
    return (
      <>
        <SubmitSkinButton
          fullWidth
          className={styles.button}
          onClick={() => {
            if (!monitoraTempo(time)) {
              alert(
                "Ops, a AULA GRAVADA ainda não está liberada.\r\nRetorne no dia e hora marcados para acessar essa aula.\r\nAssista sempre em famíila!"
              );

              return;
            }
            history.push(url);
          }}
        >
          "Assistir conteúdo - Aula Gravada"
        </SubmitSkinButton>
      </>
    );
  } else {
    return <></>;
  }
};

export default RecordedClassButton;
