import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {CircularProgress} from '@material-ui/core';
import useFetch from '../hook/useFetch';
import { load } from "../localStorage";

import Coin from "../assets/coin.png";

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        right: 5,
        top: 5,
        width: 55,
        height: 55,
        backgroundImage: "url(" + Coin + ")",
        backgroundSize: "contain",
    },
    progress : {
        position: 'absolute',
        right: 5,
        top: 5,
        width: 55,
        height: 55,
    },
    coin: {
      display: 'flex',
      justifyContent: 'center',
      alignItems : 'center',
      width: '100%',
      height: '100%',
      fontSize: 18,
      fontWeight: 'bold',
      color: 'rgba(0,0,0,0.8)',
    },
  });

export default ({ classId }) => {

    const { profile } = load("user");
    const token = profile.user.auth_token;
    const { children } = profile;
    const childrenLive = children.filter((item) => item.has_live_course)[0];
    const state = useFetch(
        `livecourse/homeworks/coins/detail/${classId}?profile_id=${childrenLive.id}`,
        null,
        token
      );

    const classes = useStyles();
    if(typeof(state.data) === undefined || state.loading == true) return <CircularProgress size={18} className={classes.progress} />
    else if( state.data == 0 ) return <></>
    else
    return (
            <div className={classes.root}>
                <div className={classes.coin}>
                  {state.data}
                </div>
            </div>
    );
}

