import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Redirect, useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import CustomDialog from "../../ui/custom-dialog";
import { load } from "../../localStorage";
import { BASE_URL, API_KEY } from "../../Constants";
import { Auth } from "../../client/api";
import BoardAboutClass from "../../components/BoardAboutClass";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import useQuery from "../../hook/useQuery";

import GridAutoFlow from "../../components/GridAutoFlow";
import BoxContent from "../../components/BoxContent";

const useStyles = makeStyles({
  filterDesktop: {
    paddingTop: 180,
    paddingBottom: 60,
    paddingLeft: "2%",
    position: "fixed",
    zIndex: 1,
    background: "white",
    right: 15,
    left: 0,
    top: 0,
    width: "auto",
  },
  filterMobile: {
    paddingTop: 220,
    paddingBottom: 60,
    paddingLeft: "2%",

    zIndex: 1,
    background: "white",
    right: 15,
    left: 0,
    top: 0,
    width: "auto",
  },
});

// export default () => {
//   return <div>calendar</div>;
// };

export default (props) => {
  let history = useHistory();
  const { profile } = load("user");
  const token = profile.user.auth_token;
  const { children } = profile;
  const childrenLive = children.filter((item) => item.has_live_course)[0];
  const [title, setTitle] = useState(null);

  const query = useQuery();
  var params = query.get("categoria");

  const [infoDialog, setInfoDialog] = React.useState(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [singleClass, setsingleClass] = React.useState(false);

  const [processing, setProcessing] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const classes = useStyles();

  const [categorias, setCategorias] = React.useState([]);
  const [subCategorias, setSubcategorias] = React.useState([]);
  const [filtros, setFiltros] = React.useState({
    Categorias: [],
    SubCategorias: [],
    Individual: false,
    Gratuita: false,
    date: false,
  });
  const [redirecionar, setRedirecionar] = React.useState(false);
  // const [selectedDate, handleDateChange] = useState(new Date());
  const profileId = childrenLive.id;

  const mobile = useMediaQuery("(max-width:960px)");

  useEffect(() => {
    if (singleClass === false) {
      single();
    }
  }, [singleClass]);
  const handlefiltros = (filtro, value) => {
    console.log(filtro, value);
    const updateFiltros = filtros;
    updateFiltros[`${filtro}`] = value;
    setFiltros(updateFiltros);
  };

  async function single() {
    try {
      console.log(params);
      const categoria = await Auth(token).get(
        "singleclasscategory" +
          "?profile_id=" +
          profileId +
          "&apikey=" +
          API_KEY
      );

      const subcategoria = await Auth(token).get(
        "singleclasssubcategory" +
          "?profile_id=" +
          profileId +
          "&apikey=" +
          API_KEY
      );

      setCategorias(categoria.data.result);
      setSubcategorias(subcategoria.data.result);

      if (params == "kidsa_plus") {
        const response = await Auth(token).post(
          `singleclassdate/CalendarFilter?apikey=${API_KEY}`,
          {
            profile_id: profileId,
            categories: [7],
            isFree: false,
            isIndividual: false,
            date: false,
            subCategories: [],
          }
        );

        setsingleClass(response.data.result[0]);
        setTitle("Kidsa Doooo!");
      } else if (params == "conversation") {
        const response = await Auth(token).post(
          `singleclassdate/CalendarFilter?apikey=${API_KEY}`,
          {
            profile_id: profileId,
            categories: [],
            isFree: false,
            isIndividual: true,
            date: false,
            subCategories: [],
          }
        );

        setsingleClass(response.data.result[0]);
        setTitle("Kidsa Conversation");
      } else if (params == "introdutoriaingles") {
        const response = await Auth(token).post(
          `singleclassdate/CalendarFilter?apikey=${API_KEY}`,
          {
            profile_id: profileId,
            categories: [2],
            isFree: false,
            isIndividual: false,
            date: false,
            subCategories: [],
          }
        );

        const allDates = response.data.result[0].dates;
        const availableOrReservedDates = allDates.filter((date) => date.has_vacancy || date.reserved);

        setsingleClass({dates: [...availableOrReservedDates]});

        // setsingleClass(response.data.result[0]);
        setTitle("Power Up");
      } else if (params == "introdutoriasprogramacao") {
        const response = await Auth(token).post(
          `singleclassdate/CalendarFilter?apikey=${API_KEY}`,
          {
            profile_id: profileId,
            categories: [11, 9],
            isFree: false,
            isIndividual: false,
            date: false,
            subCategories: [],
          }
        );

        setsingleClass(response.data.result[0]);
        setTitle("Aulas de Reforço Code");
      } else if (params == "clubdoooo") {
        const response = await Auth(token).post(
          `singleclassdate/CalendarFilter?apikey=${API_KEY}`,
          {
            profile_id: profileId,
            categories: [7, 1],
            isFree: false,
            isIndividual: false,
            date: false,
            subCategories: [],
          }
        );

        setsingleClass(response.data.result[0]);
        setTitle("Aulas diárias Club by Kidsa");
      } else if (params == "individuallevelup") {
        const response = await Auth(token).post(
          `singleclassdate/CalendarFilter?apikey=${API_KEY}`,
          {
            profile_id: profileId,
            categories: [8, 4],
            isFree: false,
            isIndividual: true,
            date: false,
            subCategories: [],
          }
        );

        const allDates = response.data.result[0].dates;
        const availableOrReservedDates = allDates.filter((date) => date.has_vacancy || date.reserved);

        setsingleClass({dates: [...availableOrReservedDates]});
        setTitle("Aulas Individuais / Level Up");
      } else {
        const response = await Auth(token).get(
          "singleclassdate" +
            "/Calendar?profile_id=" +
            profileId +
            "&apikey=" +
            API_KEY
        );

        setsingleClass(response.data.result[0]);
      }

      return;
    } catch (error) {
      throw error;
    }
  }

  const search = async () => {
    const filterResponse = await Auth(token).post(
      `singleclassdate/CalendarFilter?apikey=${API_KEY}`,
      {
        profile_id: profileId,
        categories: filtros.Categorias,
        isFree: filtros.Gratuita,
        isIndividual: filtros.Individual,
        date: filtros.date,
        subCategories: filtros.SubCategorias,
      }
    );
    setsingleClass(filterResponse.data.result[0]);
  };

  const cancelReserve = async (classId) => {
    try {
      setProcessing(true);

      const response = await Auth(token).post(
        // 'livecourseclassdate/' + classId + '/cancel?apikey=' + API_KEY,
        `singleclassdate/${classId}/reserve/cancel?apikey=${API_KEY}`,
        {
          profile_id: profileId,
        }
      );

      setProcessing(false);

      if (response.data.result) {
        single();
        onCloseDialog();

        history.go(0);
      }
    } catch (error) {
      alert(error.response.data.error);
      setProcessing(false);
    }
  };

  const makeReserve = async (classId) => {
    try {
      setProcessing(true);

      const response = await Auth(token).post(
        // 'livecourseclassdate/' + classId + '/reserve?apikey=' + API_KEY,
        `singleclassdate/${classId}/reserve?apikey=${API_KEY}`,
        {
          profile_id: profileId,
        }
      );
      setProcessing(false);
      history.go(0);
      if (response.data.result) {
        single();
        onCloseDialog();
      }
    } catch (error) {
      setProcessing(false);

      if (error.response.status === 488) {
        alert("Você não tem Créditos Suficientes.");
        setRedirecionar(true);
      } else if (error.response.status === 401) {
        alert("Limite máximo de reservas alcançado.");
      }
    }
  };

  const montaTempo = (infodialog) => {
    //  console.log('infodialog:', infodialog);
    let dateTimezone = new Date();
    let timezone = dateTimezone.getTimezoneOffset() / 60;
    let separadorDataHorario = infodialog.init_at_complete_format.split(" ");
    let hora = separadorDataHorario[1].split("h");
    let data = separadorDataHorario[0].split("/");
    data = data[2] + "-" + data[1] + "-" + data[0];
    let horaAula = data + "T" + hora[0] + ":" + hora[1] + ":00";
    let dateClass = new Date(horaAula);

    return "";
  };

  const inTimeForClass = async (dateStr1) => {
    try {
      const response = await fetch(`${BASE_URL}/time/iso?apikey=${API_KEY}`);
      console.log(response);
      if (!response.ok) {
        throw new Error(`Erro ao buscar a data atual. Status: ${response.status}`);
      }
      const dataString2 = await response.json();

      // const dataString2 = "2023-12-10T23:49:10.552Z";


      const partes = dateStr1.split(" ");
      const dataPartes = partes[0].split("/");
      const horaPartes = partes[1].split("h");
      const dataString1 = `${dataPartes[2]}-${dataPartes[1]}-${dataPartes[0]} ${horaPartes[0]}:${horaPartes[1]}:00.000 -0300`;

      const data1 = new Date(dataString1);
      const data2 = new Date(dataString2);

      const dezMinutosEmMilissegundos = 10 * 60 * 1000;

      return data2.getTime() >= data1.getTime() - dezMinutosEmMilissegundos;
    } catch (error) {
      console.error('Erro na função inTimeForClass:', error.message);
      return true;
    }
  };

  const onOpenDialog = async (data) => {
    // const isInTime = await inTimeForClass(data.init_at_complete_format);
    // data.isInTime = isInTime;

    setInfoDialog(data);
  };

  const onCloseDialog = () => {
    setInfoDialog(null);
  };

  if (!singleClass) return <Loading />;
  if (singleClass.dates.length === 0)
    return (
      <>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ paddingBottom: 100 }}
        >
          <div
            style={
              mobile
                ? {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 5px",
                  }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 5px",
                  }
            }
          >
            <Button
              style={mobile ? {} : { marginBottom: "-20px" }}
              variant="contained"
              color="primary"
              startIcon={<ArrowBack />}
              onClick={() => history.push("/live/calendar")}
            >
              Voltar
            </Button>

            <Typography
              variant="h1"
              style={mobile ? {} : { marginLeft: "40px" }}
            >
              No momento não temos Aulas disponível !!
            </Typography>

            <div style={{ visibility: "hidden" }}>space</div>
          </div>
        </Grid>
      </>
    );

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={
          mobile
            ? { paddingBottom: 80, marginTop: 190 }
            : { paddingBottom: 60, marginTop: 190 }
        }
      >
        <Grid item xs={11}>
          {title !== null && (
            <div
              style={
                mobile
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "100px 5px",
                      height: "250px",
                    }
                  : {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "20px 58px",
                    }
              }
            >
              <Button
                style={mobile ? {} : { marginBottom: "-25px" }}
                variant="contained"
                color="primary"
                startIcon={<ArrowBack />}
                onClick={() => history.push("/live/calendar")}
              >
                Voltar
              </Button>

              <Typography variant="h1">{title}</Typography>

              <div style={{ visibility: "hidden" }}>space</div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <GridAutoFlow>
              {singleClass.dates.map((item, index) => {
                // const categoria = item.name;

                return (
                  <BoxContent
                    {...item}
                    key={index}
                    liveClass={true}
                    onOpenDialog={onOpenDialog}
                    calendar={false}
                    hora={montaTempo(item)}
                  />
                );
              })}
            </GridAutoFlow>
          </div>
        </Grid>
      </Grid>
      {infoDialog && console.log("infodialog : ", infoDialog)}
      {infoDialog && (
        <CustomDialog
          title={`${infoDialog.name} -`}
          subtitle={
            infoDialog.init_at_complete_format + " - Horário de Brasília"
          }
          open={true}
          onClose={onCloseDialog}
        >
          <BoardAboutClass
            id={infoDialog.id}
            countReserves={infoDialog.countReserves}
            numberParticipants={infoDialog.numberParticipants}
            hasVacancy={infoDialog.hasVacancy}
            image={infoDialog.teacher.image_thumb[1].secure_url_image}
            reserved={infoDialog.reserved}
            title={name}
            description={infoDialog.description || ""}
            nameTeacher={infoDialog.teacher.name}
            processing={processing}
            makeReserve={makeReserve}
            cancelReserve={cancelReserve}
            onClose={onCloseDialog}
            link={infoDialog.link}
            time={infoDialog}
            singleclass={true}
            recorded_class_video={null}
            credits={infoDialog.credits}
            // isInTime={infoDialog.isInTime}
            init_at_complete_format={infoDialog.init_at_complete_format}
          />
        </CustomDialog>
      )}
      {redirecionar && <Redirect to="/live/credit" />}
    </>
  );
};
