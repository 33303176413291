import React from 'react';
import {
  makeStyles
} from '@material-ui/core';
import View from '../styles/view';

const useStyles = makeStyles({  
  outsideWhiteBorder: {
    backgroundColor: 'white',
    borderRadius: 12,
    padding: 1.5,
    // shadowOffset: 'width: 0, height: 3',
    margin: '0 10px 10px 10px'
  },
  outsideBlackBorder: {
    height: 20,
    backgroundColor: '#424242',
    borderRadius: 11,
    padding: 1.5
  },
  background: {
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
  },
  progress: {
    width: '0%',
    height: '100%',
    backgroundColor: '#FFD714',
    borderRadius: 10,
  },
});

export default props => {
  const styles = useStyles();

  const { index, length } = props;
  let pct = (index / length) * 100;
  let width = { width: pct + '%' };

  return (
    <View className={styles.outsideWhiteBorder}>
      <View className={styles.outsideBlackBorder}>
        <View className={styles.background}></View>
        <View className={styles.progress} style={width}></View>
      </View>
    </View>
  )
}