import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CssBaseline from "@material-ui/core/CssBaseline";
import Root from "./containers/Root";
import theme from "./theme";
import store from "./redux/store/store";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/pt-br";
import ErrorBoundary from "./containers/ErrorBoundary";
moment.locale("pt-br");

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale="pt-br"
      >
        <ErrorBoundary>
          <Root store={store} />
        </ErrorBoundary>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
