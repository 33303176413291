import React from 'react';
import {
  Button,
  makeStyles
} from '@material-ui/core';
import View from '../styles/view';

const useStyles = makeStyles(theme => ({
  button: {
    padding: 0,
    margin: 0,
  },
  outsideWhiteBorder: {
    width: 200,
    height: 300,
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 2,
    shadowOffset: { width: 0, height: 3 },
    shadowColor: 'black',
    shadowOpacity: 0.4,
    shadowRadius: 0,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      width: 150,
      height: 250,
    }
  },
  outsideBlackBorder: {
    height: '100%',
    backgroundColor: '#424242',
    borderRadius: 5,
    padding: 2
  },
  outsideCheckBorder: {
    height: '100%',
    borderRadius: 4,
    padding: 3,
  },
  container: {
    position: 'relative',
    height: '100%',
    backgroundColor: '#FFFBE6',
    padding: 2,
    borderRadius: 3,
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-75px',
    marginTop: '-75px',
    width: 150,
    height: 150,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: 75,
      height: 75,
      marginLeft: '-38px',
      marginTop: '-38px',
    }
  }

}));

export default props => {
  const styles = useStyles();
  return (
    <View pointerEvents={props.lock ? 'none' : 'auto'}>
      <Button 
        className={styles.button}
        onClick={() => {
        props.onCheckOption(props)
      }}>
        <View className={styles.outsideWhiteBorder}>
          <View className={styles.outsideBlackBorder}>
          <View className={styles.outsideCheckBorder} style={{ backgroundColor: props.defaultSelectedColor ? '#FFFBE6' : props.selected === false ? '#FFFBE6' : props.selected && props.isCorrect ? '#36D95F' : '#F84C4C' }}>
            <View className={styles.container}>
              <img src={props.image} className={styles.image}  alt='icon' />
            </View>
            </View>
          </View>
        </View>
      </Button>
    </View>
  )
}