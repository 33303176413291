import React from 'react';
import Slide from '../components/Slide';
import HeaderPage from '../components/HeaderPage';
import mountContent from '../hoc/mountContent';

const Games = (props) => {
  return (
    <React.Fragment>
      {props.title && props.description && (
        <HeaderPage title={props.title} description={props.description} />
      )}

      {props.content.map((value, index) => {
        if (value.items.length <= 0) return null;

        var newValues = value.items.map((item) => {
          const { id, object_related, image_thumb } = item;
          if (object_related.name == null) {
            return {};
          }
          const { name } = object_related;

          return {
            id: id,
            name: name,
            image_thumb: image_thumb[0].secure_url_image,
          };
        });

        return (
          <Slide
            key={index}
            title={value.name}
            values={newValues}
            path='game'
            category_id={value.id}
          />
        );
      })}
    </React.Fragment>
  );
};

export default mountContent(Games, 'gamecategory', 2);
