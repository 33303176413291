import React, { useState } from "react";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import client from "../../../client/api";
import { API_KEY } from "../../../Constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../components/Loading";
import { load } from "../../../localStorage";

const useStyles = makeStyles(() => ({
  inputField: {
    marginBottom: 12,
  },
  submitButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  dualField: {
    display: "flex",
    columnGap: "10px",
  },
  f1: { flex: 1 },
  max20: { maxWidth: "20%" },
  max30: { maxWidth: "30%" },
}));

const validationSchema = yup.object({});

function getChildLive(listChildren) {
  const atLeastOneChildLive = listChildren.filter(
    (item) => item.has_live_course === true
  );

  return atLeastOneChildLive.length > 0 ? atLeastOneChildLive[0] : null;
}

function AddressInformation() {
  const [loading, setLoading] = useState(false);
  const profileData = useSelector((state) => state.profile);
  const classes = useStyles();
  const { profile } = load("user");
  const children = getChildLive(profile.children);
  const formik = useFormik({
    initialValues: {
      customer_name: profileData.customer_first_name,
      customer_last_name: profileData.customer_last_name,
      street: profileData.street,
      number: profileData.number,
      neighborhood: profileData.neighborhood,
      city: profileData.city,
      state: profileData.state,
      country: profileData.country,
      zipCode: profileData.zip_code,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const response = await client.put(
        `/profile/${children.id}?apikey=${API_KEY}`,
        {
          customer_name: values.customer_first_name,
          customer_last_name: values.customer_last_name,
          street: values.street,
          number: values.number,
          neighborhood: values.neighborhood,
          city: values.city,
          state: values.state,
          country: values.country,
          zip_code: values.zipCode,
        }
      );
      if (response.status !== 200) {
        toast.error("Falha ao Atualizar dados, por favor tente novamente.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      } else {
        localStorage.setItem(
          "notification",
          "Sucesso em salvar as informações do perfil."
        );
        window.location.reload();
      }
    },
  });
  if (loading) return <Loading />;
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.dualField}>
          <TextField
            id="customer_name"
            name="customer_name"
            label="Nome do Responsavel"
            variant="outlined"
            value={formik.values.customer_name}
            onChange={formik.handleChange}
            className={[classes.inputField, classes.f1]}
            error={
              formik.touched.customer_name &&
              Boolean(formik.errors.customer_name)
            }
            helperText={
              formik.touched.customer_name && formik.errors.customer_name
            }
          />
          <TextField
            id="customer_last_name"
            name="customer_last_name"
            label="Sobrenome do Responsavel"
            variant="outlined"
            value={formik.values.customer_last_name}
            onChange={formik.handleChange}
            className={[classes.inputField, classes.f1]}
            error={
              formik.touched.customer_last_name &&
              Boolean(formik.errors.customer_last_name)
            }
            helperText={
              formik.touched.customer_last_name &&
              formik.errors.customer_last_name
            }
          />
        </div>

        <div className={classes.dualField}>
          <TextField
            id="number"
            name="number"
            label="Número"
            variant="outlined"
            value={formik.values.number}
            onChange={formik.handleChange}
            className={[classes.inputField, classes.max20]}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
          />
          <TextField
            id="street"
            name="street"
            label="Rua"
            variant="outlined"
            value={formik.values.street}
            onChange={formik.handleChange}
            className={[classes.inputField, classes.f1]}
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={formik.touched.street && formik.errors.street}
          />
        </div>

        <div className={classes.dualField}>
          <TextField
            id="neighborhood"
            name="neighborhood"
            label="Bairro"
            variant="outlined"
            value={formik.values.neighborhood}
            onChange={formik.handleChange}
            className={[classes.inputField]}
            error={
              formik.touched.neighborhood && Boolean(formik.errors.neighborhood)
            }
            helperText={
              formik.touched.neighborhood && formik.errors.neighborhood
            }
          />
          <TextField
            id="city"
            name="city"
            label="Cidade"
            variant="outlined"
            value={formik.values.city}
            onChange={formik.handleChange}
            className={[classes.inputField, classes.f1]}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </div>

        <div className={classes.dualField}>
          <TextField
            id="state"
            name="state"
            label="Estado"
            variant="outlined"
            value={formik.values.state}
            onChange={formik.handleChange}
            className={[classes.inputField, classes.f1]}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
          <TextField
            id="country"
            name="country"
            label="País"
            variant="outlined"
            value={formik.values.country}
            onChange={formik.handleChange}
            className={[classes.inputField, classes.f1]}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
          <TextField
            id="zipCode"
            name="zipCode"
            label="CEP"
            variant="outlined"
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            className={[classes.inputField]}
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
          />
        </div>

        <div className={classes.submitButton}>
          <Button color="primary" variant="contained" type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddressInformation;
