import {
  USER_SIGN_IN_START,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAILURE,
  USER_SIGN_OUT,
  USER_CLEAR,
  USER_RECOVER_PASSWORD_START,
  USER_RECOVER_PASSWORD_IN_SUCCESS,
  USER_RECOVER_PASSWORD_IN_FAILURE,
} from '../actionTypes';

import API from '../../../client/api';
import { API_KEY } from '../../../Constants';

export const userSignIn = (values) => (dispatch) => {
  dispatch({ type: USER_SIGN_IN_START });

  return API.post('customer/sign?apikey=' + API_KEY, values)
    .then(
      (response) => {
        dispatch({
          type: USER_SIGN_IN_SUCCESS,
          payload: response.data.result,
        });
      },
      ({ response }) => {
        dispatch({
          type: USER_SIGN_IN_FAILURE,
          payload: response.data.error || 'Erro, tente novamente',
        });
      }
    )
    .catch((error) => {
      dispatch({
        type: USER_SIGN_IN_FAILURE,
        error: error || 'Erro, tente novamente',
      });
    });
};

export const userRecoverPassword = (values) => (dispatch) => {
  dispatch({ type: USER_RECOVER_PASSWORD_START });

  return API.post('user/recoverpassword?apikey=' + API_KEY, values)
    .then(
      (response) => {
        console.log('success');
        dispatch({
          type: USER_RECOVER_PASSWORD_IN_SUCCESS,
          payload: response.data.result,
        });
      },
      ({ response }) => {
        console.log('error response');
        dispatch({
          type: USER_RECOVER_PASSWORD_IN_FAILURE,
          payload: response.data.message || 'Erro, tente novamente',
        });
      }
    )
    .catch((error) => {
      console.log('catch');
      dispatch({
        type: USER_RECOVER_PASSWORD_IN_FAILURE,
        error: error || 'Erro, tente novamente',
      });
    });
};

export const userSignOut = () => (dispatch) => {
  dispatch({
    type: USER_SIGN_OUT,
    payload: {},
  });
};

export const userClear = () => (dispatch) => {
  dispatch({ type: USER_CLEAR });
};
