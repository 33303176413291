import React from "react";
import { Link, AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import notShow from "../hoc/notShow";

import YoutubeLogo from "../assets/youtube-logo.png";
import Instagram from "../assets/instagram-logo.png";
import Spotify from "../assets/spotify-logo.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    top: "inherit",
    bottom: 0,
    backgroundColor: "#004dc9",
    boxShadow: "none",
    height: 80,
  },
  container: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "4px",
  },
  logo: {
    width: 336,
    marginBottom: 15,
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  phrase: {
    fontWeight: "bold",
    color: "white",
    fontSize: 11,
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  logosContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px",
    columnGap: "5px",
  },
  link: {
    color: "#ff0",
  },
}));

const Footer = (props) => {
  const styles = useStyles();
  return (
    <AppBar className={styles.footer} component="footer">
      <div className={styles.container}>
        <p className={styles.phrase}>
          ** Todas as aulas estão configuradas no HORÁRIO DE BRASÍLIA
          <br />
          2019-2020 ©{" "}
          <Link
            href="http://360e1.com/"
            target="_blank"
            rel="noopener"
            className={styles.link}
          >
            360e1 Entretenimento
          </Link>{" "}
          • Todos os direitos reservados •{" "}
          <Link
            href="https://api.kidsa.com/termos-uso.pdf"
            target="_blank"
            rel="noopener"
            className={styles.link}
          >
            Termos de Uso
          </Link>{" "}
          •{" "}
          <Link
            href="https://api.kidsa.com/politica-privacidade.pdf"
            target="_blank"
            rel="noopener"
            className={styles.link}
          >
            Política de Privacidade
          </Link>
          <br />
          <div className={styles.logosContainer}>
            Acompanhe o Kidsa nas mídias sociais:
            <Link
              href="https://www.youtube.com/channel/UCaw-FP3gBVqf6_mAAhvdzoQ"
              target="_blank"
              rel="noopener"
              className={styles.link}
            >
              <img src={YoutubeLogo} height="30" />
            </Link>
            <Link
              href="https://www.instagram.com/kidsaoficial/"
              target="_blank"
              rel="noopener"
              className={styles.link}
            >
              <img src={Instagram} height="25" />
            </Link>
            <Link
              href="https://open.spotify.com/artist/4uxWlzCOE5Qlywc0C34t1D"
              target="_blank"
              rel="noopener"
              className={styles.link}
            >
              <img src={Spotify} height="25" />
            </Link>
          </div>
        </p>
      </div>
    </AppBar>
  );
};

export default notShow(Footer);
