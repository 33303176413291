import React, {useEffect} from "react";
import {
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import SubmitSkinButton from "../styles/submit-skin-button";
import SubmitCancelButton from "../styles/submit-cancel-button";
import LogInClassButton from "../ui/LogInClassButton";
import ReplacementButtonAndRecordedClass from "../ui/ReplacementButtonAndRecordedClass";
import RecordedClassButton from "../ui/RecordedClassButton";
import HomeworkButton from "../ui/HomeworkButton";
import { useHistory } from "react-router-dom";
import KidsReserved from "../assets/kids-reserved.png";
import KidsLimit from "../assets/kids-limit.png";

import useFetch from "../hook/useFetch";
import { load } from "../localStorage";

const useStyles = makeStyles({
  image: {
    display: "block",
    width: "100%",
  },
  text: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&> img": {
      marginRight: 10,
    },
  },
  button: {
    margin: "0 0 20px",
  },
  closeButton: {
    margin: "0 0 6px",
    backgroundColor: '#004dc9',
    '& > span': { // Modificado para seletor correto
      color: 'white',
    },
    '&:hover': {
      backgroundColor: '#4477c9',
    },
    '&.Mui-disabled': {
      backgroundColor: 'white',
    },
    '&.Mui-disabled > .MuiButton-label': {
      color: 'black'
    }
  },
  image2: {
    display: "block",
    margin: "0 auto 20px",
  },
  text2: {
    textAlign: "left",
    margin: "0 0 20px",
  },
});

export default ({
  id,
  title,
  description,
  nameTeacher,
  countReserves,
  numberParticipants,
  reserved,
  image,
  makeReserve,
  cancelReserve,
  processing,
  allowCancel,
  allowReserve,
  dependencies,
  hasVacancy,
  link,
  time,
  turmas,
  replacementvideoid,
  hasHomeworks,
  classId,
  replacementhomeworkid,
  singleclass,
  credits,
  watched,
  single,
  recordedclass = false,
  recorded,
  recorded_class_video,
  init_at,
  singleHomework,
  // isInTime,
  onClose,
  buttonText,
  init_at_complete_format
}) => {
  const { profile } = load("user");
  const token = profile.user.auth_token;
  var replacementData = false;
  if (
    replacementhomeworkid !== null &&
    replacementhomeworkid !== false &&
    singleclass !== true
  ) {
    replacementData = useFetch(
      `livecourseclassturmas/getReplacementData/${replacementvideoid}`,
      null,
      token
    );
  }

  const styles = useStyles();
  // const limit = countReserves === numberParticipants;
  let phraseReserve = "Nenhum aluno reservou aula ainda";

  if (!hasVacancy) {
    phraseReserve = "Não temos mais vagas para esta aula";
  } else if (countReserves > 0) {
    phraseReserve =
      countReserves > 1
        ? `${countReserves} alunos reservaram esta aula`
        : `${countReserves} aluno reservou esta aula`;
  }

  if (!allowReserve && dependencies) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <img src={image} alt={title} className={styles.image2} />
          <Typography component="p" className={styles.text2} variant="body2">
            {description}
          </Typography>
          <Typography component="p" style={{ textAlign: "left" }}>
            Para assistir a esta aula você precisa assistir a(s) seguinte(s)
            aula(s):
          </Typography>
          {dependencies.map((item, index) => (
            <Typography
              component="p"
              className={styles.text2}
              key={index}
              variant="body2"
            >
              - {item.name}
            </Typography>
          ))}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {(replacementhomeworkid == null || replacementhomeworkid == false) &&
            !watched && (
              <>
                <Grid item sm={12} md={6}>
                  <img src={image} alt={title} className={styles.image} />
                  <Typography
                    component="p"
                    // style={{ textAlign: 'center' }}
                    className={styles.text}
                    variant="body1"
                  >
                    {nameTeacher}
                  </Typography>
                </Grid>

                <Grid item sm={12} md={6}>
                  <Typography
                    component="p"
                    className={styles.text}
                    variant="body2"
                  >
                    {description}
                  </Typography>
                </Grid>
              </>
            )}
          {recorded ||
          (replacementhomeworkid !== null &&
            replacementhomeworkid !== false &&
            replacementData.data != null) ? (
            <div
              style={{
                textAlign: "center",
                padding: "5px",
                marginBottom: "10px",
                fontWeight: 500,
              }}
            >
              {" "}
              Atenção! Para que a aula seja computada como ASSISTIDA, o vídeo
              tem que ser visto por completo.
            </div>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} justifyContent="center" alignItems="center">
        {LogInClassButton(
          reserved,
          styles,
          time,
          link,
          recordedclass,
          recorded_class_video,
          singleclass,
          onClose,
          init_at_complete_format,
          id
        )}
        {hasVacancy && !reserved && (turmas != true || single === true) && (
          <SubmitSkinButton
            fullWidth
            className={styles.button}
            onClick={() => {
              makeReserve(id);
            }}
          >
            {!processing ? (
              "FAZER RESERVA"
            ) : (
              <CircularProgress size={20} color="secondary" />
            )}
          </SubmitSkinButton>
        )}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          {reserved && (turmas != true || single === true) && (
            <SubmitCancelButton
              className={styles.button}
              onClick={() => {
                cancelReserve(id);
              }}
            >
              {!processing ? (
                "CANCELAR RESERVA "
              ) : (
                <CircularProgress size={20} color="primary" />
              )}
            </SubmitCancelButton>
          )}
        </div>

        {ReplacementButtonAndRecordedClass(
          replacementvideoid,
          styles,
          replacementData,
          id,
          classId,
          replacementhomeworkid,
          recordedclass,
          init_at
        )}
        {RecordedClassButton(
          recorded,
          recorded_class_video,
          styles,
          init_at,
          id
        )}
        {HomeworkButton(hasHomeworks, styles, classId, singleHomework)}
      </Grid>

      {turmas != true && (
        <Grid item xs={12}>
          <Typography component="p" className={styles.text} variant="body1">
            <img
              src={hasVacancy ? KidsReserved : KidsLimit}
              alt="Kids reserved"
            />
            {phraseReserve}
          </Typography>
          {credits > 0 && (
            <Typography component="p" className={styles.text} variant="body1">
              {" "}
              Créditos : {credits}{" "}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};
