import React from "react";
import { connect } from "react-redux";
import { Grid, CircularProgress } from "@material-ui/core";
import * as Yup from "yup";
import { withFormik } from "formik";
import { childLiveCourse } from "../redux/actions";
import { getError, getProcessing } from "../redux/actions/utils";
import Message from "../ui/message";
import CustomTextField from "../styles/textfield";
import SubmitSkinButton from "../styles/submit-skin-button";
import { useHistory } from "react-router-dom";

const ChildApply = (props) => {
  const history = useHistory();
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    initialValues,
    turmas,
  } = props;

  const [mounted, setMounted] = React.useState(false);
  // const [turmas, setTurmas] = React.useState([]);

  const renderRow = () => {
    let filterTurmas = turmas.filter(function (item) {
      return item.course_id === values.livecourse_id;
    });
    return filterTurmas.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {/* `${item.class_days} | Das ${item.class_hour_begin } às ${item.class_hour_end} ` */}
          {`${item.group_name} `}
        </option>
      );
    });
  };

  React.useEffect(() => {
    if (!props.processing && mounted) {
      resetForm(initialValues);
      goToLiveCalendar();
    }
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.processing]);

  const goToLiveCalendar = () => {
    history.push("/live/calendar");
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <CustomTextField
            style={{ display: "none" }}
            id="livecourse_id"
            name="livecourse_id"
            label="Selecione um curso"
            placeholder="Selecione um curso"
            variant="outlined"
            required
            select
            fullWidth
            SelectProps={{
              native: true,
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.livecourse_id}
            error={errors.livecourse_id && touched.livecourse_id}
            helperText={
              errors.livecourse_id &&
              touched.livecourse_id &&
              errors.livecourse_id
            }
          >
            {props.courses.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </CustomTextField>
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            style={{ display: "none" }}
            id="turmas"
            name="turmas"
            label="Selecione uma turma"
            placeholder="Selecione uma turma"
            variant="outlined"
            required
            select
            fullWidth
            SelectProps={{
              native: true,
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.turmas}
            error={errors.turmas && touched.turmas}
            helperText={errors.turmas && touched.turmas && errors.turmas}
          >
            <option value={-1}>[Selecione]</option>
            {renderRow()}
          </CustomTextField>
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            id="profile_id"
            name="profile_id"
            label="Seleciona uma criança"
            placeholder="Selecione uma criança"
            variant="outlined"
            required
            select
            fullWidth
            SelectProps={{
              native: true,
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.profile_id}
            error={errors.profile_id && touched.profile_id}
            helperText={
              errors.profile_id && touched.profile_id && errors.profile_id
            }
          >
            <option value={-1}>[Selecione]</option>
            {props.children.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </CustomTextField>
        </Grid>
        <Grid item xs={12}>
          <SubmitSkinButton type="submit" fullWidth disabled={props.processing}>
            {!props.processing ? (
              "ATRIBUIR"
            ) : (
              <CircularProgress size={20} color="secondary" />
            )}
          </SubmitSkinButton>
        </Grid>
        {props.error.length > 0 && (
          <Grid item xs={12}>
            <Message error={true} text={props.error} />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

const validationSchema = Yup.object({
  livecourse_id: Yup.number()
    .moreThan(0, "Selecione um curso")
    .required("Escolher um curso é obrigatório"),
  profile_id: Yup.number()
    .moreThan(0, "Selecione uma criança")
    .required("Escolher uma criança é obrigatório"),
  turmas: Yup.number()
    .moreThan(0, "Selecione uma turma")
    .required("Escolher uma turma é obrigatório"),
});

const FormChildApplyLiveCourse = withFormik({
  mapPropsToValues: () => ({
    livecourse_id: 1,
    profile_id: -1,
    turmas: 160,
  }),
  validationSchema: validationSchema,
  handleSubmit: (values, { props }) => {
    props.childLiveCourse(values.livecourse_id, {
      profile_id: Number(values.profile_id),
      subscription_id: Number(props.subscription_id),
      turmas: Number(values.turmas),
    });
  },
  displayName: "ChildApply",
})(ChildApply);

const mapStateToProps = (state) => ({
  error: getError(state),
  processing: getProcessing(state),
});

export default connect(mapStateToProps, { childLiveCourse })(
  FormChildApplyLiveCourse
);
