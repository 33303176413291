import React from "react";
import {
  // Menu,
  // MenuItem,
  Chip,
  Avatar,
  Button,
  // Badge,
  makeStyles,
} from "@material-ui/core";
// import ToOpen from '@material-ui/icons/ArrowDropDownOutlined';
// import ToClose from '@material-ui/icons/ArrowDropUpOutlined';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getChildren } from "../redux/actions/utils";

const useStyles = makeStyles({
  chip: {
    // minWidth: 150,
    // maxWidth: 150,
    justifyContent: "space-between",
  },
  badge: {
    top: "-10%",
    backgroundColor: "#ffeb00",
    color: "black",
    border: "1px solid black",
  },
});

function getChildLive(listChildren) {
  const atLeastOneChildLive = listChildren.filter(
    (item) => item.has_live_course === true
  );
  return atLeastOneChildLive.length > 0 ? atLeastOneChildLive[0] : null;
}

export const SelectChild = ({ listChildren, limitAddChild }) => {
  const styles = useStyles();
  // const hasChild = listChildren && listChildren.length >= 1;
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [image, setImage] = React.useState(null);
  // const [coins, setCoins] = React.useState(
  //   hasChild ? listChildren[0].coins : 0
  // );

  // var chipRef = React.useRef(null);

  const history = useHistory();
  const labelNewChild = "Nova criança";

  // const openMenu = (event) => {
  //   setAnchorEl(event ? event.currentTarget : 1);
  // };

  // const closeMenu = () => {
  //   setAnchorEl(null);
  // };

  const newChild = () => {
    history.push("/live/new-child");
    // closeMenu();
  };

  // const changeChild = (item) => {
  //   setName(item.name);
  //   setImage(item.image);
  //   setCoins(item.icons);
  //   closeMenu();
  // };

  React.useEffect(() => {
    const childLive = getChildLive(listChildren);
    if (childLive) {
      setName(childLive.name);
      setImage(childLive.image);
    }
  }, [listChildren]);

  if (!name && !image) {
    return (
      <Button id="new-child" onClick={newChild}>
        {labelNewChild}
      </Button>
    );
  }

  // limit the name at 25 characters
  const compressName = (nome) => {
    if (nome.length <= 10) {
      // if less or equal 25, return the name.
      return nome;
    }
    let nomeArray = nome.split(" ").filter((e) => e !== "");
    nome = nomeArray
      .map((e, i, a) => {
        const lastArrayIndex = a.length - 1;
        if (i > 0 && i < lastArrayIndex) {
          return `${e.charAt(0).toUpperCase()}.`;
        }
        return e;
      })
      .join(" ");
    if (nome.length <= 10) {
      return nome;
    }
    nomeArray = nome.split(" ").filter((e) => e !== "");
    if (nomeArray.length < 2) {
      return nomeArray[0];
    }
    let firstName = nomeArray[0];
    let lastName = nomeArray.slice(-1).pop();
    if (`${firstName} ${lastName}`.length < 18) {
      return `${firstName} ${lastName}`;
    }
    return firstName;
  };

  return (
    // <Badge
    //   badgeContent={`KOINS ${coins}`}
    //   overlap='circle'
    //   classes={{ badge: styles.badge }}
    // >
    <Chip
      // ref={chipRef}
      // aria-label='more'
      // aria-controls='long-menu'
      // aria-haspopup='true'
      // id='chip'
      color="primary"
      avatar={<Avatar src={image} />}
      className={styles.chip}
      label={compressName(name)}
      // onDelete={() => setAnchorEl(chipRef.current)}
      // deleteIcon={anchorEl ? <ToClose /> : <ToOpen />}
      onClick={() => history.push("/live/profile")}
    />
    // </Badge>
  );

  // return (
  //   <>
  //     <Chip
  //       ref={chipRef}
  //       aria-label='more'
  //       aria-controls='long-menu'
  //       aria-haspopup='true'
  //       id='chip'
  //       avatar={<Avatar src={image} />}
  //       className={styles.chip}
  //       label={name}
  //       onDelete={() => setAnchorEl(chipRef.current)}
  //       deleteIcon={anchorEl ? <ToClose /> : <ToOpen />}
  //       onClick={openMenu}
  //     />
  //     <Menu
  //       id='long-menu'
  //       getContentAnchorEl={null}
  //       keepMounted
  //       marginThreshold={0}
  //       anchorEl={anchorEl}
  //       open={Boolean(anchorEl)}
  //       onClose={closeMenu}
  //       anchorOrigin={{
  //         vertical: 35,
  //         horizontal: 'center',
  //       }}
  //       transformOrigin={{
  //         vertical: 'top',
  //         horizontal: 'center',
  //       }}
  //     >
  //       {listChildren.map((item, index) => {
  //         return (
  //           <MenuItem
  //             key={index}
  //             onClick={() => {
  //               changeChild(item);
  //             }}
  //           >
  //             {item.name}
  //           </MenuItem>
  //         );
  //       })}
  //       {listChildren.length < (limitAddChild || 4) && (
  //         <MenuItem onClick={newChild}>{labelNewChild}</MenuItem>
  //       )}
  //     </Menu>
  //   </>
  // );
};

const mapStateToProps = (state) => ({
  listChildren: getChildren(state),
});

export default connect(mapStateToProps, null)(SelectChild);
