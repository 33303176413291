import { FREE_USER } from '../../../Constants';

// helpers
export const getSubscription = (state) => {
  return (
    state.user &&
    state.user.profile &&
    (state.user.profile.has_subscription ||
      (state.user.profile.user &&
        state.user.profile.user.type_user === FREE_USER))
  );
};

export const getLoggedIn = (state) => {
  return state.user && state.user.loggedIn;
};

export const getError = (state) => {
  return state.user && state.user.error;
};

export const getProcessing = (state) => {
  return state.user && state.user.processing;
};

export const getAuthenticating = (state) => {
  return state.user && state.user.isAuthenticating;
};

export const getMessage = (state) => {
  return state.user && state.user.message;
};

export const getValidUser = (state) => {
  return getLoggedIn(state) && getSubscription(state);
};

export const getChildren = (state) => {
  return state.user && state.user.profile && state.user.profile.children;
};
