import React from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../Constants";

import CardCheck from "./CardCheck";
import Progress from "./Progress";

import FeedbackGame from "../custom-ui/feedback-game";

var _audio = null;
var _idInterval = null;
let _timerToRepeatQuestion = 10000;

var _startAudio = null;
var _successAudio = null;
var _errorAudio = null;
var _finishAudio = null;
var _isTestFinalCorrection = null;
var _minimumCorrectQuestion = null;
var _numberOptionsCorrect = null;

const validate = () => {
  localStorage.setItem("gameEnd", "loading");
  var homeworkInfo = JSON.parse(localStorage.getItem("homeworkInfo"));
  var authToken = JSON.parse(localStorage.getItem("user"));
  authToken = authToken.profile.user.auth_token;
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + authToken);
  var myInit = { method: "GET", headers: myHeaders };
  var typeOfClassUrl = homeworkInfo.singleclass
    ? "singleclasshomework"
    : "livecourseclasshomework";
  var myRequest = new Request(
    `${homeworkInfo.BASE_URL}/${typeOfClassUrl}/validate?apikey=${homeworkInfo.API_KEY}&homework=${homeworkInfo.homeworkNumber}&homework_id=${homeworkInfo.homework_id}`,
    myInit
  );
  return fetch(myRequest);
};

export default React.forwardRef((props, ref) => {
  const [questions, setQuestions] = React.useState(null);
  const [progress, setProgress] = React.useState(1);
  const [lock, setLock] = React.useState(true);
  const [feedback, setFeedback] = React.useState(null);

  const [path, setPath] = React.useState(null);
  const history = useHistory();

  // test feedback final
  const [hide, setHide] = React.useState(true);
  const [pass, setPass] = React.useState(false);

  React.useEffect(() => {
    if (questions === null && !props.isMobile) start();

    setPath(history.location.pathname);

    return () => {
      if (path !== null && path !== history.location.pathname) {
        clearAudio();
        clearInternval();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, progress, lock, feedback, history]);

  React.useImperativeHandle(ref, () => ({
    startGame() {
      start();
    },
  }));

  // methods
  const start = () => {
    const {
      type_test,
      minimum_correct_question,
      start_audio,
      error_audio,
      success_audio,
      finish_audio,
    } = props.json;

    if (
      props.json === null ||
      start_audio === null ||
      error_audio === null ||
      success_audio === null ||
      finish_audio === null ||
      props.json.questions === null ||
      start_audio.url_audio === null ||
      error_audio.url_audio === null ||
      success_audio.url_audio === null ||
      finish_audio.url_audio === null ||
      props.json.questions.length <= 0
    ) {
      console.log("error load game");
      return;
    }

    _startAudio = start_audio.url_audio;
    _successAudio = success_audio.url_audio;
    _errorAudio = error_audio.url_audio;
    _finishAudio = finish_audio.url_audio;
    _isTestFinalCorrection = type_test == 1;
    _minimumCorrectQuestion = minimum_correct_question;
    _numberOptionsCorrect = 0;

    parseQuestion(props.json.questions, (questions) => {
      setQuestions(questions);
      playAudio(_startAudio, (success) => {
        if (success) {
          speakQuestion(questions[progress - 1]);
        }
      });
    });
  };

  const parseQuestion = (data, callback) => {
    var questions = [];
    let totalQuestions = data.length;

    const parseQuestion = (i) => {
      const currentQuestion = data[i];

      let totalOptions = currentQuestion.options.length;

      var newQuestion = [];
      var newOptions = [];

      const idQuestion = currentQuestion.id;
      const urlAudioQuestion = currentQuestion.audio.url_audio;

      var question = {
        id: idQuestion,
        sound: urlAudioQuestion,
      };

      const parseOption = (ii) => {
        const currentOption = currentQuestion.options[ii];

        let idOption = currentOption.id;
        let figureOption = currentOption.figure;
        let isCorrect = currentOption.is_correct;
        let idFigure = figureOption.id;
        let imageThumbFigure =
          figureOption.image_thumb[figureOption.image_thumb.length - 1]
            .url_image;
        let urlAudioFigure = figureOption.audio.url_audio;

        newOptions.push({
          idOption: idOption,
          idFigure: idFigure,
          isCorrect: isCorrect,
          imageThumb: imageThumbFigure,
          sound: urlAudioFigure,
        });

        ii += 1;

        if (ii < totalOptions) {
          parseOption(ii);
        } else {
          question.options = newOptions;
          newQuestion.push(question);

          // console.log(question)
          // q.push(question);
          questions.push(question);

          i += 1;

          if (i < totalQuestions) {
            parseQuestion(i);
          } else {
            callback(questions);
          }
        }
      };

      parseOption(0); // start
    };

    parseQuestion(0); // start
  };

  const playAudio = (url, callback) => {
    _audio = new Audio(url);
    _audio.addEventListener("ended", (el, event) => {
      _audio = null;

      if (callback != null) {
        callback(true);
      }
    });

    var playPromise = _audio.play();
    if (playPromise !== undefined) {
      playPromise.then((_) => {}).catch((error) => {});
    }
  };

  const clearAudio = () => {
    if (_audio) {
      _audio.pause();
      _audio = null;
    }
  };

  const clearInternval = () => {
    if (_idInterval > 0) {
      clearInterval(_idInterval);
      _idInterval = null;
    }
  };

  const speakQuestion = (question) => {
    clearInternval();
    playAudio(question.sound, (success) => {
      if (success) {
        setLock(false);
        repeatQuestion(question);
      }
    });
  };

  const repeatQuestion = (question) => {
    _idInterval = setInterval(() => {
      setLock(true);
      speakQuestion(question);
    }, _timerToRepeatQuestion);
  };

  const resetGame = () => {
    _numberOptionsCorrect = 0;
    setProgress(1);
    setHide(true);
    setFeedback("");
    setPass(false);

    playAudio(_startAudio, (success) => {
      if (success) {
        speakQuestion(questions[0]);
      }
    });
  };

  const controlGame = () => {
    const totalQuestions = questions.length;
    if (progress < totalQuestions) {
      let currentProgress = progress;
      setProgress(currentProgress + 1);
      speakQuestion(questions[currentProgress]);
      return;
    }

    if (_isTestFinalCorrection) {
      setHide(false);
      if (_numberOptionsCorrect < _minimumCorrectQuestion) {
        setFeedback(`${_numberOptionsCorrect} of ${totalQuestions} oh no!`);
        setPass(false);
        playAudio(_errorAudio, null);
      } else {
        finishGame();
        setFeedback(
          `${_numberOptionsCorrect} of ${totalQuestions} Congratulations!`
        );
        setPass(true);
      }
    } else {
      finishGame();
    }
  };

  const renderOptions = () => {
    if (!questions || questions.length <= 0) {
      return;
    }

    var cards = [];

    questions[progress - 1].options.forEach((item, index) => {
      cards.push(
        <CardCheck
          key={index}
          index={index}
          selected={false}
          defaultSelectedColor={false}
          isCorrect={item.isCorrect}
          image={item.imageThumb}
          onCheckOption={onCheckOption.bind(this)}
        />
      );
    });

    return cards;
  };

  const finishGame = () => {
    var homeworkInfo = JSON.parse(localStorage.getItem("homeworkInfo"));
    homeworkInfo.homework_id !== null
      ? validate()
          .then(() => localStorage.setItem("gameEnd", true))
          .catch(() => localStorage.setItem("gameEnd", false))
      : localStorage.setItem("gameEnd", true);

    setLock(true);
    setFeedback(true);
    playAudio(_finishAudio, null);
  };

  // events
  const onCheckOption = (item) => {
    if (!lock) {
      clearInternval();
      setLock(true);

      const question = questions[progress - 1];
      const option = question.options[item.index];

      if (_isTestFinalCorrection) {
        if (option.isCorrect) {
          _numberOptionsCorrect = _numberOptionsCorrect + 1;
        }

        controlGame();
      } else {
        playAudio(option.sound, (success) => {
          if (success) {
            if (option.isCorrect) {
              playAudio(_successAudio, () => {
                controlGame();
              });
            } else {
              playAudio(_errorAudio, () => {
                speakQuestion(questions[progress - 1]);
              });
            }
          }
        });
      }
    }
  };

  if (!questions || questions.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        ...props.style,
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {renderOptions()}
      </div>
      <Progress index={progress} length={questions.length} />
      <FeedbackGame
        hide={hide}
        text1={feedback}
        pass={pass}
        onReloadGame={() => resetGame()}
      />
    </div>
  );
});
