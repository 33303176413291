import {
  USER_SIGN_IN_START,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAILURE,
  USER_SIGN_OUT,
  USER_CLEAR,
  USER_RECOVER_PASSWORD_START,
  USER_RECOVER_PASSWORD_IN_SUCCESS,
  USER_RECOVER_PASSWORD_IN_FAILURE,
  NEW_CHILD_IN_START,
  NEW_CHILD_IN_SUCCESS,
  NEW_CHILD_IN_FAILURE,
  CHILD_REQUEST_START,
  CHILD_APPLY_LIVE_COURSE_SUCCESS,
  CHILD_APPLY_LIVE_COURSE_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  profile: null,
  loggedIn: false,
  isAuthenticating: false,
  recoverPassword: null,
  message: '',
  error: '',
  processing: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_SIGN_IN_START:
    case USER_RECOVER_PASSWORD_START:
      return {
        ...state,
        error: '',
        message: '',
        processing: true,
      };
    case CHILD_REQUEST_START:
      return {
        ...state,
        error: '',
        processing: true,
      };
    case USER_SIGN_IN_SUCCESS:
    case CHILD_APPLY_LIVE_COURSE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        live_subscription_pending: null,
        loggedIn: true,
        error: '',
        message: '',
        isAuthenticating: false,
        processing: false,
      };
    case USER_SIGN_IN_FAILURE:
      return {
        ...state,
        profile: null,
        loggedIn: false,
        error: action.payload,
        message: '',
        isAuthenticating: false,
        processing: false,
      };
    case USER_RECOVER_PASSWORD_IN_SUCCESS:
    case USER_RECOVER_PASSWORD_IN_FAILURE:
      return {
        ...state,
        message: action.payload,
        isAuthenticating: false,
        processing: false,
        error: '',
      };
    case USER_SIGN_OUT:
      return {
        ...state,
        profile: null,
        loggedIn: false,
        error: '',
        message: '',
        isAuthenticating: false,
        processing: false,
      };
    case USER_CLEAR:
      return {
        ...state,
        message: '',
        error: '',
        isAuthenticating: false,
        processing: false,
      };
    case NEW_CHILD_IN_START:
      return {
        ...state,
        processing: true,
        error: '',
      };
    case NEW_CHILD_IN_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          children: [...state.profile.children, action.payload],
        },
        error: '',
        processing: false,
      };
    case NEW_CHILD_IN_FAILURE:
      return {
        ...state,
        error: '',
        message: '',
        isAuthenticating: false,
        processing: false,
      };
    case CHILD_APPLY_LIVE_COURSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        processing: false,
      };
    default:
      return state;
  }
};

export default user;
