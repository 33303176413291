import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridAutoFlow: 'dense',
    marginBottom: 20,
    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    // [theme.breakpoints.up('sm')]: {
    //   gridTemplateColumns: 'repeat(4, 1fr)',
    // },
    // [theme.breakpoints.up('xs')]: {
    //   gridTemplateColumns: 'repeat(3, 1fr)',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   gridTemplateColumns: 'repeat(1, 1fr)',
    // },
  },
}));

export default ({ children }) => {
  const styles = useStyles();
  return <div className={styles.grid}>{children}</div>;
};
