import React from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import AulaIntrodutoriaIngles from "../../assets/INTRODUTÓRIASenglish.png";
import AulaProgramaca from "../../assets/INTRODUTÓRIASprogramação.png";
import AulaIntrodutoriaClub from "../../assets/INTRODUTÓRIASclub.png";
import AulasIndividuais from "../../assets/AULASINDIVIDUAIS.png";

const useStyles = () =>
  makeStyles((theme) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
      paddingTop: "180px",
      paddingBottom: "80px",
      gap: "20px",

      [theme.breakpoints.down("960")]: {
        marginTop: "250px",
        marginBottom: "250px",
        gap: "10px",
        flexDirection: "column",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    thumb: {
      borderRadius: "20px",
      padding: "15px",
      cursor: "pointer",
      width: "40vw",
      height: "25vh",
      [theme.breakpoints.down("960")]: {
        width: "100%",
        height: "25vh",
      },
    },
    row: {
      display: "flex",
      flexDirection: "row",
      columnGap: "20px",
      [theme.breakpoints.down("960")]: {
        rowGap: "10px",
        flexDirection: "column",
      },
    },
    img: {
      width: "100%",
      height: "100%",
    },
  }));

export default () => {
  const theme = useTheme();
  const styles = useStyles(theme)();
  let history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div
          style={{ backgroundColor: "#007DFE" }}
          className={styles.thumb}
          onClick={() => history.push("/live/singleclass?categoria=clubdoooo")}
        >
          <img src={AulaIntrodutoriaClub} className={styles.img} />
        </div>
        <div
          style={{ backgroundColor: "#FFBE00" }}
          className={styles.thumb}
          onClick={() =>
            history.push("/live/singleclass?categoria=introdutoriaingles")
          }
        >
          <img src={AulaIntrodutoriaIngles} className={styles.img} />
        </div>
      </div>
      <div className={styles.row}>
        <div
          style={{ backgroundColor: "#00C800" }}
          className={styles.thumb}
          onClick={() =>
            history.push("/live/singleclass?categoria=individuallevelup")
          }
        >
          <img src={AulasIndividuais} className={styles.img} />
        </div>
        <div
          style={{ backgroundColor: "#C41C38" }}
          className={styles.thumb}
          onClick={() =>
            history.push("/live/singleclass?categoria=introdutoriasprogramacao")
          }
        >
          <img src={AulaProgramaca} className={styles.img} />
        </div>
      </div>
    </div>
  );
};
