import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Subtitle from "../ui/subtitle";
import Slider from "react-slick";
import BoxContent from "./BoxContent";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles({
  content: {
    marginBottom: 10,
  },
});

const Arrow = ({ className, to, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`button button--text button--icon ${className}`}
    aria-label={to}
  >
    {to === "next" ? (
      <ArrowForwardOutlinedIcon color="primary" />
    ) : (
      <ArrowBackOutlinedIcon color="primary" />
    )}
  </button>
);

export default (props) => {
  const styles = useStyles();

  const setSettings = (total) => {
    return {
      dots: false,
      infinite: false,
      speed: 250,
      slidesToShow: Math.min(6, total),
      slidesToScroll: 1,
      centerPadding: "0px",

      lazyLoad: "ondemand",
      nextArrow: <Arrow to="next" />,
      prevArrow: <Arrow prev="prev" />,
      responsive: [
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: Math.min(4, total),
            // arrows: false,
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: Math.min(3, total),
          },
        },
        {
          breakpoint: 860,
          settings: {
            slidesToShow: Math.min(2, total),
          },
        },
        {
          breakpoint: 580,
          settings: {
            variableWidth: true,
            centerMode: true,
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    };
  };

  return (
    <Grid item xs={11} className={styles.content}>
      <Subtitle>{props.title}</Subtitle>
      <Slider {...setSettings(props.values.length)}>
        {
          // eslint-disable-next-line array-callback-return
          props.values.map((item, index) => {
            if (item.id) {
              return (
                <BoxContent
                  {...item}
                  image={item.image_thumb}
                  live={props.live}
                  liveClass={props.liveClass}
                  key={index}
                  path={props.path}
                  category_id={props.category_id}
                  // makeReserve={props.makeReserve}
                  // processing={props.processing}
                  goToClass={props.goToClass}
                  onOpenDialog={props.onOpenDialog}
                />
              );
            }
          })
        }
      </Slider>
    </Grid>
  );
};
