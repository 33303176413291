import React from "react";
import { useLocation } from "react-router-dom";

export default (Component) => {
  const NotShow = (props) => {
    let location = useLocation();
    const [notShow, setNotShow] = React.useState(false);

    React.useEffect(() => {
      setNotShow(location.pathname.indexOf("/game/") === 0);
    }, [location]);

    if (notShow) {
      return null;
    }

    return <Component {...props} />;
  };

  return NotShow;
};
