import React from "react";
import SubmitSkinButton from "../styles/submit-skin-button";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const monitoraTempo = (time) => {
  if (time !== false) {
    const fakeDate = new Date(2022, 1, 22, 10, 0);

    const classDate = new Date(time);
    const now = new Date();

    if (now >= classDate) {
      return true;
    } else {
      return;
    }
  } else {
    return true;
  }
};

const ReplacementButtonAndRecordedClass = (
  replacementid,
  styles,
  replacementData,
  classDateId,
  classId,
  replacementhomeworkid,
  recordedclass,
  time = false
) => {
  const history = useHistory();

  console.log("replacementData : ", replacementData);
  var url = "";
  if (
    replacementData.data !== null &&
    typeof replacementData.data !== "undefined"
  ) {
    if (replacementData.data.panda_id !== null) {
      url = `/directvideo/${replacementid}?category_id=10&homework_id=${classDateId}&panda_id=${replacementData.data.panda_id}&homework=${replacementhomeworkid}`;
    } else {
      url = `/directvideo/${replacementid}?category_id=10&homework_id=${classDateId}&vimeo_id=${replacementData.data.vimeo_id}&homework=${replacementhomeworkid}`;
    }
  }
  if (
    replacementhomeworkid !== null &&
    replacementhomeworkid !== false &&
    replacementData.data != null
  ) {
    return (
      <>
        <SubmitSkinButton
          fullWidth
          className={styles.button}
          onClick={() => {
            if (!monitoraTempo(time)) {
              alert(
                "Ops, a AULA GRAVADA ainda não está liberada.\r\nRetorne no dia e hora marcados para acessar essa aula.\r\nAssista sempre em famíila!"
              );

              return;
            }
            if (
              replacementhomeworkid !== null &&
              replacementhomeworkid !== false &&
              replacementData.loading === false
            ) {
              history.push(url);
            }
          }}
        >
          {replacementData === false ||
          replacementData === null ||
          replacementData.loading === true ? (
            <CircularProgress size={24} />
          ) : (
            "Assistir conteúdo - Aula Gravada"
          )}
        </SubmitSkinButton>
      </>
    );
  } else {
    return <></>;
  }
};

export default ReplacementButtonAndRecordedClass;
