export default (state = false, action) => {
  switch (action.type) {
    case "REFRESH_NOTICE_WALL":
      return true;

    case "REFRESHED_NOTICE_WALL":
      return false;

    default:
      return state;
  }
};
