import {
  NEW_CHILD_IN_START,
  NEW_CHILD_IN_SUCCESS,
  NEW_CHILD_IN_FAILURE,
  CHILD_REQUEST_START,
  CHILD_APPLY_LIVE_COURSE_SUCCESS,
  CHILD_APPLY_LIVE_COURSE_FAILURE,
} from '../actionTypes';

import { Auth } from '../../../client/api';
import { API_KEY } from '../../../Constants';
import { load } from '../../../localStorage';

export const newChild = (values) => (dispatch) => {
  const token = load('user').profile.user.auth_token;

  dispatch({ type: NEW_CHILD_IN_START });

  return Auth(token)
    .post('customer/child/new?apikey=' + API_KEY, values)
    .then(
      (response) => {
        dispatch({
          type: NEW_CHILD_IN_SUCCESS,
          payload: response.data.result,
        });
      },
      ({ response }) => {
        dispatch({
          type: NEW_CHILD_IN_FAILURE,
          payload: response.data.error.toString() || 'Erro, tente novamente',
        });
      }
    )
    .catch((error) => {
      dispatch({
        type: NEW_CHILD_IN_FAILURE,
        error: error || 'Erro, tente novamente',
      });
    });
};

export const childLiveCourse = (liveCourseId, values) => (dispatch) => {
  const token = load('user').profile.user.auth_token;

  dispatch({ type: CHILD_REQUEST_START });

  return Auth(token)
    .post('livecourse/' + liveCourseId + '/subscribe?apikey=' + API_KEY, values)
    .then(
      (response) => {
        dispatch({
          type: CHILD_APPLY_LIVE_COURSE_SUCCESS,
          payload: response.data.result,
        });
      },
      ({ response }) => {
        dispatch({
          type: CHILD_APPLY_LIVE_COURSE_FAILURE,
          payload: response.data.error.toString() || 'Erro, tente novamente',
        });
      }
    )
    .catch((error) => {
      dispatch({
        type: CHILD_APPLY_LIVE_COURSE_FAILURE,
        error: error || 'Erro, tente novamente',
      });
    });
};
