import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const CustomIconButtom = withStyles((theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
    zIndex: 1,
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'black',
    },
  },
}))((props) => <IconButton aria-label='close' {...props} />);

const StyleDialog = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  paper: {
    paddingBottom: 30,
  },
}))((props) => {
  const { classes, fullScreen, children, onClose } = props;
  return (
    <Dialog
      maxWidth='xs'
      fullWidth={true}
      fullScreen={fullScreen}
      classes={{ root: classes.root }}
      {...props}
    >
      {onClose ? (
        <CustomIconButtom onClick={onClose}>
          <CloseIcon />
        </CustomIconButtom>
      ) : null}
      {children}
    </Dialog>
  );
});

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    position: 'relative',
    margin: 0,
    textAlign: 'center',
    paddingTop: 45,
    paddingBottom: 25,
  },
}))((props) => {
  const { classes, ...other } = props;
  return <DialogTitle disableTypography className={classes.root} {...other} />;
});

export default (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { title, children, subtitle } = props;

  return (
    <StyleDialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
    >
      <StyledDialogTitle disableTypography onClose={props.onClose}>
        <Typography variant='h2' color='primary'>
          {title}
        </Typography>
        
        <Typography variant='h6' color='primary'>
          {subtitle}
        </Typography>

      </StyledDialogTitle>
      <DialogContent>{children}</DialogContent>
    </StyleDialog>
  );
};
