import React from 'react';
import { Grid } from '@material-ui/core';
import Paper from '../../styles/paper';
import Title from '../../ui/title';
import FormNewChild from '../../containers/FormNewChild';

// export default () => {
//   return <div>new child</div>;
// };

export default (props) => {
  return (
    <>
      <Grid
        item
        xs={11}
        sm={8}
        md={5}
        lg={4}
        style={{ paddingTop: 190, paddingBottom: 60 }}
      >
        <Grid item xs>
          <Title>NOVA CRIANÇA</Title>
        </Grid>
        <Grid item xs sm md>
          <Paper>
            <FormNewChild />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
