import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    letterSpacing: 2,
  },
}));

export default (props) => {
  const styles = useStyles();

  return (
    <Typography variant='h1' component='h1' className={styles.title}>
      {props.children}
    </Typography>
  );
};
