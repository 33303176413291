import React, { useState } from "react";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import client from "../../../client/api";
import { API_KEY } from "../../../Constants";
import Loading from "../../../components/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { load } from "../../../localStorage";

const useStyles = makeStyles(() => ({
  inputField: {
    marginBottom: 12,
  },
  submitButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const validationSchema = yup.object({
  email: yup
    .string("Digite seu novo senha")
    .email("Preencha um email valido.")
    .required("O Email é um campo requerido."),
  password: yup
    .string("Digite sua nova senha")
    .min(6, "A senha deverá ter no minímo 6 caracteres."),

  confirmPassword: yup
    .string("Confirme sua nova senha.")
    .oneOf([yup.ref("password"), null], "As senhas não são iguais"),
});

function getChildLive(listChildren) {
  const atLeastOneChildLive = listChildren.filter(
    (item) => item.has_live_course === true
  );

  return atLeastOneChildLive.length > 0 ? atLeastOneChildLive[0] : null;
}

export default () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const profileData = useSelector((state) => state.profile);
  const { profile } = load("user");
  const children = getChildLive(profile.children);
  const formik = useFormik({
    initialValues: {
      email: profileData.email,
      password: "",
      confirmPassword: "",
      phone: profileData.phone,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const response = await client.put(
        `/profile/${children.id}?apikey=${API_KEY}`,
        {
          email: values.email,
          password: values.password,
          phone: values.phone,
        }
      );
      if (response.status !== 200) {
        toast.error("Falha ao Atualizar dados, por favor tente novamente.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      } else {
        localStorage.setItem(
          "notification",
          "Sucesso em salvar as informações do perfil."
        );
        window.location.reload();
      }
    },
  });

  if (loading) return <Loading />;
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          id="email"
          name="email"
          fullWidth
          label="Email"
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          className={classes.inputField}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          disabled
        />
        <TextField
          id="password"
          name="password"
          fullWidth
          label="Nova Senha"
          variant="outlined"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          className={classes.inputField}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <TextField
          id="confirmPassword"
          name="confirmPassword"
          fullWidth
          label="Confirmação da Nova Senha"
          variant="outlined"
          type="password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          className={classes.inputField}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />

        <TextField
          id="phone"
          name="phone"
          label="Telefone/Celular"
          variant="outlined"
          fullWidth
          value={formik.values.phone}
          onChange={formik.handleChange}
          className={[classes.inputField]}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />

        <div className={classes.submitButton}>
          <Button color="primary" variant="contained" type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
};
